import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  combineLatest,
  filter,
  map,
  of,
  shareReplay,
  take,
} from 'rxjs';

import { CashierStore, StoreStore } from '@application/stores';
import { CashierService } from '@infrastructure/api';
import { CASHIER_MOVEMENT } from '@model/enums';
import {
  CashierMovement,
  CashierStatus,
  OpenCloseCashier,
} from '@model/interfaces';
import { UTCToTimezoned } from 'app/utils/utc-to-timezoned.function';
import { DateTime } from 'luxon';
import { customDateFormat } from '../../../../utils/custom-date-format.function';
import { printCashierOperation } from '../../../../utils/print-cashier-operation.function';
import { printCloseCashierTicket } from '../../../../utils/print-ticket-close-cashier.function';
import { CashierFilter } from '@model/interfaces/order/cashier-filter';

@Injectable({
  providedIn: 'root',
})
export class CashierFacade {
  constructor(
    private readonly cashierService: CashierService,
    private readonly toastrService: ToastrService,
    private readonly cashierStore: CashierStore,
    private readonly storeStore: StoreStore
  ) {}

  openCloseCashier(body: OpenCloseCashier, name: string, wantPrint: boolean) {
    const storeSettings = this.storeStore.getCurrentSettings();
    this.cashierService
      .openCloseCashier(body)
      .pipe(
        take(1),
        shareReplay(1),
        map((res) => res.success),
        catchError((err) => {
          this.toastrService.error(err.error.error.message);
          return of(false);
        })
      )
      .subscribe((res) => {
        const action = body.action == 'open' ? 'aberta' : 'fechada';
        if (!res) {
          return;
        }

        if (body.action == 'open') {
          const cashierFilter: CashierFilter = {};
          cashierFilter.date = new Date();
          this.fetchCashierStatus(cashierFilter);
          this.cashierStore.status = 'OPEN';

          if (wantPrint) {
            const dateNow = DateTime.now()
              .setZone(storeSettings.timezone.timezone)
              .toFormat('yyyy-MM-dd hh:mm:ss a');

            printCashierOperation(
              body.cash,
              name,
              dateNow,
              CASHIER_MOVEMENT.OPEN
            );
          }
        }

        if (body.action == 'close') {
          this.cashierStore.status = 'CLOSED';

          const dateNow = DateTime.now()
            .setZone(storeSettings.timezone.timezone)
            .toFormat('yyyy-MM-dd');

          if (wantPrint)
            this.cashierService
              .getCashierSummary(dateNow)
              .subscribe((cashierSummary) => {
                if (cashierSummary.success) {
                  printCloseCashierTicket(
                    cashierSummary.data,
                    name,
                    storeSettings
                  );
                }
              });
        }

        if (res) this.toastrService.success(`Caixa ${action} por ${name}`);
      });
  }

  fetchCashierStatus(cashierF?: CashierFilter) {
    let formatDate;
    if (cashierF?.date) formatDate = customDateFormat(cashierF.date);
    this.cashierStore.status = 'PENDING';
    combineLatest([
      this.storeStore.getStoreSettings(),
      this.cashierService.fetchCashierStatus(
        formatDate,
        cashierF?.connectionId
      ),
    ])
      .pipe(
        take(1),
        shareReplay(1),
        filter(([storeSettings]) => Boolean(storeSettings.timezone)),
        map(([storeSettings, res]) => {
          const { timezone } = storeSettings.timezone;
          const { closedAt, openedAt, ...rest } = res.data;
          return {
            ...rest,
            closedAt: closedAt ? UTCToTimezoned(closedAt, timezone) : closedAt,
            openedAt: openedAt ? UTCToTimezoned(openedAt, timezone) : openedAt,
          };
        }),
        catchError(() => {
          this.cashierStore.status = 'CLOSED';
          return of({} as CashierStatus);
        })
      )
      .subscribe((cashier: CashierStatus) => {
        this.cashierStore.cashier = cashier;
        if (cashier.isOpen) this.cashierStore.status = 'OPEN';
        if (cashier.isClosed) this.cashierStore.status = 'CLOSED';
        if (!cashier.isOpen && !cashier.isClosed)
          this.cashierStore.status = 'NOOPEN';
      });
  }

  cashierMovements(movements: CashierMovement, print: boolean) {
    const storeSettings = this.storeStore.getCurrentSettings();
    this.cashierService
      .cashierMovements(movements)
      .pipe(
        take(1),
        shareReplay(1),
        catchError((err) => {
          this.toastrService.error(err.error.error.message);
          throw new Error();
        })
      )
      .subscribe(({ success, message }) => {
        if (!success) {
          this.toastrService.error(message);
        }

        this.fetchMovements();

        if (
          movements.type === CASHIER_MOVEMENT.DEPOSIT ||
          movements.type === CASHIER_MOVEMENT.WITHDRAWAL
        ) {
          this.toastrService.success(
            movements.type === CASHIER_MOVEMENT.DEPOSIT
              ? 'Dinheiro adicionado'
              : 'Dinheiro retirado'
          );

          if (print && movements.user) {
            const dateNow = DateTime.now()
              .setZone(storeSettings.timezone.timezone)
              .toFormat('yyyy-MM-dd hh:mm:ss a');
            printCashierOperation(
              movements.amount,
              movements.user.name,
              dateNow,
              movements.type
            );
          }

          return;
        }
      });
  }

  fetchMovements(cashFilter?: CashierFilter) {
    let formatDate;
    if (cashFilter?.date) formatDate = customDateFormat(cashFilter.date);
    combineLatest([
      this.storeStore
        .getStoreSettings()
        .pipe(filter((storeSettings) => Boolean(storeSettings.timezone))),
      this.cashierService
        .fetchMovements(formatDate, cashFilter?.connectionId)
        .pipe(
          catchError(() => of({ data: [] })),
          map((res) => res.data)
        ),
    ])
      .pipe(take(1), shareReplay(1))
      .subscribe(([storeSettings, movements]) => {
        const { timezone } = storeSettings.timezone;
        this.cashierStore.movements = movements.map((movement) => ({
          ...movement,
          createdAt: UTCToTimezoned(movement.createdAt, timezone),
        }));
      });
  }
}
