import { HttpRequest } from '@angular/common/http';
import { IInterceptorFilter } from '@shared/interfaces/interceptor-filter';
import { SKIP_LOADER } from '../context';
import { LoadingInterceptor } from '../loading.interceptor';

export class LoadingHttpContextSkip implements IInterceptorFilter {
  skipIf(interceptor: unknown, req: HttpRequest<unknown>): boolean {
    return (
      interceptor instanceof LoadingInterceptor && req.context.get(SKIP_LOADER)
    );
  }
}
