import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, debounceTime } from 'rxjs';

import { AuthFacade } from '@application/facades';
import { AuthToken } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authFacade: AuthFacade) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const request = this.getTokenCredentials(req);
    return next.handle(request).pipe(
      debounceTime(1000),
      catchError((err) => {
        // TODO Determine a way to efficiently handle errors in the app
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.authFacade.logout();
        }

        throw err;
      })
    );
  }

  getTokenCredentials(req: HttpRequest<unknown>) {
    const strToken = localStorage.getItem('token');
    if (!strToken) return req;

    const token: AuthToken = JSON.parse(strToken);

    return req.clone({
      setHeaders: {
        authorization: `Bearer ${token.accessToken}`,
      },
    });
  }
}
