import { Injectable } from '@angular/core';
import { HubsterFulfillmentMode } from '@slabcode/hubster-models/enums/hubster';
import { BehaviorSubject, Observable } from 'rxjs';

import { FulfillmentInfo } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class FulfillmentInfoStore {
  private fulfillmentInfo$: BehaviorSubject<FulfillmentInfo> =
    new BehaviorSubject({
      pickupTime: null,
      deliveryTime: null,
      fulfillmentMode: HubsterFulfillmentMode.DELIVERY,
      schedulingType: 'ASAP',
      courierStatus: null,
    } as FulfillmentInfo);

  set fulfillmentInfo(fulfillment: FulfillmentInfo) {
    this.fulfillmentInfo$.next({ ...fulfillment });
  }

  set fulfillmentMode(fulfillmentMode: HubsterFulfillmentMode) {
    const fulfillmentInfo = this.fulfillmentInfo$.getValue();
    this.fulfillmentInfo$.next({ ...fulfillmentInfo, fulfillmentMode });
  }

  getFulfillmentInfo(): Observable<FulfillmentInfo> {
    return this.fulfillmentInfo$.asObservable();
  }

  isFulfillmentMode(fulfillmentMode: HubsterFulfillmentMode) {
    return this.fulfillmentInfo$.getValue().fulfillmentMode == fulfillmentMode;
  }

  setPickupDelivery(date: string): void {
    const { fulfillmentMode, schedulingType, courierStatus } =
      this.fulfillmentInfo$.getValue();
    const fulfillmentInfo = {
      schedulingType,
      courierStatus,
      fulfillmentMode,
    } as FulfillmentInfo;
    if (fulfillmentMode == HubsterFulfillmentMode.DELIVERY)
      fulfillmentInfo.deliveryTime = date;
    if (fulfillmentMode == HubsterFulfillmentMode.PICKUP)
      fulfillmentInfo.pickupTime = date;
    this.fulfillmentInfo$.next({ ...fulfillmentInfo });
  }

  getPickupDelivery(): string | null {
    return this.fulfillmentInfo$.getValue().deliveryTime;
  }

  getDateValue(): string | null {
    const { fulfillmentMode, deliveryTime, pickupTime } =
      this.fulfillmentInfo$.getValue();
    const rawDate =
      fulfillmentMode == HubsterFulfillmentMode.DELIVERY
        ? deliveryTime
        : pickupTime;
    if (!rawDate) return '';
    const date = new Date(rawDate);
    const hour = date.toTimeString().substring(0, 5);
    const today = date.toISOString().substring(0, 11);
    return `${today}${hour}`;
  }

  reset() {
    this.fulfillmentInfo$.next({
      pickupTime: null,
      deliveryTime: null,
      fulfillmentMode: HubsterFulfillmentMode.DELIVERY,
      schedulingType: 'ASAP',
      courierStatus: null,
    } as FulfillmentInfo);
  }
}
