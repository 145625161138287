import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  CustomerResponse,
  CustomersResponse,
  PackageOrderResponse,
} from '@infrastructure/dtos';
import { Customer, CustomerFilter, PackageOrder } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private URL = `${environment.apiUsersUrl}/customers`;

  constructor(private http: HttpClient) {}

  fetchCustomers(filters?: CustomerFilter): Observable<CustomersResponse> {
    let params = new HttpParams();
    const { filter, page, size, connectionId } = filters || {};
    if (filter) params = params.set('filter', filter);
    if (page) params = params.set('page', page);
    if (size) params = params.set('size', size);
    if (connectionId) params = params.set('connectionId', connectionId);
    return this.http.get<CustomersResponse>(`${this.URL}`, {
      params,
    });
  }

  fetchCustomer(
    id: number,
    showPackages?: boolean
  ): Observable<CustomerResponse> {
    let params = new HttpParams();
    if (showPackages) params = params.set('available-packages', true);
    return this.http.get<CustomerResponse>(`${this.URL}/${id}`, { params });
  }

  createCustomer(customer: Customer): Observable<CustomerResponse> {
    return this.http.post<CustomerResponse>(`${this.URL}`, customer);
  }

  updateCustomer(customer: Customer, id: number): Observable<CustomerResponse> {
    return this.http.put<CustomerResponse>(`${this.URL}/${id}`, customer);
  }

  sellPackages(packages: PackageOrder): Observable<PackageOrderResponse> {
    return this.http.post<PackageOrderResponse>(
      `${this.URL}/packages`,
      packages
    );
  }
}
