import { Injectable } from '@angular/core';
import { PICKUP_STATUS } from '@model/enums';
import { Pickup, PickupDetail } from '@model/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PickupStore {
  private pickups$: BehaviorSubject<Pickup[] | null> = new BehaviorSubject<
    Pickup[] | null
  >(null);

  private totalPickups$: BehaviorSubject<number> = new BehaviorSubject(0);

  private pickup$: BehaviorSubject<PickupDetail | null> =
    new BehaviorSubject<PickupDetail | null>(null);

  getPickups() {
    return this.pickups$.asObservable();
  }

  set pickups(pickups: Pickup[]) {
    this.pickups$.next(pickups);
  }

  getTotalPickups() {
    return this.totalPickups$.asObservable();
  }

  set totalPickups(total: number) {
    this.totalPickups$.next(total);
  }

  getPickup() {
    return this.pickup$.asObservable();
  }

  set pickup(pickup: PickupDetail) {
    this.pickup$.next(pickup);
  }

  deletePickup(pickupId: number) {
    const prevPickupArray = this.pickups$.value as Pickup[];
    const newPickupArray = prevPickupArray.filter((p) => p.id !== pickupId);
    this.pickups$.next(newPickupArray);
  }

  addPickup(pickup: PickupDetail) {
    const pickupArray = this.pickups$.value as Pickup[];
    const [last] = pickupArray.slice(-1);
    if (pickup.pickupDate > last.pickupDate) {
      pickupArray.pop();
      pickupArray.push({
        id: pickup.id,
        comments: pickup.comments,
        status: pickup.status,
        pickupDate: pickup.pickupDate,
        customerName: pickup.customer.customerName,
        fullAddress: pickup.customer.address.fullAddress,
      });
      pickupArray.sort((a, b) =>
        a.pickupDate > b.pickupDate ? -1 : a.pickupDate < b.pickupDate ? 1 : 0
      );
      this.pickups$.next(pickupArray);
    }
  }

  updatePickup(pickupId: number, comments: string, pickupDate: string) {
    const pickupArray = this.pickups$.value as Pickup[];
    const updatedPickup = pickupArray.find((p) => p.id === pickupId) as Pickup;
    updatedPickup.comments = comments;
    updatedPickup.pickupDate = pickupDate;
    this.pickups$.next(pickupArray);
  }

  setAsComplete(pickupId: number) {
    const pickupArray = this.pickups$.value as Pickup[];
    const updatedPickup = pickupArray.find((p) => p.id === pickupId) as Pickup;
    updatedPickup.status = PICKUP_STATUS.DONE;
    this.pickups$.next(pickupArray);
  }

  resetPickup() {
    this.pickup$.next(null);
  }

  resetPickups() {
    this.pickups$.next(null);
    this.totalPickups$.next(0);
  }
}
