import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  CreateStoreUser,
  UpdateStoreUser,
  UserResponse,
  UsersResponse,
  ValidatePinResponse,
} from '@infrastructure/dtos';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private URL = `${environment.apiUsersUrl}/users`;

  constructor(private readonly http: HttpClient) {}

  validatePin(pin: string): Observable<ValidatePinResponse> {
    return this.http.post<ValidatePinResponse>(`${this.URL}/validate-pin`, {
      pin,
    });
  }

  fetchUsersByStore(connectionId: number): Observable<UsersResponse> {
    const params = new HttpParams().set('connectionId', connectionId);
    return this.http.get<UsersResponse>(`${this.URL}/manager/store-users`, {
      params,
    });
  }

  createStoreUser(user: CreateStoreUser): Observable<UserResponse> {
    return this.http.post<UserResponse>(
      `${this.URL}/manager/store-users`,
      user
    );
  }

  updateStoreUser(user: UpdateStoreUser): Observable<UserResponse> {
    return this.http.put<UserResponse>(`${this.URL}/manager/store-users`, user);
  }

  setUserStatus(userId: number, enable: boolean) {
    if (enable) return this.http.patch(`${this.URL}/${userId}/enable`, {});
    return this.http.patch(`${this.URL}/${userId}/disable`, {});
  }
}
