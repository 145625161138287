export const environment = {
  apiMenuUrl: 'https://omo-lavanderias-stag-back-menus-4df2kcmewq-uc.a.run.app',
  //   'https://us-central1-hubster-305121.cloudfunctions.net/omo-laundry-menu-dev',
  apiUsersUrl:
    'https://omo-lavanderias-stag-back-users-4df2kcmewq-uc.a.run.app',
  //   'https://us-central1-hubster-305121.cloudfunctions.net/omo-laundry-users-dev',
  apiOrderUrl:
    'https://omo-lavanderias-stag-back-orders-4df2kcmewq-uc.a.run.app',
  //   'https://us-central1-hubster-305121.cloudfunctions.net/omo-laundry-orders-dev',
  mapServiceUrl: 'https://map-service-4df2kcmewq-uc.a.run.app/maps',
};
