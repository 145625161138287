import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ItemInfo } from '@model/interfaces';

@Component({
  selector: 'app-item-gallery',
  templateUrl: './item-gallery.component.html',
  styleUrls: ['./item-gallery.component.scss'],
})
export class ItemGalleryComponent<T extends ItemInfo> {
  // TODO Should extend from GalleryItem
  @Input() gallery: T[] | null = [] as T[];
  @Input() title: string | null = '';
  @Output() itemClicked = new EventEmitter<T['id']>();

  onClick(itemId: T['id'], isUnlitimed?: boolean) {
    if (isUnlitimed) return;
    this.itemClicked.emit(itemId);
  }
}
