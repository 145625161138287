export enum CASHIER_MOVEMENT {
  DEPOSIT = 'deposit',
  ADDITION = 'addition',
  WITHDRAWAL = 'withdrawal',
  OPEN = 'open',
  ORDER_PAYMENT = 'order-payment',
  ORDER_CANCELATION = 'order-cancelation',
  CLOSE = 'close',
}

export type PRINTABLE_CASHIER_MOVEMENTS =
  | CASHIER_MOVEMENT.OPEN
  | CASHIER_MOVEMENT.DEPOSIT
  | CASHIER_MOVEMENT.WITHDRAWAL;
