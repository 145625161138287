import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Cancellation, Store } from '@model/interfaces';
import { StoreSettings } from '@model/interfaces/store/store-settings';

@Injectable({
  providedIn: 'root',
})
export class StoreStore {
  private stores$: BehaviorSubject<Store[] | null> = new BehaviorSubject<
    Store[] | null
  >(null);
  private storeSettings$: BehaviorSubject<StoreSettings> =
    new BehaviorSubject<StoreSettings>({} as StoreSettings);
  private cancellations$: BehaviorSubject<Cancellation[] | null> =
    new BehaviorSubject<Cancellation[] | null>(null);
  private storeTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  getStores() {
    return this.stores$.asObservable();
  }

  set stores(stores: Store[] | null) {
    this.stores$.next(stores);
  }

  getStoreTotal() {
    return this.storeTotal$.asObservable();
  }

  set storeTotal(storeTotal: number) {
    this.storeTotal$.next(storeTotal);
  }

  getStoreSettings() {
    return this.storeSettings$.asObservable();
  }

  set storeSettings(storeSettings: StoreSettings) {
    this.storeSettings$.next({ ...storeSettings });
  }

  getCancellations() {
    return this.cancellations$.asObservable();
  }

  set cancellations(cancellations: Cancellation[]) {
    this.cancellations$.next([...cancellations]);
  }

  updateCancellation(cancellation: Cancellation) {
    const cancellations = this.cancellations$.value as Cancellation[];
    const updatedCancellations = cancellations.filter(
      (c) => c.connectionId !== cancellation.connectionId
    );
    updatedCancellations.push(cancellation);
    this.cancellations$.next(updatedCancellations);
  }

  getCurrentSettings() {
    return this.storeSettings$.value;
  }

  setStoreSelected(storeId: number): void {
    localStorage.setItem('store', JSON.stringify(storeId));
  }

  getStoreSelected(): number | null {
    const storeId = localStorage.getItem('store');
    if (!storeId) return null;
    return JSON.parse(storeId) as number;
  }

  setStoreNames(stores: { [key: string]: string }) {
    localStorage.setItem('storeNames', JSON.stringify(stores));
  }

  getStoreNames() {
    const storeNames = localStorage.getItem('storeNames');

    if (!storeNames) return null;
    return JSON.parse(storeNames) as { [key: string]: string };
  }

  getStoreName(id: number) {
    const storeNames = this.getStoreNames();

    if (!storeNames) return undefined;
    return storeNames[id.toString()];
  }

  resetStoreSettings() {
    this.storeSettings$.next({} as StoreSettings);
  }

  resetStores() {
    this.stores$.next(null);
  }

  reset(): void {
    this.stores$.next([]);
    localStorage.removeItem('store');
    localStorage.removeItem('storeNames');
  }
}
