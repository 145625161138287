import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { NfeResponse, NfesResponse } from '@infrastructure/dtos';
import { Nfe } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class NfeService {
  private readonly URL = `${environment.apiMenuUrl}`;
  private readonly http = inject(HttpClient);

  constructor() {}

  fetchNfe(connectionId?: number) {
    let params = new HttpParams();
    if (connectionId) {
      params = params.append('connectionId', connectionId);
    }
    return this.http.get<NfesResponse>(`${this.URL}/nfe-settings`, {
      params,
    });
  }

  createNfe(nfe: Nfe) {
    return this.http.post<NfeResponse>(`${this.URL}/nfe-settings`, nfe);
  }
}
