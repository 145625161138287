import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import {
  CashierMovementResponse,
  CashiersMovementResponse,
  CashierStatusResponse,
  OpenCloseCashierResponse,
} from '@infrastructure/dtos';
import { OpenCloseCashier } from '@model/interfaces';
import { CashierMovement } from '@model/interfaces';
import { CashierSummaryResponse } from '../../dtos/cashier/cashier-summary-responde.dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CashierService {
  url = environment.apiUsersUrl;

  constructor(private readonly http: HttpClient) {}

  fetchCashierStatus(date?: string, connectionId?: number) {
    let params = new HttpParams();
    if (connectionId) params = params.append('connectionId', connectionId);
    if (date) params = params.append('date', date);
    return this.http.get<CashierStatusResponse>(`${this.url}/cashier`, {
      params,
    });
  }

  openCloseCashier(body: OpenCloseCashier) {
    return this.http.post<OpenCloseCashierResponse>(
      `${this.url}/cashier`,
      body
    );
  }

  cashierMovements(movement: CashierMovement) {
    return this.http.post<CashierMovementResponse>(
      `${this.url}/cashier/movements`,
      movement
    );
  }

  getCashierSummary(date: string): Observable<CashierSummaryResponse> {
    let params = new HttpParams();
    params = params.append('date', date);
    return this.http.get<CashierSummaryResponse>(
      `${this.url}/cashier/summary`,
      {
        params,
      }
    );
  }

  fetchMovements(date?: string, connectionId?: number) {
    let params = new HttpParams();
    if (connectionId) params = params.append('connectionId', connectionId);
    if (date) params = params.append('date', date);
    return this.http.get<CashiersMovementResponse>(
      `${this.url}/cashier/movements`,
      {
        params,
      }
    );
  }
}
