import { Observable } from 'rxjs';
import { MenuItem, MenuItemMap } from '../models/menu-item.model';
import { MenuModifierGroupMap } from '../models/menu-modifier-group.model';
import { Menu } from '../models/menu.model';

export abstract class IHubsterStore {
  abstract getMenus(): Observable<Menu[] | null>;
  abstract setMenus(value: Menu[] | null): void;

  abstract getMenuItems(): Observable<MenuItem[] | null>;
  abstract getMenuItemsSnapshot(): MenuItem[] | null;
  abstract setMenuItems(value: MenuItem[] | null): void;

  abstract setModifierGroups(modifiers: MenuModifierGroupMap | null): void;
  abstract getModifierGroupsSnapshot(): MenuModifierGroupMap | null;

  abstract setItems(items: MenuItemMap | null): void;
  abstract getItemsSnapshot(): MenuItemMap | null;
}
