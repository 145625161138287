import { Injectable } from '@angular/core';
import { combineLatest, filter, Observable, shareReplay, take } from 'rxjs';

import { CustomerStore, StoreStore } from '@application/stores';
import { CustomerService } from '@infrastructure/api';
import {
  CustomerResponse,
  CustomersResponse,
  PackageOrderResponse,
} from '@infrastructure/dtos';
import { Customer, CustomerFilter, PackageOrder } from '@model/interfaces';
import { formatIntlShortDate } from 'app/utils/intl-formatters.function';

@Injectable({
  providedIn: 'root',
})
export class CustomerFacade {
  constructor(
    private readonly customerService: CustomerService,
    private readonly customerStore: CustomerStore,
    private readonly storeStore: StoreStore
  ) {}

  fetchCustomers(filter?: CustomerFilter): void {
    this.customerService
      .fetchCustomers(filter)
      .pipe(take(1))
      .subscribe((res: CustomersResponse) => {
        this.customerStore.customers = res.data;
        this.customerStore.totalCustomers = res.total;
      });
  }

  fetchCustomer(id: number, showPackages?: boolean): void {
    combineLatest([
      this.storeStore
        .getStoreSettings()
        .pipe(filter((storeSettings) => Boolean(storeSettings.timezone))),
      this.customerService.fetchCustomer(id, showPackages),
    ])
      .pipe(take(1))
      .subscribe(([storeSettings, res]) => {
        const timezone = storeSettings.timezone.timezone;
        this.customerStore.customer = {
          ...res.data,
          packages: res.data.packages.map((p) => ({
            ...p,
            expirationDate: p.expirationDate
              ? this.formatDate(p.expirationDate, timezone)
              : '',
            purchaseDate: p.purchaseDate
              ? this.formatDate(p.purchaseDate, timezone)
              : '',
          })),
        };
      });
  }

  private formatDate(rawDate: string, timezone: string) {
    return formatIntlShortDate(timezone, new Date(rawDate));
  }

  // TODO Should return void?
  createCustomer(customer: Customer): Observable<CustomerResponse> {
    return this.customerService.createCustomer(customer).pipe(shareReplay(1));
  }

  // TODO Should return void?
  updateCustomer(customer: Customer, id: number): Observable<CustomerResponse> {
    return this.customerService
      .updateCustomer(customer, id)
      .pipe(shareReplay(1));
  }

  sellPackages(packages: PackageOrder): Observable<PackageOrderResponse> {
    return this.customerService.sellPackages(packages).pipe(take(1));
  }

  setCustomer(customer: Customer) {
    this.customerStore.customer = customer;
  }

  resetCustomer() {
    this.customerStore.resetCustomer();
  }

  resetCustomers() {
    this.customerStore.resetCustomers();
  }
}
