import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { RoyaltiesResponse } from '../../dtos/royalties/royalties-response.dto';
import { Observable } from 'rxjs';
import { RoyaltyStoreSettingsResponse } from '@infrastructure/dtos/store/store-settings-royalties.dto';

@Injectable({
  providedIn: 'root',
})
export class RoyaltiesService {
  private URL = `${environment.apiUsersUrl}`;

  constructor(private readonly http: HttpClient) {}

  getRoyalties(
    connectionId?: number,
    year?: string
  ): Observable<RoyaltiesResponse> {
    let params = new HttpParams();
    if (connectionId) {
      params = params.append('connectionId', connectionId);
    }
    if (year) {
      params = params.append('year', year);
    }

    params = params.append('accumulated', true);
    return this.http.get<RoyaltiesResponse>(`${this.URL}/royalties`, {
      params,
    });
  }

  fetchStoreSettingsRoyalties() {
    return this.http.get<RoyaltyStoreSettingsResponse>(
      `${this.URL}/store-settings/royalties`
    );
  }
}
