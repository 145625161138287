import { Injectable } from '@angular/core';
import { filter, map, Observable, take } from 'rxjs';
import { IStockFacade } from '../interfaces/stock-facade.interface';
import { IStockRepository } from '../interfaces/stock-repository.interface';
import { IStockStore } from '../interfaces/stock-store.interface';
import { StockSetting } from '../models/stock-setting.model';
import { StockMap } from '../types/stock-map.type';

@Injectable()
export class StockFacade implements IStockFacade {
  constructor(
    private readonly stockRepository: IStockRepository,
    private readonly stockStore: IStockStore
  ) {}

  initDataForItems(): void {
    this.fetchStockItems();
  }

  fetchStockSettings(connectionId: number) {
    this.stockRepository
      .fetchStockSettings(connectionId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.stockStore.setStockSettings(res.data);
        },
      });
  }
  getStockSettings(): Observable<StockSetting[] | null> {
    return this.stockStore.getStockSettings();
  }

  private fetchStockItems(): void {
    this.stockRepository
      .stockItems()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.stockStore.setStockItems(res.data);
        },
      });
  }

  getStockItemsMap(): Observable<StockMap> {
    return this.stockStore.getStockItems().pipe(
      filter((items) => !!items),
      map((items) =>
        items!.reduce((acc, cur) => ({ ...acc, [cur.itemName]: cur.stock }), {})
      )
    );
  }
}
