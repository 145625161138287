import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { LoginRequest, LoginResponse } from '@infrastructure/dtos';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private URL = `${environment.apiUsersUrl}/users`;

  constructor(private http: HttpClient) {}

  login(payload: LoginRequest) {
    return this.http.post<LoginResponse>(`${this.URL}/login`, payload);
  }
}
