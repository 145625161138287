import { Component, Input } from '@angular/core';

import { ItemInfo } from '@model/interfaces';

@Component({
  selector: 'app-item-count',
  templateUrl: './item-count.component.html',
  styleUrls: ['./item-count.component.scss'],
})
export class ItemCountComponent {
  // TODO ItemInfo is not properly implemented
  @Input() value: ItemInfo = {} as ItemInfo;
}
