import { BehaviorSubject, Observable } from 'rxjs';
import { IStockStore } from '../interfaces/stock-store.interface';
import { StockItem } from '../models/stock-item.model';
import { StockSetting } from '../models/stock-setting.model';

export class StockStore implements IStockStore {
  private readonly stockItems = new BehaviorSubject<StockItem[] | null>(null);
  setStockItems(items: StockItem[] | null): void {
    this.stockItems.next(items);
  }
  getStockItems(): Observable<StockItem[] | null> {
    return this.stockItems.asObservable();
  }

  private readonly stockSettings = new BehaviorSubject<StockSetting[] | null>(
    null
  );
  setStockSettings(items: StockSetting[] | null): void {
    this.stockSettings.next(items);
  }
  getStockSettings(): Observable<StockSetting[] | null> {
    return this.stockSettings.asObservable();
  }
}
