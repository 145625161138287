<section>
  <h1 class="text-2xl font-semibold mb-2">
    {{ title }}
  </h1>
  <div class="grid gap-2 gallery-grid overflow-auto">
    <!-- TODO Add item-card component -->
    <div *ngFor="let item of gallery" class="flex flex-col p-2 border bg-white text-center cursor-pointer relative"
      (click)="onClick(item.id, item.isUnlimited)"
      [ngClass]="{'disabled': item.isUnlimited}"
    >
      <div class="flex justify-center" *ngIf="!item.images || item.images.length == 0; else images">
        <img class="h-44 object-contain p-16" src="/assets/no_image.png" alt="no-img" />
      </div>
      <ng-template #images>
        <div *ngFor="let photoId of item.images" class="flex justify-center">
          <img class="h-44 object-contain" [src]="photoId" [alt]="item.name" />
        </div>
      </ng-template>
    <span *ngIf="item.stock != undefined"
        class="text-center flex items-center justify-center rounded-full w-5 h-5 mr-1 mt-1 border border-solid border-gray-500 absolute top-0 right-0">
        {{ item.stock }}
      </span>
      {{ item.name }}
    </div>
  </div>
</section>
