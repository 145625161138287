/**
 * Returns a date in format YYYY-MM-DD
 * @param date date
 * @returns Date in format YYYY-MM-DD
 */
export const customDateFormat = (date: Date): string => {
  const month =
    date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

  return `${date.getFullYear()}-${month}-${day}`;
};
