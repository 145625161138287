import { Injectable } from '@angular/core';
import { Nfe } from '@model/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NfeStore {
  private nfeSettings$ = new BehaviorSubject<Nfe[]>([]);

  getNfeSettings() {
    return this.nfeSettings$.asObservable();
  }

  getNfeSettingsSnapshot() {
    return this.nfeSettings$.getValue();
  }

  updateNfeSetting(nfe: Nfe) {
    const nfeArray = this.nfeSettings$.value;
    const updatedNfeIndex = nfeArray.findIndex((el) => el.id === nfe.id);
    if (updatedNfeIndex !== -1) {
      nfeArray[updatedNfeIndex] = nfe;
      this.nfeSettings$.next([...nfeArray]);
      return;
    }
    this.nfeSettings$.next([...nfeArray, nfe]);
  }

  set nfeSettings(nfes: Nfe[]) {
    this.nfeSettings$.next([...nfes]);
  }

  set nfeSetting(nfe: Nfe) {
    const nfes = this.nfeSettings$.getValue();
    this.nfeSettings$.next([...nfes, nfe]);
  }
}
