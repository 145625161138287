import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  AddExtraCancellations,
  CancellationResponse,
  CancellationsResponse,
  StoreFilter,
  StoresResponse,
} from '@infrastructure/dtos';
import { StoreSettingsCreationResponse } from '@infrastructure/dtos/store/store-settings-creation-response.dto';
import { StoreSettingsResponse } from '@infrastructure/dtos/store/store-settings-response.dto';
import { StoreSettings } from '@model/interfaces/store/store-settings';
import { Royalty } from '../../../model/interfaces';
import { RoyaltyStoreSettingsResponse } from '../../dtos/store/store-settings-royalties.dto';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private URL = `${environment.apiUsersUrl}`;

  constructor(private readonly http: HttpClient) {}

  getManagerStores(filters?: StoreFilter): Observable<StoresResponse> {
    let params = new HttpParams();
    if (filters)
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== '') params = params.set(key, value);
      });
    return this.http.get<StoresResponse>(`${this.URL}/users/manager/stores`, {
      params,
    });
  }

  getAdminStores(filters?: StoreFilter): Observable<StoresResponse> {
    let params = new HttpParams();
    if (filters)
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== '') params = params.set(key, value);
      });
    return this.http.get<StoresResponse>(`${this.URL}/users/admin/stores`, {
      params,
    });
  }

  fetchStoreSettings(connectionId: number): Observable<StoreSettingsResponse> {
    const params = new HttpParams().set('connectionId', connectionId);
    return this.http.get<StoreSettingsResponse>(`${this.URL}/store-settings`, {
      params,
    });
  }

  createStoreSettings(
    storeSettings: StoreSettings
  ): Observable<StoreSettingsCreationResponse> {
    return this.http.post<StoreSettingsCreationResponse>(
      `${this.URL}/store-settings`,
      storeSettings
    );
  }

  createStoreSettingsRoyalties(storeSettings: Royalty[]) {
    return this.http.post<RoyaltyStoreSettingsResponse>(
      `${this.URL}/store-settings/royalties`,
      {
        storeSettings,
      }
    );
  }

  fetchCancellations() {
    return this.http.get<CancellationsResponse>(
      `${this.URL}/cancellations/current`
    );
  }

  addExtraCancellations(dto: AddExtraCancellations) {
    return this.http.post<CancellationResponse>(
      `${this.URL}/cancellations/${dto.connectionId}/add-extras`,
      {
        quantity: dto.quantity,
        expirationDate: dto.expirationDate,
      }
    );
  }
}
