<div class="flex justify-between mb-2 col-span-2">
  <div class="ml-1 mb-2 font-semibold text-lg">Endereço</div>
  <div class="flex items-center">
    <span class="text-xs mr-1">Código postal</span>
    <mat-slide-toggle
      [checked]="isAddressFinder"
      (change)="onToggle($event.checked)"
      color="darkblue"
    ></mat-slide-toggle>
    <span class="text-xs">Endereço</span>
  </div>
</div>
<app-input
  *ngIf="!isAddressFinder"
  label="Localizador por código postal"
  [abstractControl]="innerControl"
></app-input>
<div [ngClass]="{ hide: !isAddressFinder }">
  <span class="ml-1">Localizador por endereço</span>
  <input
    class="w-full mb-6 rounded"
    #fullAddress
    type="text"
    placeholder="Localizador por endereço"
    [formControl]="innerControl"
  />
</div>
<app-input
  [maxlength]="50"
  label="Logradouro"
  [abstractControl]="addressFormGroup.controls['streetName']"
></app-input>
<app-input
  label="Número"
  [maxlength]="5"
  [abstractControl]="addressFormGroup.controls['number']"
></app-input>
<app-input
  label="Complemento"
  [maxlength]="20"
  [abstractControl]="addressFormGroup.controls['complement']"
></app-input>
<app-input
  label="Country code"
  [maxlength]="2"
  [abstractControl]="addressFormGroup.controls['countryCode']"
></app-input>
<app-input
  label="Código postal"
  [abstractControl]="addressFormGroup.controls['postalCode']"
  placeholder="00000-000"
></app-input>
<app-input
  label="Estado"
  [maxlength]="2"
  [abstractControl]="addressFormGroup.controls['state']"
></app-input>
<app-input
  label="Cidade"
  [maxlength]="20"
  [abstractControl]="addressFormGroup.controls['city']"
></app-input>
<app-input
  label="Bairro"
  [maxlength]="40"
  [abstractControl]="addressFormGroup.controls['neighborhood']"
></app-input>
<app-input
  label="Instruções Adicionais"
  [maxlength]="150"
  [abstractControl]="addressFormGroup.controls['additionalComments']"
></app-input>
