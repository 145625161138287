import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { debounceTime, finalize, Observable } from 'rxjs';
import { LoadingService } from '@shared/services/loading.service';
import { IInterceptorFilter } from '@shared/interfaces/interceptor-filter';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(
    private readonly loadingService: LoadingService,

    @Optional()
    @Inject(IInterceptorFilter)
    private readonly blacklist?: IInterceptorFilter[]
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const hasSkip = !this.blacklist?.every((filter) =>
      filter.skipIf(this, request)
    );
    if (hasSkip) {
      this.loadingService.show();
    }
    return next.handle(request).pipe(
      debounceTime(1000),
      finalize(() => {
        if (hasSkip) {
          this.loadingService.hide();
        }
      })
    );
  }
}
