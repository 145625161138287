<h1 class="uppercase border-b py-2 px-5" mat-dialog-title>
  {{ modalOptions ? modalOptions.title : "Desconto" }}
</h1>
<form
  class="flex flex-col gap-2 pt-2 pb-5 px-5 bg-gray-100"
  [formGroup]="formGroup"
  (submit)="addDiscount()"
>
  <input type="text" formControlName="numPadValue" />
  <app-tabs
    class="bg-gray-200"
    (optionChanged)="option($event)"
    [options]="TAB_OPTIONS"
  ></app-tabs>

  <div class="grid grid-cols-3 grid-rows-4 gap-1">
    <button
      type="button"
      *ngFor="let btnLbl of buttons"
      (click)="setNumpadValue(btnLbl)"
      class="border py-3 bg-white px-4 font-bold flex justify-center items-center"
    >
      <ng-container *ngIf="btnLbl !== 'backspace'">
        {{ btnLbl }}
      </ng-container>
      <ng-container *ngIf="btnLbl === 'backspace'">
        <mat-icon [fontIcon]="btnLbl"></mat-icon>
      </ng-container>
    </button>
  </div>
  <button class="bg-hubster-primary text-white" [disabled]="formGroup.invalid">
    {{ modalOptions ? modalOptions.submitButtonTxt : "Adicionar desconto" }}
  </button>
</form>
