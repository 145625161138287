import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

interface OrderDiscountForm {
  type: FormControl<string>;
  numPadValue: FormControl<string>;
}
@Component({
  selector: 'app-order-discount',
  templateUrl: './order-discount.component.html',
  styleUrls: ['./order-discount.component.scss'],
})
export class OrderDiscountComponent implements OnInit {
  formGroup!: FormGroup<OrderDiscountForm>;
  buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, 'backspace'];

  modalOptions?: {
    submitButtonTxt: string;
    title: string;
  };

  TAB_OPTIONS = [
    {
      name: 'Quantia',
      value: 'amount',
      active: true,
    },
    {
      name: 'Percentagem',
      value: 'percentage',
      active: false,
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<OrderDiscountComponent>,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.nonNullable.group({
      type: ['amount', Validators.required],
      numPadValue: ['', Validators.required],
    });
  }

  addDiscount() {
    this.dialogRef.close(this.formGroup.value);
  }

  option(value: string) {
    this.formGroup.controls.type.setValue(value);
  }

  setNumpadValue(value: string | number) {
    const { numPadValue } = this.formGroup.value;

    if (numPadValue === undefined) {
      return;
    }

    if (value === 'backspace') {
      this.formGroup.controls.numPadValue.setValue(
        numPadValue.slice(0, numPadValue.length - 1)
      );
      return;
    }

    if (value === '.' && numPadValue[numPadValue.length - 1] === '.') {
      return;
    }

    this.formGroup.controls.numPadValue.setValue(
      numPadValue.concat(String(value))
    );
  }
}
