import { CreateStockItem } from '@model/interfaces';
import { Injectable } from '@angular/core';
import { catchError, map, of, shareReplay, take } from 'rxjs';

import { StockStore } from '@application/stores';
import { StockService } from '@infrastructure/api';
import { StockItemsResponse } from '@infrastructure/dtos';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class StockFacade {
  constructor(
    private stockService: StockService,
    private stockStore: StockStore,
    private toastrService: ToastrService
  ) {}

  getStockSettings(connectionId: number) {
    this.stockService
      .getStockSettings(connectionId)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.stockStore.stockSettings = res.data;
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  createStockSettings(
    menuName: string,
    stockEnabled: boolean,
    connectionId: number
  ) {
    this.stockService
      .createStockSettings(menuName, stockEnabled, connectionId)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.stockStore.updateSetting(res.data);
          this.toastrService.success('Stock editado com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  getStockItemsByStockSetting(stockSettingId: number) {
    this.stockService
      .getStockItemsByStockSetting(stockSettingId)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.stockStore.stockItems = res.data;
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  createStockItems(stockSettingId: number, items: CreateStockItem[]) {
    this.stockService
      .createStockItems(stockSettingId, items)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.stockStore.stockItems = res.data;
          this.toastrService.success('Itens editados com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  updateItemQuantity(itemId: number, quantity: number) {
    this.stockStore.updateItemQuantity(itemId, quantity);
  }

  fetchStockItems() {
    this.stockService
      .fetchStockItems()
      .pipe(
        take(1),
        shareReplay(1),
        catchError((err) => {
          this.toastrService.error(err.error.error.message);
          return of({} as StockItemsResponse);
        })
      )
      .subscribe((stocks: StockItemsResponse) => {
        this.stockStore.stockItems = stocks.data;
      });
  }

  getStockItems() {
    return this.stockStore.getStockItems().pipe(
      shareReplay(1),
      map(
        (items) =>
          items?.reduce(
            (acc, cur) => ({ ...acc, [cur.itemName]: cur.stock }),
            {} as { [id: string]: number }
          ) || {}
      )
    );
  }
}
