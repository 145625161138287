import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map, Observable, shareReplay, take } from 'rxjs';

import { AuthStore, PaymentMethodStore } from '@application/stores';
import { PaymentMethodService } from '@infrastructure/api';
import { PAYMENT_MODE } from '@model/enums';
import { PaymentMethod } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodFacade {
  constructor(
    private paymentMethodService: PaymentMethodService,
    private paymentMethodStore: PaymentMethodStore,
    private toastrService: ToastrService,
    private authStore: AuthStore
  ) {}

  getAllPaymentMethods() {
    this.paymentMethodService
      .getAllPaymentMethods()
      .pipe(shareReplay(1))
      .subscribe((methods) => {
        this.paymentMethodStore.paymentMethods = methods.data;
      });
  }
  createPaymentMethod(name: string) {
    this.paymentMethodService
      .createPaymentMethod(name)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.paymentMethodStore.addPaymentMethod(res.data);
          this.toastrService.success('Pagamento criado com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }
  updatePaymentMethod(id: number, name: string) {
    this.paymentMethodService
      .updatePaymentMethod(id, name)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.paymentMethodStore.updatePaymentMethod(res.data);
          this.toastrService.success('Pagamento atualizado com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  associatePaymentMethodsToStore(storeId: number, paymentMethodIds: number[]) {
    this.paymentMethodService
      .associatePaymentMethodsToStore(storeId, paymentMethodIds)
      .pipe(shareReplay(1))
      .subscribe({
        next: () => {
          this.toastrService.success('Pagamentos associados com sucesso');
        },
        error: (err) => {
          this.toastrService.error(err.error.error.message);
        },
      });
  }

  getPaymentMethodsByStore(storeId: number) {
    this.paymentMethodService
      .getPaymentMethodsByStore(storeId)
      .pipe(take(1))
      .subscribe((res) => {
        this.paymentMethodStore.storePaymentMethods = res.data;
      });
  }

  setPaymentMethodsByStore() {
    this.paymentMethodService
      .getPaymentMethodsByStore(this.authStore.getConnectionIds()[0])
      .pipe(take(1))
      .subscribe((res) => {
        this.paymentMethodStore.storePaymentMethods = res.data;
      });
  }

  getStorePaymentMethods(): Observable<PaymentMethod[]> {
    return this.paymentMethodStore.getStorePaymentMethods();
  }

  getStorePaymentMethodsSnapshot(): PaymentMethod[] {
    return this.paymentMethodStore.getStorePaymentMethodsSnapshot();
  }

  getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.paymentMethodStore
      .getStorePaymentMethods()
      .pipe(
        map((paymentMethods) =>
          paymentMethods.filter(
            (paymentMethod) => paymentMethod.name !== 'PACOTE'
          )
        )
      );
  }

  setPaymentMethod(method: PAYMENT_MODE) {
    this.paymentMethodStore.paymentMode = method;
  }
}
