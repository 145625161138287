import { Pipe, PipeTransform } from '@angular/core';
import { SidebarOption } from 'app/modules/sidebar/models/sidebar-option.interface';

@Pipe({
  name: 'sortSidebar',
})
export class SortSidebarPipe implements PipeTransform {
  transform(options: SidebarOption[] | null): SidebarOption[] {
    if (options == null) return [] as SidebarOption[];
    if (!options[0]?.sorted == true) return options;
    const optionsSorted = options.sort((acc, act) =>
      acc.name > act.name ? 1 : -1
    );
    return optionsSorted;
  }
}
