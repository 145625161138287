import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  UrlTree,
} from '@angular/router';

import { AuthFacade } from '@application/facades';
import { AuthStore } from '@application/stores';
import { ROLE } from '@model/enums';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): boolean | UrlTree => {
  const router = inject(Router);
  const authFacade = inject(AuthFacade);
  const authStore = inject(AuthStore);

  if (authFacade.isLoggedIn()) {
    const roles = authStore.getRoles();
    const roleMatches = route.data['roles'].some((r: ROLE) =>
      roles.includes(r)
    );

    if (roleMatches) {
      return true;
    } else {
      switch (roles[0]) {
        case ROLE.INTEGRATION_ADMIN:
          return router.parseUrl('/admin/packages');
        case ROLE.STORE_MANAGER:
          return router.parseUrl('/manager/stores');
        case ROLE.STORE_USER:
          return router.parseUrl('/orders');
      }
    }
  }

  return router.parseUrl('/auth/login');
};
