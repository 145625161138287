import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthFacade } from '@application/facades';
import { LoadingService } from '@shared/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isLoading$: Observable<boolean> = this.loadingService.getIsLoading();

  constructor(
    private authFacade: AuthFacade,
    private loadingService: LoadingService
  ) {
    this.authFacade.loadAuth();
  }
}
