import { Injectable } from '@angular/core';
import {
  HubsterMenuCategoriesPublish,
  HubsterMenuItemsPublish,
  HubsterMenuModifierGroupsPublish,
  HubsterMenuPhotosPublish,
  HubsterMenusPublish,
} from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData';
import { IStockFacade } from 'app/modules/_stock/public-api';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatest, filter, map } from 'rxjs';
import { IHubsterFacade } from '../interfaces/hubster-facade.interface';
import { IHubsterRepository } from '../interfaces/hubster-repository.interface';
import { IHubsterStore } from '../interfaces/hubster-store.interface';
import { MenuCategory } from '../models/menu-category.model';
import { MenuItem, MenuItemMap } from '../models/menu-item.model';
import {
  MenuModifierGroup,
  MenuModifierGroupMap,
} from '../models/menu-modifier-group.model';
import { Menu } from '../models/menu.model';

@Injectable()
export class HubsterFacade implements IHubsterFacade {
  constructor(
    private readonly hubsterRepository: IHubsterRepository,
    private readonly hubsterStore: IHubsterStore,
    private readonly stockFacade: IStockFacade,
    private readonly toastrService: ToastrService
  ) {}

  initData(): void {
    this.fetchPublishedMenu();
  }

  initAdminData(): void {
    this.fetchAdminMenu();
  }

  getMenus(): Observable<Menu[] | null> {
    return this.hubsterStore.getMenus().pipe(
      filter((menus) => !!menus),
      map(
        (menus) =>
          menus!
            .sort((acc, cur) => (acc.name > cur.name ? 1 : -1))
            .map((menu) => ({
              ...menu,
              categories: this.sortCategories(menu.categories),
            })) || []
      )
    );
  }

  private sortCategories(categories: MenuCategory[]): MenuCategory[] {
    return categories.sort((acc, cur) => (acc.name > cur.name ? 1 : -1));
  }

  getMenuItems(): Observable<MenuItem[] | null> {
    return this.hubsterStore.getMenuItems();
  }

  getMenuItemsByIds(itemIds: string[]): Observable<MenuItem[]> {
    return this.getMenuItems().pipe(
      filter((items) => !!items),
      map((items) => items!.filter((item) => itemIds.indexOf(item.id) !== -1)),
      map((items) => items.sort((acc, cur) => (acc.name > cur.name ? 1 : -1)))
    );
  }

  clearData() {
    this.hubsterStore.setMenus(null);
    this.hubsterStore.setMenuItems(null);
  }

  private fetchPublishedMenu(): void {
    combineLatest([
      this.stockFacade.getStockItemsMap(),
      this.hubsterRepository.fetchPublishedMenu(),
    ]).subscribe({
      next: ([stock, hubsterResponse]) => {
        const menus = this.mapMenus(
          hubsterResponse.data.menus,
          hubsterResponse.data.categories
        );
        const menuItems = this.mapMenuItems(
          hubsterResponse.data.items,
          hubsterResponse.data.photos,
          stock
        );

        this.mapModifiers(hubsterResponse.data.modifierGroups);
        this.hubsterStore.setMenus(menus);
        this.hubsterStore.setMenuItems(menuItems);
      },
      error: (err) =>
        this.toastrService.error(
          err.status === 404
            ? 'A loja não possui um menu associado'
            : 'Erro desconhecido'
        ),
    });
  }

  private fetchAdminMenu(): void {
    this.hubsterRepository.fetchAdminMenu().subscribe({
      next: (hubsterResponse) => {
        const menus = this.mapMenus(
          hubsterResponse.data.menus,
          hubsterResponse.data.categories
        );
        const menuItems = this.mapMenuItems(
          hubsterResponse.data.items,
          hubsterResponse.data.photos,
          {}
        );

        this.mapModifiers(hubsterResponse.data.modifierGroups);
        this.hubsterStore.setMenus(menus);
        this.hubsterStore.setMenuItems(menuItems);
      },
    });
  }

  private mapMenus(
    menus: HubsterMenusPublish,
    categories: HubsterMenuCategoriesPublish
  ): Menu[] {
    return Object.values(menus).map((menu) => {
      return {
        id: menu.id,
        name: menu.name,
        description: menu.description,
        categories: menu.categoryIds.map((id) => {
          return {
            id: categories[id].id,
            name: categories[id].name,
            description: categories[id].description,
            itemIds: categories[id].itemIds,
          };
        }),
      } as Menu;
    });
  }

  private mapMenuItems(
    items: HubsterMenuItemsPublish,
    photos: HubsterMenuPhotosPublish,
    stock: { [name: string]: number }
  ): MenuItem[] {
    const formatItems: MenuItemMap = {};
    const itemsArray = Object.entries(items).map(([key, item]) => {
      formatItems[key] = {
        id: item.id,
        name: item.name,
        description: item.description,
        status: item.status.saleStatus,
        price: item.price,
        limit: stock[item.name],
        modifierGroups: item.modifierGroupIds,
        photos: item.photoIds.map((id) => {
          return {
            id: photos[id].id,
            url: photos[id].url,
          };
        }),
      } as MenuItem;
      return formatItems[key];
    });
    this.hubsterStore.setItems(formatItems);
    return itemsArray;
  }

  private mapModifiers(modifiers: HubsterMenuModifierGroupsPublish) {
    const formatModifiers: MenuModifierGroupMap = {};
    Object.entries(modifiers).forEach(([key, value]) => {
      formatModifiers[key] = {
        id: value.id,
        name: value.name,
        description: value.description,
        itemIds: value.itemIds,
        minimumSelections: value.minimumSelections,
        maximumSelections: value.maximumSelections,
        maxPerModifierSelectionQuantity: value.maxPerModifierSelectionQuantity,
      };
    });
    this.hubsterStore.setModifierGroups(formatModifiers);
  }

  getItem(itemId: string): MenuItem | null {
    const items = this.hubsterStore.getItemsSnapshot();
    return items ? items[itemId] : null;
  }

  getItemByName(name: string): MenuItem | null {
    const items = this.hubsterStore.getMenuItemsSnapshot();
    if (!items) return null;
    const item = items.find((i) => i.name === name);
    return item ? item : null;
  }

  getModifierGroup(modifierId: string): MenuModifierGroup | null {
    const modifiers = this.hubsterStore.getModifierGroupsSnapshot();
    return modifiers ? modifiers[modifierId] : null;
  }
}
