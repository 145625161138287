import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RoyaltyDetail } from '../../../model/interfaces/royalties/royalty-detail';
import { Royalty } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class RoyaltiesStore {
  private royalties$ = new BehaviorSubject<Royalty[]>([]);

  private royaltyDetails$ = new BehaviorSubject<RoyaltyDetail[]>([]);
  private totalSales$ = new BehaviorSubject<number>(0);
  private totalEarnings$ = new BehaviorSubject<number>(0);

  getRoyalties() {
    return this.royalties$.asObservable();
  }
  getRoyaltyDetails() {
    return this.royaltyDetails$.asObservable();
  }
  getTotalSales() {
    return this.totalSales$.asObservable();
  }
  getTotalEarnings() {
    return this.totalEarnings$.asObservable();
  }

  setRoyalties(royalties: Royalty[]) {
    this.royalties$.next(royalties);
  }
  setRoyaltyDetails(royalties: RoyaltyDetail[]) {
    this.royaltyDetails$.next(royalties);
  }
  setTotalSales(number: number) {
    this.totalSales$.next(number);
  }
  setTotalEarnings(number: number) {
    this.totalEarnings$.next(number);
  }

  reset(): void {
    this.royalties$.next([]);
    this.royaltyDetails$.next([]);
    this.totalSales$.next(0);
    this.totalEarnings$.next(0);
  }
}
