import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusJobs',
})
export class StatusJobsPipe implements PipeTransform {
  dictionary: { [key: string]: string } = {
    FinishedWithErrors: 'Finalizada com erros',
    Finished: 'Finalizada',
    Error: 'Erro',
    Pending: 'Pendente',
  };
  transform(status: string): string {
    return this.dictionary[status] || 'Outro';
  }
}
