import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { AddressResponse } from '@infrastructure/dtos';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private URL = environment.mapServiceUrl;

  constructor(private readonly http: HttpClient) {}

  getDataFromZipcode(zipcode: string, iso = 'br'): Observable<AddressResponse> {
    return this.http.post<AddressResponse>(`${this.URL}/zipcode`, {
      zipcode,
      iso,
    });
  }
}
