import { CreateStockItem } from '@model/interfaces';
import { Observable } from 'rxjs';
import { StockItemsResponse } from '../types/stock-items-response.type';
import { StockSettingResponse } from '../types/stock-setting-response.type';
import { StockSettingsResponse } from '../types/stock-settings-response.type';

export abstract class IStockRepository {
  abstract fetchStockSettings(
    connectionId: number
  ): Observable<StockSettingsResponse>;
  abstract createStockSettings(
    menuName: string,
    stockEnabled: boolean,
    connectionId: number
  ): Observable<StockSettingResponse>;
  abstract createStockItems(
    stockSettingsId: number,
    items: CreateStockItem[]
  ): Observable<StockItemsResponse>;
  abstract stockItemsByStockSettings(
    stockSettingsId: number
  ): Observable<StockItemsResponse>;
  abstract stockItems(): Observable<StockItemsResponse>;
}
