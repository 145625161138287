import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  AssociatedStoresResponse,
  AvailableStoresResponse,
  EmptyResponse,
  PackageResponse,
  PackagesInStoreResponse,
  PackagesResponse,
} from '@infrastructure/dtos';
import { Package, StoreToBeAssociated } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  private URL = `${environment.apiMenuUrl}`;

  constructor(private readonly http: HttpClient) {}

  fetchPackages(
    page: number,
    size: number,
    filter: string
  ): Observable<PackagesResponse> {
    let params = new HttpParams();
    if (page) params = params.set('page', page);
    if (size) params = params.set('size', size);
    if (filter != '') params = params.set('filter', filter);
    return this.http.get<PackagesResponse>(`${this.URL}/packages`, {
      params,
    });
  }

  fetchPackage(id: number): Observable<PackageResponse> {
    return this.http.get<PackageResponse>(`${this.URL}/packages/${id}`);
  }

  fetchAssociatedStores(id: number): Observable<AssociatedStoresResponse> {
    return this.http.get<AssociatedStoresResponse>(
      `${this.URL}/packages/${id}/stores`
    );
  }

  createPackage(newPackage: Package): Observable<PackageResponse> {
    return this.http.post<PackageResponse>(`${this.URL}/packages`, newPackage);
  }

  associateStores(
    id: number,
    associatedStores: StoreToBeAssociated[]
  ): Observable<EmptyResponse> {
    return this.http.put<EmptyResponse>(`${this.URL}/packages/${id}/stores`, {
      stores: associatedStores,
    });
  }

  disassociateStore(
    packageId: number,
    connectionId: number
  ): Observable<EmptyResponse> {
    return this.http.delete<EmptyResponse>(
      `${this.URL}/packages/${packageId}/stores/${connectionId}`
    );
  }

  fetchPackagesInStore(): Observable<PackagesInStoreResponse> {
    return this.http.get<PackagesInStoreResponse>(`${this.URL}/packages/store`);
  }

  updatePackage(
    id: number,
    packageToBeUpdated: Package
  ): Observable<PackageResponse> {
    return this.http.put<PackageResponse>(
      `${this.URL}/packages/${id}`,
      packageToBeUpdated
    );
  }

  fetchAvailableStores(packageId: number): Observable<AvailableStoresResponse> {
    return this.http.get<AvailableStoresResponse>(
      `${this.URL}/packages/${packageId}/stores/available`
    );
  }
}
