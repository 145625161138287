import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { Cart, OrderDiscount, UserName } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class CartStore {
  private discount$: BehaviorSubject<OrderDiscount> = new BehaviorSubject({
    type: 'amount',
    value: 0,
  } as OrderDiscount);

  private cart$: BehaviorSubject<Cart> = new BehaviorSubject({
    orderId: this.setOrderId(),
    positionId: -1,
    customerId: 0,
    userId: 0,
    tip: 0,
    currency: 'US',
    total: {
      total: 0,
      subtotal: 0,
    },
  } as Cart);
  //--------- OrderDiscount ---------
  set discount(discount: OrderDiscount) {
    this.discount$.next({ ...discount });
  }

  getDiscount(): Observable<OrderDiscount> {
    return this.discount$.asObservable();
  }

  getDiscountValue(): OrderDiscount {
    return this.discount$.getValue();
  }
  //--------- Cart ---------
  getCart(): Observable<Cart> {
    return this.cart$.asObservable();
  }

  set cart(cart: Partial<Cart>) {
    const cartValue = this.cart$.getValue();
    this.cart$.next({ ...cartValue, ...cart });
  }

  newOrderId(order?: string): void {
    const cart = this.cart$.getValue();
    const orderId = !order
      ? cart.orderId.toString().split('-')
      : order.toString().split('-');
    orderId[1] = (Number(orderId[1]) + 1).toString();
    this.cart$.next({ ...cart, orderId: `${orderId[0]}-${orderId[1]}` });
  }

  setOrderId(): string {
    const uDate = new Date();
    let day: number | string = uDate.getDate();
    if (day < 10) day = `0${day}`;
    let month: number | string = uDate.getMonth() + 1;
    if (month < 10) month = `0${month}`;
    const rnd = Math.floor(Math.random() * 9000);
    const orderId = `${day}${month}${uDate.getFullYear()}-${rnd}`;

    return orderId;
  }

  set position(positionId: number) {
    const cart = this.cart$.getValue();
    this.cart$.next({ ...cart, positionId });
  }
  getPositionIdValue() {
    return this.cart$.getValue().positionId;
  }

  set userId(userId: number) {
    const cart = this.cart$.getValue();
    this.cart$.next({ ...cart, userId });
  }

  set tip(tip: number) {
    const cart = this.cart$.getValue();
    this.cart$.next({ ...cart, tip });
  }

  reset() {
    this.cart$.next({
      orderId: this.setOrderId(),
      positionId: -1,
      customerId: 0,
      userId: 0,
      tip: 0,
      currency: 'US',
      total: {
        total: 0,
        subtotal: 0,
      },
    } as Cart);
    this.discount$.next({
      type: 'amount',
      value: 0,
    });
  }

  set hasStockItems(hasStockItems: boolean | undefined) {
    const cart = this.cart$.getValue();
    this.cart$.next({ ...cart, hasStockItems });
  }

  set nfeSettingsId(nfeSettingsId: number) {
    const cart = this.cart$.getValue();
    this.cart$.next({ ...cart, nfeSettingsId });
  }

  set storeUser(storeUser: UserName) {
    const cart = this.cart$.getValue();
    this.cart$.next({ ...cart, storeUser });
  }

  hasDiscount = () =>
    this.discount$.asObservable().pipe(map((discount) => discount.value > 0));
}
