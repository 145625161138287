import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { POSITION_FILTER } from '@model/enums';
import { Position } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PositionStore {
  private positions$: BehaviorSubject<Position[]> = new BehaviorSubject(
    [] as Position[]
  );

  private totalPositions$: BehaviorSubject<number> = new BehaviorSubject(0);

  getPositions(filter: POSITION_FILTER, text: string): Observable<Position[]> {
    return this.positions$.asObservable().pipe(
      map((positions) =>
        positions
          .sort((a, b) => a.number - b.number)
          .filter((position) => {
            return filter === POSITION_FILTER.ALL
              ? true
              : filter === POSITION_FILTER.EMPTY
              ? position.order === null
              : position.order !== null;
          })
          .filter((position) => {
            if (text === '') return true;
            let inNumber = false;
            let inCustomerName = false;
            let inOrderId = false;

            if (parseInt(text)) {
              inNumber = position.number === parseInt(text);
            }
            if (position.order) {
              inCustomerName = position.order.customerName
                .toLowerCase()
                .includes(text);
              if (parseInt(text)) {
                inOrderId = position.order.id === parseInt(text);
              }
            }

            return inCustomerName || inOrderId || inNumber;
          })
      )
    );
  }

  set positions(positions: Position[]) {
    this.positions$.next([...positions]);
  }

  getTotalPositions() {
    return this.totalPositions$.asObservable();
  }

  set totalPositions(total: number) {
    this.totalPositions$.next(total);
  }
}
