import { inject, Injectable } from '@angular/core';
import {
  catchError,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  take,
} from 'rxjs';

import { Router } from '@angular/router';
import { OrderStore, StoreStore } from '@application/stores';
import { InvoiceStore } from '@application/stores/invoice/invoice.store';
import { OrderService } from '@infrastructure/api';
import { InvoiceResponse } from '@infrastructure/dtos/invoice/invoice-response';
import { Cart, Order, OrderFilter, StatusInvoice } from '@model/interfaces';
import { InvoiceBody } from '@model/interfaces/invoice/invoice-body';
import { UTCToTimezoned } from 'app/utils/utc-to-timezoned.function';
import { ToastrService } from 'ngx-toastr';
import {
  formatIntlCompleteDate,
  formatIntlShortDate,
} from '../../../../utils/intl-formatters.function';

@Injectable({
  providedIn: 'root',
})
export class OrderFacade {
  private readonly toastrService = inject(ToastrService);
  private readonly router = inject(Router);
  private readonly invoiceStore = inject(InvoiceStore);
  statusInvoice: 'PROCESSING' | 'DONE' = 'DONE';

  constructor(
    private readonly orderService: OrderService,
    private readonly orderStore: OrderStore,
    private readonly storeStore: StoreStore
  ) {}

  orders() {
    // TODO: Improve
    return this.orderStore.getOrders('');
  }

  fetchOrder(id: number): void {
    combineLatest([
      this.storeStore
        .getStoreSettings()
        .pipe(filter((storeSettings) => Boolean(storeSettings.timezone))),
      this.orderService.fetchOrder(id),
    ])
      .pipe(take(1))
      .subscribe(([storeSettings, res]) => {
        const { timezone } = storeSettings.timezone;
        const { deliveryTime, pickupTime } = res.data.fulfillmentInfo;
        const customerP = res.data.customerPayments;

        const customerPayments = customerP.map((customer) => {
          return {
            ...customer,
            paymentDate: UTCToTimezoned(customer.paymentDate!, timezone),
          };
        });

        this.orderStore.orderDetail = {
          ...res.data,
          customerPayments,
          orderedAt: UTCToTimezoned(res.data.orderedAt, timezone),
          completionDate: res.data.completionDate
            ? UTCToTimezoned(res.data.completionDate, timezone)
            : undefined,
          cancelationDate: res.data.cancelationDate
            ? UTCToTimezoned(res.data.cancelationDate, timezone)
            : undefined,
          fulfillmentInfo: {
            ...res.data.fulfillmentInfo,
            deliveryTime: deliveryTime
              ? UTCToTimezoned(deliveryTime!, timezone)
              : '',
            pickupTime: pickupTime ? UTCToTimezoned(pickupTime!, timezone) : '',
          },
        };
      });
  }

  fetchOrders(fetchFilter: OrderFilter): void {
    combineLatest([
      this.storeStore
        .getStoreSettings()
        .pipe(filter((storeSettings) => Boolean(storeSettings.timezone))),
      this.orderService.fetchOrders(fetchFilter),
    ])
      .pipe(take(1))
      .subscribe(([storeSettings, res]) => {
        const { timezone } = storeSettings.timezone;
        this.orderStore.orders = res.data.map((order) => ({
          ...order,
          fulfillmentDate: order.fulfillmentDate
            ? UTCToTimezoned(order.fulfillmentDate as string, timezone)
            : order.fulfillmentDate,
        }));
        this.orderStore.totalOrders = res.total;
      });
  }

  /**
   * Returns the orders response straight from backend
   * without setting the store
   * @param fetchFilter parameters for order filtering
   * @returns Observable with orders that match the filters
   */
  fetchOrdersWithoutStoring(fetchFilter: OrderFilter): Observable<Order[]> {
    return this.orderService
      .fetchOrders(fetchFilter)
      .pipe(map((res) => res.data));
  }

  createOrder(cart: Cart): void {
    combineLatest([
      this.storeStore
        .getStoreSettings()
        .pipe(filter((storeSettings) => Boolean(storeSettings.timezone))),
      this.orderService.createOrder(cart),
    ])
      .pipe(take(1))
      .subscribe(([storeSettings, res]) => {
        const { timezone } = storeSettings.timezone;
        const { deliveryTime, pickupTime } = res.data.fulfillmentInfo;

        this.orderStore.orderDetail = {
          ...res.data,
          orderedAt: formatIntlCompleteDate(
            timezone,
            new Date(res.data.orderedAt)
          ),
          fulfillmentInfo: {
            ...res.data.fulfillmentInfo,
            deliveryTime: deliveryTime
              ? formatIntlCompleteDate(timezone, new Date(deliveryTime))
              : deliveryTime,
            pickupTime: pickupTime
              ? formatIntlCompleteDate(timezone, new Date(pickupTime))
              : pickupTime,
          },
        };
      });
  }

  setOrderAsComplete(orderId: number, userId: number) {
    this.orderService
      .setOrderAsComplete(orderId, userId)
      .pipe(shareReplay(1))
      .subscribe({
        next: () => {
          this.toastrService.success('Pedido finalizado com sucesso');
          this.router.navigate(['/orders']);
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  orderInvoice(orderId: number) {
    this.orderService
      .orderInvoice(orderId)
      .pipe(
        take(1),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catchError((err: any) => {
          this.toastrService.error(err.error.error.message);
          return of(false);
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((res: any) => {
        if (res) this.toastrService.success(res);
      });
  }

  cancelOrder(id: number, userId: number) {
    this.orderService
      .cancelOrder(id, userId)
      .pipe(
        take(1),
        catchError((err) => {
          this.toastrService.error(err.error.error.message);
          return of({ success: false });
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((res: any) => {
        if (!res.success) return;
        this.toastrService.success('Pedido cancelado com sucesso');
        this.router.navigate(['/orders']);
      });
  }

  createInvoices(ids: number[], filter: OrderFilter) {
    const invoiceRequest: InvoiceBody = {
      eventType: 'invoices',
      metadata: { payload: { orderIds: [] } },
    } as InvoiceBody;
    invoiceRequest.metadata.payload.orderIds = [...ids];
    this.orderService
      .createInvoices(invoiceRequest)
      .pipe(take(1))
      .subscribe((res) => {
        const { status, jobId } = res.data;
        this.invoiceStore.status = status as StatusInvoice;
        this.invoiceStore.jobId = jobId;
        this.invoiceStore.saveStoreInvoice();
        this.invoicesJob(jobId, filter);
      });
  }

  invoicesJob(jobId: string, filter?: OrderFilter) {
    this.orderService
      .invoicesJob(jobId)
      .pipe(
        take(1),
        catchError(() => {
          this.toastrService.warning('Revise a geração de NFes');
          this.statusInvoice = 'DONE';
          return of({} as InvoiceResponse);
        })
      )
      .subscribe((res) => {
        const { status, jobId } = res.data;
        this.statusInvoice = status == 'Pending' ? 'PROCESSING' : 'DONE';
        this.invoiceStore.status = status as StatusInvoice;
        this.invoiceStore.jobId = jobId;
        if (filter) {
          this.invoiceStore.ids = [];
          this.fetchOrders(filter);
        }
        status == 'Pending'
          ? setTimeout(() => this.invoicesJob(jobId), 30000)
          : this.generateMsg(status);
      });
  }

  generateMsg = (status: string) => {
    this.invoiceStore.saveStoreInvoice();
    this.fetchOrders({
      date: new Date().toISOString().split('T')[0],
      size: 10,
    });
    status.includes('Error')
      ? this.toastrService.error(status)
      : this.toastrService.success(status);
  };

  createJob(jobId: string) {
    if (this.statusInvoice == 'PROCESSING') return;
    this.invoicesJob(jobId);
  }

  payOrder(
    orderId: number,
    userId: number,
    value: number,
    paymentMethodId: number
  ) {
    this.orderService
      .payOrder(orderId, userId, value, paymentMethodId)
      .pipe(shareReplay(1))
      .subscribe({
        next: () => {
          this.fetchOrder(orderId);
          this.toastrService.success(
            'Meio de pagamento adicionado com sucesso'
          );
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  clearOrderDetail() {
    this.orderStore.clearOrderDetail();
  }

  fetchAllInvoices(page: number, size: number) {
    combineLatest([
      this.storeStore.getStoreSettings(),
      this.orderService.fetchAllInvoices(page, size),
    ])
      .pipe(
        shareReplay(),
        take(1),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catchError((err: any) => {
          this.toastrService.warning(err.error.error.error.message);
          return of();
        })
      )
      .subscribe(([storeSettings, jobs]) => {
        this.orderStore.totalJobs = jobs.total;
        this.orderStore.jobs = jobs.data.map((job) => {
          const createdAt = formatIntlShortDate(
            storeSettings.timezone.timezone,
            new Date(job.createdAt)
          );
          const updatedAt = formatIntlShortDate(
            storeSettings.timezone.timezone,
            new Date(job.updatedAt)
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const orders = job.orders.map((order: any) => {
            const createdAt = formatIntlShortDate(
              storeSettings.timezone.timezone,
              new Date(order.createdAt)
            );
            const updatedAt = formatIntlShortDate(
              storeSettings.timezone.timezone,
              new Date(order.updatedAt)
            );
            return { ...order, createdAt, updatedAt };
          });
          return { ...job, createdAt, updatedAt, orders };
        });
      });
  }
}
