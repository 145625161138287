import { inject, Injectable } from '@angular/core';
import {
  CartStore,
  CustomerStore,
  OrderStore,
  PackageStore,
  PaymentMethodStore,
  PromotionStore,
} from '@application/stores';
import {
  FulfillmentInfoStore,
  ItemsStore,
  PaymentStore,
  TotalStore,
} from '@application/stores/cart';

@Injectable({
  providedIn: 'root',
})
export class CartFacade {
  private readonly cartStore = inject(CartStore);
  private readonly customerStore = inject(CustomerStore);
  private readonly fulfillmentInfoStore = inject(FulfillmentInfoStore);
  private readonly itemsStore = inject(ItemsStore);
  private readonly orderStore = inject(OrderStore);
  private readonly packageStore = inject(PackageStore);
  private readonly paymentMethodStore = inject(PaymentMethodStore);
  private readonly paymentStore = inject(PaymentStore);
  private readonly promotionStore = inject(PromotionStore);
  private readonly totalStore = inject(TotalStore);
  constructor() {}

  reset() {
    this.cartStore.reset();
    this.customerStore.resetCustomer();
    this.fulfillmentInfoStore.reset();
    this.itemsStore.clearItems();
    this.orderStore.reset();
    this.packageStore.resetPackageOrder();
    this.packageStore.resetPackageSelected();
    this.paymentMethodStore.reset();
    this.paymentStore.reset();
    this.promotionStore.reset();
    this.totalStore.reset();
  }
}
