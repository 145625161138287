import { Injectable } from '@angular/core';
import { CASHIER_MOVEMENT } from '@model/enums';
import {
  CashierStatus,
  CashierMovement,
  StatusSwitch,
  Summary,
} from '@model/interfaces';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CashierStore {
  constructor() {}

  private status$ = new BehaviorSubject<StatusSwitch>('PENDING');
  private cashier$ = new BehaviorSubject<CashierStatus>({} as CashierStatus);
  private movements$ = new BehaviorSubject<CashierMovement[]>(
    [] as CashierMovement[]
  );

  set status(status: StatusSwitch) {
    this.status$.next(status);
  }

  getStatus = () => this.status$.asObservable();

  set cashier(cashier: CashierStatus) {
    this.cashier$.next(structuredClone<CashierStatus>(cashier));
  }

  getCashier = () => this.cashier$.asObservable();
  getCashierValue = () => this.cashier$.getValue();

  set movements(movements: CashierMovement[]) {
    this.movements$.next([...movements]);
  }

  getMovements = () => this.movements$.asObservable();

  getTotalMovements = (cashierMovement: CASHIER_MOVEMENT) =>
    this.movements$.asObservable().pipe(
      map((movements) => {
        return movements
          .filter((movement) => movement.type == cashierMovement)
          .map((movement) => movement.amount)
          .reduce((acc, act) => (acc += act), 0);
      })
    );

  getTotalSummary = () =>
    this.cashier$.asObservable().pipe(
      map((cashier) => cashier.summary),
      map((summary) => {
        if (!summary) return 0;
        return summary
          .map((sum: Summary) => sum.amount)
          .reduce((acc: number, act: number) => (acc += act), 0);
      })
    );
}
