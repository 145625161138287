import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, of, shareReplay, take } from 'rxjs';

import { Router } from '@angular/router';
import { PromotionStore } from '@application/stores';
import { PromotionService } from '@infrastructure/api';
import { AssociatePromotions, PromotionRequest } from '@infrastructure/dtos';
import { Promotion } from '@model/interfaces';
import { PromotionFrontend } from '@model/interfaces/promotion/promotion-frontend';
import { UTCToTimezoned } from 'app/utils/utc-to-timezoned.function';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class PromotionFacade {
  constructor(
    private promotionService: PromotionService,
    private promotionStore: PromotionStore,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  getPromotions(filters: PromotionRequest) {
    this.promotionService
      .fetchPromotions(filters)
      .pipe(shareReplay(1))
      .subscribe((res) => {
        this.promotionStore.promotions = res.data.map((p) => ({
          ...p,
          startDate: UTCToTimezoned(p.startDate, 'utc'),
          endDate: UTCToTimezoned(p.endDate, 'utc'),
          shouldAllowAssociateEntities:
            DateTime.now().toFormat('yyyy-MM-dd') > p.endDate.split('T')[0],
        }));
        this.promotionStore.totalPromotions = res.total;
      });
  }

  setPromotion(promotion: PromotionFrontend) {
    this.promotionStore.promotion = promotion;
  }

  resetPromotions() {
    this.promotionStore.promotions = [];
  }

  createPromotion(promotion: Promotion) {
    this.promotionService
      .createPromotion(promotion)
      .pipe(
        take(1),
        shareReplay(),
        catchError((err) => {
          console.error(err.error.error.message);
          this.toastrService.error(
            'Duplicidade de nome, por favor mudar o nome da promoção'
          );
          return of();
        })
      )
      .subscribe((res) => {
        if (res) this.toastrService.success('Promoção criado com sucesso');
        this.router.navigate(['/admin/promotions']);
      });
  }

  editPromotion(promotion: Promotion) {
    this.promotionService
      .editPromotion(promotion)
      .pipe(
        take(1),
        shareReplay(),
        catchError((err) => {
          this.toastrService.error(err.error.error.message);
          return of();
        })
      )
      .subscribe((res) => {
        if (res) this.toastrService.success('Promoção editado com sucesso');
        this.router.navigate(['/admin/promotions']);
      });
  }

  associateCustomersToPromotion(promotionId: number, customers: number[]) {
    this.promotionService
      .associateCustomersToPromotion(promotionId, customers)
      .pipe(
        take(1),
        shareReplay(),
        catchError((err) => {
          this.toastrService.error(err.error.error.message);
          return of();
        })
      )
      .subscribe((res) => {
        if (res.success) {
          this.getAssociatedCustomers(promotionId);
          this.getAvailableCustomers(promotionId);
          this.toastrService.success('Promoção associado com sucesso');
        }
      });
  }

  associatePromotionsToCustomer(customerId: number, body: AssociatePromotions) {
    this.promotionService
      .associatePromotionsToCustomer(customerId, body)
      .pipe(take(1), shareReplay())
      .subscribe((res) => {
        if (res.success)
          this.toastrService.success('Clientes associado com sucesso');
      });
  }

  getStoreAvailable(id: number) {
    this.promotionService
      .fetchStoresAvailable(id)
      .pipe(take(1), shareReplay())
      .subscribe((res) => {
        if (res.data) this.promotionStore.avaliableStores = res.data;
      });
  }

  associateStoresToPromotion(id: number, stores: number[]) {
    this.promotionService
      .associateStoresToPromotion(id, stores)
      .pipe(take(1), shareReplay())
      .subscribe((res) => {
        if (!res.success)
          return this.toastrService.error('Um erro inesperado ocorreu');
        this.getAssociatedStores(id);
        this.getStoreAvailable(id);
        return this.toastrService.success('Vínculo feito com sucesso');
      });
  }

  getAssociatedStores(id: number) {
    this.promotionService
      .fetchAssociatedStores(id)
      .pipe(take(1), shareReplay())
      .subscribe((res: any) => {
        this.promotionStore.associationStores = res.data;
      });
  }

  getAvailableCustomers(promotionId: number) {
    this.promotionService
      .fetchAvailableCustomers(promotionId)
      .pipe(take(1), shareReplay())
      .subscribe((res) => {
        this.promotionStore.availableCustomers = res.data;
      });
  }

  getAssociatedCustomers(promotionId: number) {
    this.promotionService
      .fetchAssociatedCustomers(promotionId)
      .pipe(take(1), shareReplay())
      .subscribe((res) => {
        this.promotionStore.associatedCustomers = res.data;
      });
  }

  disassociatePromotionFromStore(connectionId: number, promotionId: number) {
    this.promotionService
      .disassociatePromotionFromStore(connectionId, promotionId)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          if (res) {
            this.getAssociatedStores(promotionId);
            this.getStoreAvailable(promotionId);
            this.toastrService.success('Loja desassociada com sucesso');
          }
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  disassociatePromotionFromCustomer(customerId: number, promotionId: number) {
    this.promotionService
      .disassociatePromotionFromCustomer(customerId, promotionId)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          if (res) {
            this.getAssociatedCustomers(promotionId);
            this.getAvailableCustomers(promotionId);
            this.toastrService.success('Clientes desassociado com sucesso');
          }
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  getPromotionsByCustomer(customerId: number) {
    this.promotionService
      .fetchPromotionsByCustomer(customerId)
      .pipe(take(1), shareReplay())
      .subscribe((promotions) => {
        this.promotionStore.promotionByCustomer = promotions.data.map((p) => ({
          ...p,
          startDate: UTCToTimezoned(p.startDate, 'utc'),
          endDate: UTCToTimezoned(p.endDate, 'utc'),
        }));
      });
  }

  getPromotionToCustomer(customerId?: number, paymentLater?: boolean) {
    this.promotionService
      .fetchPromotionToCustomer(customerId, paymentLater)
      .pipe(take(1), shareReplay())
      .subscribe(
        (promotions) =>
          (this.promotionStore.promotionByCustomer = promotions.data)
      );
  }

  reset() {
    this.promotionStore.reset();
  }
}
