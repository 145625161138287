import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { MenuResponse } from '@infrastructure/dtos';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private URL = `${environment.apiMenuUrl}/menu`;

  constructor(private http: HttpClient) {}

  fetchMenu() {
    return this.http.get<MenuResponse>(this.URL);
  }

  fetchMenuForStoreManager(connectionId: number) {
    const params = new HttpParams().append('connectionId', connectionId);
    return this.http.get<MenuResponse>(this.URL, { params });
  }

  fetchPackageMenu() {
    return this.http.get<MenuResponse>(`${this.URL}/packages`);
  }
}
