import { Injectable } from '@angular/core';
import {
  HubsterMenuPublish,
  HubsterMenusPublish,
} from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData';
import { take } from 'rxjs';

import { MenuStore } from '@application/stores';
import { MenuService } from '@infrastructure/api';
import { MenuResponse } from '@infrastructure/dtos';
import { ROLE } from '@model/enums';
import { SIDEBAR_OPTIONS } from 'app/modules/sidebar/config/sidebar-options.config';
import { SidebarText } from 'app/modules/sidebar/enums/sidebar-text.enum';
import { SidebarOption } from 'app/modules/sidebar/models/sidebar-option.interface';

@Injectable({
  providedIn: 'root',
})
export class MenuFacade {
  constructor(
    private readonly menuService: MenuService,
    private readonly menuStore: MenuStore
  ) {}

  private menuMap = new Map<string, string>();

  fetchMenu(): void {
    this.menuService
      .fetchMenu()
      .pipe(take(1))
      .subscribe((response: MenuResponse) => {
        this.menuStore.menu = response.data;
        if (this.menuMap.size == 0) this.setMenuMap();
      });
  }

  fetchMenuForStoreManager(connectionId: number): void {
    this.menuService
      .fetchMenuForStoreManager(connectionId)
      .pipe(take(1))
      .subscribe((response: MenuResponse) => {
        this.menuStore.menu = response.data;
      });
  }

  fetchPackageMenu(): void {
    this.menuService
      .fetchPackageMenu()
      .pipe(take(1))
      .subscribe((response: MenuResponse) => {
        this.menuStore.menu = response.data;
      });
  }

  setStaticSidebarOptions() {
    this.menuStore.sidebarOptions = SIDEBAR_OPTIONS;
  }

  setSidebarOptions(
    path: string,
    hasFilter: boolean,
    subPath?: string,
    exclude?: string
  ) {
    const options: SidebarOption[] = [];
    const roles: ROLE[] = [ROLE.INTEGRATION_ADMIN];
    const basePath =
      subPath && subPath != '' ? `${path}/${subPath}` : `${path}/create`;

    options.push({
      name: SidebarText.BACK_BUTTON,
      path,
      expanded: false,
      roles,
      hasOptions: false,
      options: [],
    });

    const menus: HubsterMenusPublish = this.menuStore.getMenus2();
    // Find Tabela menu
    if (hasFilter) {
      const tabelaMenu = Object.values(menus).find(
        (menu: HubsterMenuPublish) => menu.name === 'TABELA'
      );
      if (tabelaMenu) {
        options.push(this.setOption(tabelaMenu, roles, basePath));
      }
    } else if (exclude) {
      Object.values(menus)
        .filter((menu) => menu.name !== exclude)
        .forEach((menu) => {
          options.push(this.setOption(menu, roles, basePath));
        });
    } else {
      Object.values(menus).forEach((menu) => {
        options.push(this.setOption(menu, roles, basePath));
      });
    }
    this.menuStore.sidebarOptions = options;
  }

  private setOption(menu: HubsterMenuPublish, roles: ROLE[], basePath: string) {
    return {
      name: menu.name,
      path: '',
      expanded: false,
      roles,
      hasOptions: true,
      options: this.menuStore.filterCategories(
        menu.categoryIds,
        basePath,
        roles,
        menu.name
      ),
    };
  }

  setMenuMap() {
    const menus = this.menuStore.getMenus2();
    const roles: ROLE[] = [ROLE.INTEGRATION_ADMIN];
    Object.values(menus).forEach((menu) => {
      this.menuStore
        .filterCategories(menu.categoryIds, '', roles, menu.name)
        .map((option) => option.path.split('/').at(-1))
        .forEach((option) => {
          if (option) this.menuMap.set(option, menu.id);
        });
    });
  }

  getMenuMap = (id: string) => this.menuMap.get(id);

  hasMenuMap = (id: string) => this.menuMap.has(id);

  getCategoryByItem = (id: string) => this.menuStore.getCategoryByItemId(id);
}
