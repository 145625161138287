<ul class="min-w-full">
  <li *ngFor="let option of options | sortSidebar" class="min-w-full">
    <div
      class="flex px-3 py-5 cursor-pointer font-bold min-w-full"
      (click)="action(option)"
      routerLinkActive="router-link-active"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="canNavigate(option) | async"
    >
      <mat-icon *ngIf="option.name === sidebarText.BACK_BUTTON" class="mr-2">
        arrow_back
      </mat-icon>
      <a>{{ option.name | titlecase }}</a>
      <mat-icon *ngIf="option.hasOptions && option.expanded" class="ml-auto">
        expand_more
      </mat-icon>
      <mat-icon *ngIf="option.hasOptions && !option.expanded" class="ml-auto">
        navigate_next
      </mat-icon>
    </div>

    <app-sidebar-option
      [options]="option.options"
      *ngIf="option.expanded"
    ></app-sidebar-option>
  </li>
</ul>
