import {
  CASHIER_MOVEMENT,
  PRINTABLE_CASHIER_MOVEMENTS,
} from '../domain/model/enums';

export const printCashierOperation = (
  cash: number,
  userName: string,
  date: string,
  type: PRINTABLE_CASHIER_MOVEMENTS
) => {
  const newTab = window.open('', '_blank') as Window;
  newTab.document.open();
  newTab.document.write(
    genCashierOperationTemplate(cash, userName, date, type)
  );
  newTab.print();
  newTab.close();
};

const genCashierOperationTemplate = (
  cash: number,
  userName: string,
  date: string,
  type: PRINTABLE_CASHIER_MOVEMENTS
) => {
  const formattedCash = Intl.NumberFormat('es-BR').format(cash);

  const operationTitles = {
    [CASHIER_MOVEMENT.OPEN]: 'Dinheiro Inicial',
    [CASHIER_MOVEMENT.DEPOSIT]: 'Dinheiro Depositado',
    [CASHIER_MOVEMENT.WITHDRAWAL]: 'Dinheiro Retirado',
  };

  const cashierAvailableTitles = {
    [CASHIER_MOVEMENT.OPEN]: 'ABERTURA',
    [CASHIER_MOVEMENT.DEPOSIT]: 'DEPOSITO',
    [CASHIER_MOVEMENT.WITHDRAWAL]: 'RETIRADA CAIXA',
  };

  const cashierOperation = operationTitles[type] ?? '';
  const cashierTitle = cashierAvailableTitles[type];

  return `
<html lang="en">

<head>
  <meta charset="utf-8">
  <style>
    @media print {   @page { margin: 0; }   body { margin: 1.6cm; } }
    body {
      width: 50 mm;
      font-family: "Arial";
      font-weight: bold;
      font-size: 9pt;
    }
    .label {
      width: 45mm; /* plus .6 inches from padding */
      padding: 5mm;
      padding-top: 0;
      margin-right: .125in; /* the gutter */
      margin-top: 0;
      float: left;
      overflow: hidden;
      outline: 0px dotted; /* outline doesn't occupy space like border does */
    }
    .page-break  {
      clear: left;
      display:block;
      page-break-after:always;
    }
    .table {
      width: 100%;
      font-size: 9pt;
      display: block;
    }
    .center{
      text-align: center;
    }
    .left{
      text-align: left;
    }
    .right{
      text-align: right;
    }
    .footer {
      text-align: center;
    }
    tbody {
      width: 100%;
      display: table;
    }
  </style>
</head>

<body>
  <div class="label center">
    <div class="center">OMO LAVANDERIA</div>
    **********************************
    <div class="center">${cashierTitle} CAIXA</div>
    **********************************
    <div class="center">${date}</div>
    <br> ----------------------------------
    <br>
    <br>
    <div>
      <table class="table">
        <tbody>
         <tr>
           <td class="left">${cashierOperation}</td>
           <td class="right">R$${formattedCash}</td>
         </tr>
       </tbody>
      </table>
    </div>
    <br> ----------------------------------
    <div class="footer">
      Operador ${userName}
      <br> ----------------------------------
    </div>
  </div>
  <div class="page-break"></div>
</body>

</html>
`;
};
