import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

import { User } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  private users$ = new BehaviorSubject<User[]>([]);

  getUsers(filter: string) {
    if (filter === '') return this.users$.asObservable();
    const lowercaseFilter = filter.toLowerCase();

    return this.users$.asObservable().pipe(
      map((users: User[]) => {
        return users.filter((user: User) => {
          const inName = user.name.toLowerCase().includes(lowercaseFilter);
          const inEmail = user.email.toLowerCase().includes(lowercaseFilter);
          const inId = user.id.toString().includes(lowercaseFilter);
          const inPin = user.pin.toString().includes(lowercaseFilter);

          return inName || inEmail || inId || inPin;
        });
      })
    );
  }

  set users(users: User[]) {
    this.users$.next([...users]);
  }

  addUser(user: User) {
    this.users$.next([...this.users$.value, user]);
  }

  updateUser(user: User) {
    const newArray = [...this.users$.value];
    const index = newArray.findIndex((el) => el.id === user.id);
    newArray[index] = user;
    this.users$.next(newArray);
  }
}
