import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { Customer, ItemInfo } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class CustomerStore {
  private customers$: BehaviorSubject<Customer[]> = new BehaviorSubject(
    [] as Customer[]
  );

  private totalCustomers$: BehaviorSubject<number> = new BehaviorSubject(0);

  private customer$: BehaviorSubject<Customer | null> =
    new BehaviorSubject<Customer | null>(null);

  getCustomers() {
    return this.customers$.asObservable();
  }

  set customers(customers: Customer[]) {
    this.customers$.next([...customers]);
  }

  getTotalCustomers() {
    return this.totalCustomers$.asObservable();
  }

  set totalCustomers(total: number) {
    this.totalCustomers$.next(total);
  }

  getCustomer() {
    return this.customer$.asObservable();
  }

  getCustomerValue(): Customer | null {
    return this.customer$.getValue();
  }

  set customer(customer: Customer) {
    this.customer$.next({ ...customer });
  }

  // TODO Method not currently in use
  getCustomerById(id: number) {
    return this.customers$.value.find((customer: Customer) => {
      return customer.id == id;
    });
  }

  // TODO This method may not be necessary
  customerExistsByCPF(cpf: string): boolean {
    return !!this.customers$.value.find(
      (customer: Customer) => customer.CPF == cpf
    );
  }

  getPackageItemsMapper<T extends ItemInfo>(): Observable<T[]> {
    return this.customer$.pipe(
      map((customer) => {
        if (customer) {
          if (!customer.packages) return [] as T[];
          return customer.packages.map((p) => p as unknown as T);
        }
        return [];
      })
    );
    // return this.customer$.pipe(
    //   map((customer) => {
    //     if (customer) {
    //       if (!customer.packages) return [] as T[];
    //       return customer.packages.map((p) => p as unknown as T);
    //     }
    //   })
    // );
  }

  resetCustomers() {
    this.customers$.next([]);
    this.totalCustomers$.next(0);
  }

  resetCustomer() {
    this.customer$.next(null);
  }
}
