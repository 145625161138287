import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';

import { PackageStore } from '@application/stores';
import { PackageService } from '@infrastructure/api';
import {
  AssociatedStoresResponse,
  PackageResponse,
  PackagesInStoreResponse,
  PackagesResponse,
} from '@infrastructure/dtos';
import { Package, StoreToBeAssociated } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PackageFacade {
  constructor(
    private readonly packageService: PackageService,
    private readonly packageStore: PackageStore,
    private readonly router: Router,
    private toastrService: ToastrService
  ) {}

  fetchPackages(page: number, size: number, filter: string): void {
    this.packageService
      .fetchPackages(page, size, filter)
      .pipe(take(1))
      .subscribe((res: PackagesResponse) => {
        this.packageStore.packages = res.data;
        this.packageStore.totalPackages = res.total;
      });
  }

  fetchPackage(id: number): void {
    this.packageService
      .fetchPackage(id)
      .pipe(take(1))
      .subscribe((res: PackageResponse) => {
        this.packageStore.selectedPackage = res.data;
      });
  }

  fetchPackageForDuplicate(id: number): void {
    this.packageService
      .fetchPackage(id)
      .pipe(take(1))
      .subscribe((res: PackageResponse) => {
        const packageWithoutId = { ...res.data };
        packageWithoutId.id = undefined;
        this.packageStore.selectedPackage = packageWithoutId;
      });
  }

  fetchAssociatedStores(id: number): void {
    this.packageService
      .fetchAssociatedStores(id)
      .pipe(take(1))
      .subscribe((res: AssociatedStoresResponse) => {
        this.packageStore.associatedStores = res.data;
      });
  }

  fetchPackagesInStore(): void {
    this.packageService
      .fetchPackagesInStore()
      .pipe(take(1))
      .subscribe((res: PackagesInStoreResponse) => {
        this.packageStore.packages = res.data.map((packageOrder) => ({
          ...packageOrder.package,
          id: packageOrder.connectionPackageId,
          price: packageOrder.storePrice,
        }));
      });
  }

  createPackage(newPackage: Package): void {
    this.packageService
      .createPackage(newPackage)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.toastrService.success('Pacote criado com sucesso');
          this.router.navigateByUrl('/admin/packages');
        },
        error: (err) => {
          console.error(err?.error.error.message);
          this.toastrService.error(
            'Duplicidade de nome, por favor mudar o nome da pacote'
          );
        },
      });
  }

  updatePackage(id: number, packageToBeUpdated: Package): void {
    this.packageService
      .updatePackage(id, packageToBeUpdated)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigateByUrl('/admin/packages');
      });
  }

  associateStores(id: number, associatedStores: StoreToBeAssociated[]): void {
    this.packageService
      .associateStores(id, associatedStores)
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success('Lojas associadas com sucesso');
        this.fetchAssociatedStores(id);
        this.fetchAvailableStores(id);
      });
  }

  disassociateStore(packageId: number, connectionId: number): void {
    this.packageService
      .disassociateStore(packageId, connectionId)
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success('Loja dissociada com sucesso');
        this.packageStore.switchAssociatedToAvailableStore(connectionId);
      });
  }

  fetchAvailableStores(packageId: number) {
    this.packageService
      .fetchAvailableStores(packageId)
      .pipe(take(1))
      .subscribe((res) => {
        this.packageStore.setAvailableStores(res.data);
      });
  }

  clearSelectedPackage() {
    this.packageStore.selectedPackage = null;
  }
  clearAssociatedStores() {
    this.packageStore.resetAssociatedStores();
  }
}
