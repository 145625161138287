import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { AttendanceResponse, AttendancesResponse } from '@infrastructure/dtos';
import { ATTENDANCE } from '@model/enums';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  private URL = `${environment.apiUsersUrl}/attendances`;

  constructor(private readonly http: HttpClient) {}

  getAttendances(
    date: string,
    connectionId: number
  ): Observable<AttendancesResponse> {
    const params = new HttpParams()
      .append('date', date)
      .append('connectionId', connectionId);

    return this.http.get<AttendancesResponse>(`${this.URL}`, {
      params,
    });
  }

  registerEntry(
    type: ATTENDANCE,
    userId: number
  ): Observable<AttendanceResponse> {
    return this.http.post<AttendanceResponse>(`${this.URL}/register`, {
      type,
      userId,
    });
  }

  registerEntryForManager(
    entryDate: string,
    exitDate: string,
    userId: number,
    connectionId: number
  ): Observable<AttendanceResponse> {
    return this.http.post<AttendanceResponse>(`${this.URL}`, {
      entryDate,
      exitDate,
      userId,
      connectionId,
    });
  }

  updateAttendance(
    attendanceId: number,
    entryDate: string,
    exitDate: string
  ): Observable<AttendanceResponse> {
    return this.http.put<AttendanceResponse>(`${this.URL}/${attendanceId}`, {
      entryDate,
      exitDate,
    });
  }
}
