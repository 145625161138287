import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { PositionsResponse, PromotionResponse } from '@infrastructure/dtos';
import { Promotion } from '@model/interfaces/promotion/promotion';

@Injectable({
  providedIn: 'root',
})
export class PositionsService {
  private URL = `${environment.apiOrderUrl}`;

  constructor(private readonly _http: HttpClient) {}

  fetchPositions(): Observable<PositionsResponse> {
    return this._http.get<PositionsResponse>(`${this.URL}/positions`);
  }

  createPromotion(promotion: Promotion): Observable<PromotionResponse> {
    return this._http.post<PromotionResponse>(`${this.URL}/promotions`, { promotion });
  }
}
