import { Injectable } from '@angular/core';
import { HubsterProcessingStatus } from '@slabcode/hubster-models/enums/hubster';
import { BehaviorSubject, Observable } from 'rxjs';

import { CustomerPayment } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PaymentStore {
  private payment$: BehaviorSubject<CustomerPayment> =
    new BehaviorSubject<CustomerPayment>({
      name: '',
      value: 0,
      processingStatus: HubsterProcessingStatus.COLLECTABLE,
      paymentMethodId: 0,
    });

  set payment(payment: CustomerPayment) {
    this.payment$.next({ ...payment });
  }

  getPayment(): Observable<CustomerPayment> {
    return this.payment$.asObservable();
  }

  reset() {
    this.payment$.next({
      name: '',
      value: 0,
      processingStatus: HubsterProcessingStatus.COLLECTABLE,
      paymentMethodId: 0,
    });
  }
}
