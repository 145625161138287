import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PAYMENT_MODE } from '@model/enums';
import { PaymentMethod } from '@model/interfaces';
import { HubsterProcessingStatus } from '@slabcode/hubster-models/enums/hubster';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodStore {
  private paymentMode$: BehaviorSubject<PAYMENT_MODE> =
    new BehaviorSubject<PAYMENT_MODE>(PAYMENT_MODE.SHOP);

  private storePaymentMethods$: BehaviorSubject<PaymentMethod[]> =
    new BehaviorSubject<PaymentMethod[]>([] as PaymentMethod[]);

  private paymentMethodId$: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1);

  private paymentMethods$: BehaviorSubject<PaymentMethod[]> =
    new BehaviorSubject<PaymentMethod[]>([]);

  private paymentStatus$: BehaviorSubject<HubsterProcessingStatus> =
    new BehaviorSubject<HubsterProcessingStatus>(
      HubsterProcessingStatus.PROCESSED
    );

  set paymentMode(payment: PAYMENT_MODE) {
    this.paymentMode$.next(payment);
  }

  getPaymentMode(): PAYMENT_MODE {
    return this.paymentMode$.getValue();
  }

  set storePaymentMethods(payment: PaymentMethod[]) {
    this.storePaymentMethods$.next([...payment]);
    // if (this.paymentMethodId$.value == -1)
    //   this.paymentMethodId = parseInt(localStorage.getItem('store') ?? '0');
  }

  getStorePaymentMethods() {
    return this.storePaymentMethods$.asObservable();
  }

  getStorePaymentMethodsSnapshot() {
    return this.storePaymentMethods$.getValue();
  }

  getPaymentMethodId(): Observable<number> {
    return this.paymentMethodId$.asObservable();
  }

  getPaymentMethodIdValue(): number {
    return this.paymentMethodId$.getValue();
  }

  set paymentMethodId(id: number) {
    this.paymentMethodId$.next(id);
  }

  getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.paymentMethods$.asObservable();
  }

  set paymentMethods(methods: PaymentMethod[]) {
    this.paymentMethods$.next(methods);
  }

  addPaymentMethod(method: PaymentMethod) {
    this.paymentMethods$.next([...this.paymentMethods$.value, method]);
  }

  updatePaymentMethod(method: PaymentMethod) {
    const newArray = [...this.paymentMethods$.value];
    const index = newArray.findIndex((el) => el.id === method.id);
    newArray[index] = method;
    this.paymentMethods$.next(newArray);
  }

  getPaymentPackageValue(): number {
    return (
      this.storePaymentMethods$
        .getValue()
        .find((payment) => payment.name == 'PACOTE')?.id || -1
    );
  }

  set paymentStatus(status: HubsterProcessingStatus) {
    this.paymentStatus$.next(status);
  }

  getPaymentStatus() {
    return this.paymentStatus$.asObservable();
  }

  reset() {
    this.paymentMode = PAYMENT_MODE.SHOP;
    this.storePaymentMethods = [] as PaymentMethod[];
    this.paymentMethodId = -1;
    this.paymentMethods = [];
    this.paymentStatus = HubsterProcessingStatus.PROCESSED;
  }
}
