import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { InputComponent } from './components/input/input.component';
import { ItemCountComponent } from './components/item-count/item-count.component';
import { ItemGalleryComponent } from './components/item-gallery/item-gallery.component';
import { ItemListComponent } from './components/item-list/item-list.component';
import { LoadingComponent } from './components/loading/loading.component';
import { OrderDiscountComponent } from './components/order-discount/order-discount.component';
import { PackageInfoComponent } from './components/package-info/package-info.component';
import { PositionComponent } from './components/position/position.component';
import { SidebarOptionComponent } from './components/sidebar-option/sidebar-option.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { MetricPipe } from './pipes/metric.pipe';
import { PaginationPipe } from './pipes/pagination.pipe';
import { SortSidebarPipe } from './pipes/sort-sidebar.pipe';
import { StatusJobsPipe } from './pipes/status-jobs.pipe';

@NgModule({
  declarations: [
    AddressInputComponent,
    ItemCountComponent,
    ItemGalleryComponent,
    ItemListComponent,
    LoadingComponent,
    OrderDiscountComponent,
    PackageInfoComponent,
    PaginationPipe,
    PositionComponent,
    SidebarOptionComponent,
    MetricPipe,
    SortSidebarPipe,
    StatusJobsPipe,
  ],
  imports: [
    CommonModule,
    InputComponent,
    FormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatIconModule,
    MatPaginatorModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    RouterModule,
    TabsComponent,
  ],
  providers: [provideNgxMask()],
  exports: [
    AddressInputComponent,
    ItemCountComponent,
    ItemGalleryComponent,
    ItemListComponent,
    LoadingComponent,
    MatCheckboxModule,
    OrderDiscountComponent,
    PackageInfoComponent,
    PaginationPipe,
    PositionComponent,
    MetricPipe,
    StatusJobsPipe,
  ],
})
export class SharedModule {}
