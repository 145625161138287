import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from '@env/environment';

import {
  AssociatePromotions,
  CustomersResponse,
  EmptyResponse,
  EntitiesResponse,
  PromotionRequest,
  PromotionResponse,
  PromotionsResponse,
} from '@infrastructure/dtos';
import { Promotion } from '@model/interfaces/promotion/promotion';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  private readonly http = inject(HttpClient);
  private URL = `${environment.apiMenuUrl}/promotions`;

  fetchPromotions(filters: PromotionRequest) {
    let params = new HttpParams()
      .set('page', filters.page)
      .set('size', filters.size);

    if (filters.connectionId)
      params = params.set('connectionId', filters.connectionId);
    if (filters.name !== '') params = params.set('name', filters.name);
    if (filters.isActive !== '')
      params = params.set('isActive', filters.isActive);
    if (filters.isConvenio !== '')
      params = params.set('isConvenio', filters.isConvenio);

    return this.http.get<PromotionsResponse>(this.URL, { params });
  }

  createPromotion(promotion: Promotion): Observable<PromotionResponse> {
    return this.http.post<PromotionResponse>(this.URL, promotion);
  }

  editPromotion(promotion: Promotion): Observable<PromotionResponse> {
    return this.http.put<PromotionResponse>(this.URL, promotion);
  }

  associateCustomersToPromotion(
    promotionId: number,
    customers: number[]
  ): Observable<EmptyResponse> {
    return this.http.put<EmptyResponse>(
      `${this.URL}/${promotionId}/customers`,
      { customers }
    );
  }

  associatePromotionsToCustomer(customerId: number, body: AssociatePromotions) {
    return this.http.put<{ data: void; success: boolean }>(
      `${this.URL}/customer/${customerId}`,
      body
    );
  }

  fetchStoresAvailable(id: number): Observable<EntitiesResponse> {
    return this.http.get<EntitiesResponse>(
      `${this.URL}/${id}/stores/available`
    );
  }

  fetchAvailableCustomers(promotionId: number): Observable<CustomersResponse> {
    return this.http.get<CustomersResponse>(
      `${this.URL}/${promotionId}/customers/available`
    );
  }

  fetchAssociatedCustomers(promotionId: number): Observable<CustomersResponse> {
    return this.http.get<CustomersResponse>(
      `${this.URL}/${promotionId}/customers`
    );
  }

  disassociatePromotionFromCustomer(
    customerId: number,
    promotionId: number
  ): Observable<{ data: void; success: boolean }> {
    return this.http.delete<{ data: void; success: boolean }>(
      `${this.URL}/${promotionId}/customer/${customerId}`
    );
  }

  associateStoresToPromotion(
    promotionId: number,
    stores: number[]
  ): Observable<EmptyResponse> {
    return this.http.put<EmptyResponse>(`${this.URL}/${promotionId}/stores`, {
      stores,
    });
  }

  fetchAssociatedStores(id: number) {
    return this.http.get<EntitiesResponse>(`${this.URL}/${id}/stores`);
  }

  disassociatePromotionFromStore(connectionId: number, promotionId: number) {
    return this.http.delete(`${this.URL}/${promotionId}/store/${connectionId}`);
  }

  fetchPromotionsByCustomer(customerId: number) {
    const params = new HttpParams()
      .set('page', 1)
      .set('size', 10)
      .set('isActive', true);
    return this.http.get<PromotionsResponse>(
      `${this.URL}/customer/${customerId}`,
      { params }
    );
  }

  fetchPromotionToCustomer(customerId?: number, paymentLater?: boolean) {
    let params = new HttpParams();
    if (paymentLater) params = params.append('payLater', true);
    if (customerId) params = params.append('customerId', customerId);
    return this.http.get<PromotionsResponse>(`${this.URL}/available-to-use`, {
      params,
    });
  }
}
