import { Injectable } from '@angular/core';
import {
  HubsterFulfillmentMode,
  HubsterPaymentMethod,
  HubsterProcessingStatus,
} from '@slabcode/hubster-models/enums/hubster';
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import { BehaviorSubject, Observable, map } from 'rxjs';

import {
  Cart,
  Customer,
  CustomerPayment,
  FulfillmentInfo,
  OrderDiscount,
  OrderItem,
} from '@model/interfaces';

@Injectable({ providedIn: 'root' })
export class CartService {
  private initialState: Cart = {
    orderId: this.setOrderId(),
    positionId: -1,
    customerId: 0,
    customer: null,
    userId: 0,
    note: '',
    discount: {
      type: 'amount',
      value: 0,
    },
    tip: 0,
    items: [],
    total: {
      subtotal: 0,
      total: 0,
    },
    currency: 'US',
    customerPayments: {
      name: 'DINHEIRO',
      value: 0,
      processingStatus: HubsterProcessingStatus.COLLECTABLE,
      paymentMethodId: 0,
    },
    fulfillmentInfo: {
      pickupTime: null,
      deliveryTime: null,
      fulfillmentMode: HubsterFulfillmentMode.DELIVERY,
      schedulingType: 'ASAP',
      courierStatus: null,
    },
  };

  private cart$ = new BehaviorSubject<Cart>(this.initialState);

  get cart() {
    return this.cart$.value;
  }

  private setCart(cart: Cart): void {
    const { total, currency } = this.calcCartTotal(cart);

    if (!currency) {
      this.cart$.next({ ...cart, total });
      return;
    }

    this.cart$.next({ ...cart, total, currency });
  }

  private calcCartTotal(cart: Cart) {
    const hubsterOrderTotal: HubsterOrderTotal = {
      subtotal: 0,
      total: 0,
    };
    // Sum each total cart's item
    const result = cart.items.reduce(
      (acc, current) => (acc += current.total),
      0
    );

    hubsterOrderTotal.subtotal = result;
    hubsterOrderTotal.total = result;

    if (cart.tip) {
      hubsterOrderTotal.total += cart.tip;
      hubsterOrderTotal.tip = cart.tip;
    }

    if (cart.discount.type === 'amount') {
      hubsterOrderTotal.discount = cart.discount.value;
      hubsterOrderTotal.total -= cart.discount.value;
    }

    if (cart.discount.type === 'percentage') {
      const discountPrice = (result * cart.discount.value) / 100;
      hubsterOrderTotal.discount = discountPrice;
      hubsterOrderTotal.total -= discountPrice;
    }

    return {
      total: hubsterOrderTotal,
      currency: cart.items[0]?.currency,
    };
  }

  getCart(): Observable<Cart> {
    return this.cart$.asObservable();
  }

  addItemToCart(newOrderItem: OrderItem) {
    this.setCart({
      ...this.cart,
      items: [...this.cart.items, newOrderItem],
    });
  }

  removeItemToCart(orderItem: OrderItem) {
    this.setCart({
      ...this.cart,
      items: this.cart.items.filter((order) => order.id !== orderItem.id),
    });
  }

  updateCartItem(itemId: string, updatedItem: OrderItem) {
    this.setCart({
      ...this.cart,
      items: this.cart.items.map((item) =>
        item.id === itemId ? updatedItem : item
      ),
    });
  }

  hasCartItems(): Observable<boolean> {
    return this.getCart().pipe(map((cart: Cart) => cart.items.length > 0));
  }

  clearCart() {
    this.setCart({ ...this.cart, items: [] });
  }

  resetCart() {
    this.setCart({ ...this.initialState });
  }

  addExtra(extra: {
    tip?: number;
    discount?: OrderDiscount;
    orderId?: number;
    userId?: number;
    customer?: Customer;
    customerPayments?: CustomerPayment;
    fulfillmentInfo?: FulfillmentInfo;
  }) {
    const cloneExtra = structuredClone(extra);
    this.setCart({ ...this.cart, ...cloneExtra });
  }

  setOrderId() {
    const uDate = new Date();
    let day: number | string = uDate.getDate();
    if (day < 10) day = `0${day}`;
    let month: number | string = uDate.getMonth() + 1;
    if (month < 10) month = `0${month}`;
    const rnd = Math.floor(Math.random() * 9000);
    const orderId = `${day}${month}${uDate.getFullYear()}-${rnd}`;

    return orderId;
  }

  newOrderId(order?: string) {
    const orderId = !order
      ? this.cart.orderId.toString().split('-')
      : order.toString().split('-');
    orderId[1] = (Number(orderId[1]) + 1).toString();
    this.setCart({ ...this.cart, orderId: `${orderId[0]}-${orderId[1]}` });
  }

  setPosition(positionId: number) {
    const clone = structuredClone(this.cart$.value);
    this.cart$.next({ ...clone, positionId });
  }

  getPosition() {
    return this.cart.positionId;
  }

  getDate() {
    const ffInfo = this.cart.fulfillmentInfo;
    return ffInfo.fulfillmentMode == HubsterFulfillmentMode.DELIVERY
      ? ffInfo.deliveryTime
      : ffInfo.pickupTime;
  }

  setCustomer(customer: Customer) {
    const clone = structuredClone(this.cart$.value);
    if (customer.id)
      this.cart$.next({
        ...clone,
        customerId: customer.id,
      });
  }

  getCustomer(): Observable<Customer | null> {
    return this.cart$.pipe(map((cart) => cart.customer));
  }
}
