import { ModuleWithProviders, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { SERVICES_URL } from '../../common/types/services-url.token';
import { IHubsterFacade } from './interfaces/hubster-facade.interface';
import { IHubsterRepository } from './interfaces/hubster-repository.interface';
import { IHubsterStore } from './interfaces/hubster-store.interface';
import { HubsterFacade } from './services/hubster-facade.service';
import { HubsterRepository } from './services/hubster-repository.service';
import { HubsterStore } from './services/hubster-store.service';
import { HubsterModuleConfig } from './types/hubster-config.type';

@NgModule({
  providers: [
    {
      provide: SERVICES_URL,
      useValue: environment.apiMenuUrl,
    },
    {
      provide: IHubsterFacade,
      useClass: HubsterFacade,
    },
    {
      provide: IHubsterRepository,
      useClass: HubsterRepository,
    },
    {
      provide: IHubsterStore,
      useClass: HubsterStore,
    },
  ],
})
export class HubsterModule {
  static forRoot(
    config?: HubsterModuleConfig
  ): ModuleWithProviders<HubsterModule> {
    return {
      ngModule: HubsterModule,
      providers: [
        {
          provide: IHubsterFacade,
          useClass: config?.facadeHandler ?? HubsterFacade,
        },
        {
          provide: IHubsterRepository,
          useClass: config?.repositoryHandler ?? HubsterRepository,
        },
        {
          provide: IHubsterStore,
          useClass: config?.storeHandler ?? HubsterStore,
        },
      ],
    };
  }
}
