import { Observable } from 'rxjs';
import { StockSetting } from '../models/stock-setting.model';
import { StockMap } from '../types/stock-map.type';

export abstract class IStockFacade {
  abstract initDataForItems(): void;

  abstract fetchStockSettings(connectionId: number): void;
  abstract getStockSettings(): Observable<StockSetting[] | null>;

  abstract getStockItemsMap(): Observable<StockMap>;
}
