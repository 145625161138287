import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metric',
})
export class MetricPipe implements PipeTransform {
  transform(value: unknown, metric: string): unknown {
    return `${value} ${metric}`;
  }
}
