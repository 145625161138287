import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import {
  PaymentMethodResponse,
  PaymentMethodsResponse,
} from '@infrastructure/dtos';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  private URL = `${environment.apiUsersUrl}`;

  constructor(private readonly http: HttpClient) {}

  getAllPaymentMethods() {
    return this.http.get<PaymentMethodsResponse>(`${this.URL}/payment-methods`);
  }

  getPaymentMethod(paymentMethodId: number) {
    return this.http.get<PaymentMethodResponse>(
      `${this.URL}/payment-methods/${paymentMethodId}`
    );
  }

  createPaymentMethod(name: string) {
    return this.http.post<PaymentMethodResponse>(
      `${this.URL}/payment-methods`,
      { name }
    );
  }

  updatePaymentMethod(id: number, name: string) {
    return this.http.put<PaymentMethodResponse>(`${this.URL}/payment-methods`, {
      id,
      name,
    });
  }

  associatePaymentMethodsToStore(storeId: number, paymentMethodIds: number[]) {
    return this.http.put<PaymentMethodResponse>(
      `${this.URL}/stores/${storeId}/payment-methods/`,
      { paymentMethodIds }
    );
  }

  getPaymentMethodsByStore(storeId: number) {
    return this.http.get<PaymentMethodsResponse>(
      `${this.URL}/stores/${storeId}/payment-methods`
    );
  }
}
