import { Component, Input } from '@angular/core';
import { of } from 'rxjs';

import { AuthStore } from '@application/stores';
import { ROLE } from '@model/enums';
import { SidebarText } from 'app/modules/sidebar/enums/sidebar-text.enum';
import { SidebarOption } from 'app/modules/sidebar/models/sidebar-option.interface';

@Component({
  selector: 'app-sidebar-option',
  templateUrl: './sidebar-option.component.html',
  styleUrls: ['./sidebar-option.component.scss'],
})
export class SidebarOptionComponent {
  @Input() options: SidebarOption[] | null = [];

  sidebarText = SidebarText;

  constructor(private readonly authStore: AuthStore) {}

  userRoles: ROLE[] = this.authStore.getRoles();
  editPath = '';

  validateRoles(option: SidebarOption): boolean {
    const roleMatches = option.roles!.some((r: ROLE) =>
      this.userRoles.includes(r)
    );

    return roleMatches;
  }

  action = (option: SidebarOption) => {
    option.expanded = !option.expanded;
  };

  canNavigate = (option: SidebarOption) => {
    return option.path && option.path != '/shop' ? of(option.path) : of(null);
  };
}
