import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import {
  StockItemsResponse,
  StockSettingResponse,
  StockSettingsResponse,
} from '@infrastructure/dtos';
import { CreateStockItem } from '@model/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StockService {
  private URL = environment.apiMenuUrl;

  constructor(private readonly http: HttpClient) {}

  getStockSettings(connectionId: number): Observable<StockSettingsResponse> {
    const params = new HttpParams().append('connectionId', connectionId);

    return this.http.get<StockSettingsResponse>(`${this.URL}/stock-settings`, {
      params,
    });
  }

  createStockSettings(
    menuName: string,
    stockEnabled: boolean,
    connectionId: number
  ): Observable<StockSettingResponse> {
    return this.http.post<StockSettingResponse>(`${this.URL}/stock-settings`, {
      menuName,
      stockEnabled,
      connectionId,
    });
  }

  createStockItems(
    stockSettingId: number,
    items: CreateStockItem[]
  ): Observable<StockItemsResponse> {
    return this.http.post<StockItemsResponse>(`${this.URL}/stock-items`, {
      stockSettingId,
      items,
    });
  }

  getStockItemsByStockSetting(
    stockSettingId: number
  ): Observable<StockItemsResponse> {
    return this.http.get<StockItemsResponse>(
      `${this.URL}/stock-settings/${stockSettingId}/stock-items`
    );
  }

  fetchStockItems() {
    return this.http.get<StockItemsResponse>(`${this.URL}/store/stock-items`);
  }
}
