import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import {
  passwordForCreateRegexString,
  passwordForEditRegexString,
} from 'app/constants/regex.constants';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgxMaskDirective, NgxMaskPipe],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() type = 'text';
  @Input() label = '';
  @Input() autocomplete = true;
  @Input() mask = '';
  @Input() abstractControl!: AbstractControl;
  @Input() placeholder = '';
  @Input() maxlength: number | null = null;
  @Input() minlength: number | null = null;
  control!: FormControl;

  @Output() blurEvent = new EventEmitter();

  ngOnInit() {
    this.control = this.abstractControl as FormControl;
  }

  onBlur() {
    this.blurEvent.emit();
  }

  getErrorMsg(errors: ValidationErrors) {
    if (errors['required']) return `Campo obrigatório`;
    if (errors['min']) {
      return `O valor deve ser maior que ${errors['min']['min']}`;
    }
    if (errors['max'])
      return `O valor deve ser menor que ${errors['max']['max']}`;
    if (errors['pattern']) {
      if (errors['pattern']['requiredPattern'] === passwordForCreateRegexString)
        return 'Deve conter números, maiúsculas, minúsculas e símbolos';
      if (errors['pattern']['requiredPattern'] === passwordForEditRegexString)
        return 'Mínimo de 8 números, maiúsculas, minúsculas e símbolos';
      return `Format: ${errors['pattern']['requiredPattern']}`;
    }
    if (errors['mask']) return `Formato: ${errors['mask']['requiredMask']}`;
    if (errors['email']) return `Forneça um email válido`;
    if (errors['maxlength'])
      return `O valor deve ser menor que ${errors['maxlength']['requiredLength']}`;
    if (errors['minlength'])
      return `Valor deve ser maior que ${errors['minlength']['requiredLength']}`;
    return;
  }
}
