import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVICES_URL } from '../../../common/types/services-url.token';
import { IHubsterRepository } from '../interfaces/hubster-repository.interface';
import { FetchMenuResponse } from '../types/fetch-menu-response.type';

@Injectable()
export class HubsterRepository implements IHubsterRepository {
  constructor(
    private readonly http: HttpClient,
    @Inject(SERVICES_URL) private readonly servicesUrl: string
  ) {}

  fetchPublishedMenu(): Observable<FetchMenuResponse> {
    return this.http.get<FetchMenuResponse>(`${this.servicesUrl}/menu`);
  }

  fetchAdminMenu(): Observable<FetchMenuResponse> {
    return this.http.get<FetchMenuResponse>(
      `${this.servicesUrl}/menu/packages`
    );
  }
}
