import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { Order, OrderDetail } from '@model/interfaces';
import { Jobs } from '@model/interfaces/order/jobs';

@Injectable({
  providedIn: 'root',
})
export class OrderStore {
  private orders$: BehaviorSubject<Order[]> = new BehaviorSubject(
    [] as Order[]
  );

  private totalOrders$: BehaviorSubject<number> = new BehaviorSubject(0);

  private orderDetail$: BehaviorSubject<OrderDetail | null> =
    new BehaviorSubject<OrderDetail | null>(null);

  private jobs$: BehaviorSubject<Jobs[]> = new BehaviorSubject([] as Jobs[]);
  private totalJobs$: BehaviorSubject<number> = new BehaviorSubject(0);

  reset() {
    this.orders$.next([]);
    this.totalOrders$.next(0);
    this.orderDetail$.next(null);
  }

  getOrders(filter: string): Observable<Order[]> {
    if (filter === '') return this.orders$.asObservable();
    const lowercaseFilter = filter.toLowerCase();

    return this.orders$.asObservable().pipe(
      map((orders) =>
        orders.filter((order) => {
          const inCustomerName = order.customerName
            .toLowerCase()
            .includes(lowercaseFilter);
          const inUserName = order.userName
            .toLowerCase()
            .includes(lowercaseFilter);
          const inPosition = order.position.number
            .toString()
            .includes(lowercaseFilter);
          return inCustomerName || inUserName || inPosition;
        })
      )
    );
  }

  set orders(orders: Order[]) {
    this.orders$.next([...orders]);
  }

  getTotalOrders() {
    return this.totalOrders$.asObservable();
  }

  set totalOrders(total: number) {
    this.totalOrders$.next(total);
  }

  getOrderDetail(): Observable<OrderDetail | null> {
    return this.orderDetail$.asObservable();
  }

  set orderDetail(order: OrderDetail) {
    this.orderDetail$.next({ ...order });
  }

  clearOrderDetail() {
    this.orderDetail$.next(null);
  }

  set jobs(jobs: Jobs[]) {
    this.jobs$.next([...jobs]);
  }

  getJobs() {
    return this.jobs$.asObservable();
  }

  set totalJobs(total: number) {
    this.totalJobs$.next(total);
  }

  getTotalJobs(): Observable<number> {
    return this.totalJobs$.asObservable();
  }
}
