import { inject, Injectable } from '@angular/core';
import { catchError, of, shareReplay, take } from 'rxjs';

import { StoreStore } from '@application/stores';
import { StoreService } from '@infrastructure/api';
import {
  AddExtraCancellations,
  StoreFilter,
  StoresResponse,
} from '@infrastructure/dtos';
import { StoreSettingsResponse } from '@infrastructure/dtos/store/store-settings-response.dto';
import { StoreSettings } from '@model/interfaces/store/store-settings';
import { UTCToTimezoned } from 'app/utils/utc-to-timezoned.function';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class StoreFacade {
  // private authStore = inject(AuthStore);
  private readonly toastrService = inject(ToastrService);
  constructor(
    private storeService: StoreService,
    private storeStore: StoreStore
  ) {}

  getStoreSettingsSnapShot() {
    return this.storeStore.getCurrentSettings();
  }

  getStoreSettings() {
    return this.storeStore.getStoreSettings();
  }

  getManagerStores(filters?: StoreFilter): void {
    this.storeService
      .getManagerStores(filters)
      .pipe(shareReplay(1))
      .subscribe((res: StoresResponse) => {
        this.storeStore.stores = res.data;
        this.storeStore.storeTotal = res.total;
      });
  }

  getAdminStores(filters?: StoreFilter): void {
    this.storeService
      .getAdminStores(filters)
      .pipe(shareReplay(1))
      .subscribe((res: StoresResponse) => {
        this.storeStore.stores = res.data;
        this.storeStore.storeTotal = res.total;
      });
  }

  fetchStoreSettings() {
    const connectionId = parseInt(localStorage.getItem('store') ?? '0');
    this.storeService
      .fetchStoreSettings(connectionId)
      .pipe(
        take(1),
        catchError((err) => {
          this.toastrService.error(
            err.status === 404
              ? 'O gerente deve definir as configurações da loja'
              : err.error.error.message
          );
          return of({ data: {} } as StoreSettingsResponse);
        })
      )
      .subscribe((res: StoreSettingsResponse) => {
        this.storeStore.storeSettings = res.data;
      });
  }

  createStoreSettings(storeSettings: StoreSettings) {
    const connectionId = this.storeStore.getStoreSelected();
    if (!connectionId) {
      return;
    }

    const newStoreSettings = { ...storeSettings, connectionId };
    this.storeService
      .createStoreSettings(newStoreSettings)
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success('Parâmetros salvos com sucesso');
      });
  }

  today(): Date {
    const storeSettings = this.getStoreSettingsSnapShot();
    return DateTime.now().setZone(storeSettings.timezone.timezone).toJSDate();
  }

  fetchCancellations() {
    this.storeService
      .fetchCancellations()
      .pipe(
        take(1),
        catchError((err) => {
          this.toastrService.error(err.error.error.message);
          return of();
        })
      )
      .subscribe((res) => {
        this.storeStore.cancellations = res.data.map((c) => ({
          ...c,
          startDate: UTCToTimezoned(c.startDate, c.storeTimeZone.timezone),
          endDate: UTCToTimezoned(c.endDate, c.storeTimeZone.timezone),
          extrasExpirationDate: UTCToTimezoned(
            c.extrasExpirationDate,
            c.storeTimeZone.timezone
          ),
        }));
      });
  }

  addExtraCancellations(dto: AddExtraCancellations) {
    this.storeService
      .addExtraCancellations(dto)
      .pipe(
        take(1),
        catchError((err) => {
          this.toastrService.error(err.error.error.message);
          return of();
        })
      )
      .subscribe((res) => {
        this.storeStore.updateCancellation({
          ...res.data,
          startDate: UTCToTimezoned(
            res.data.startDate,
            res.data.storeTimeZone.timezone
          ),
          endDate: UTCToTimezoned(
            res.data.endDate,
            res.data.storeTimeZone.timezone
          ),
          extrasExpirationDate: UTCToTimezoned(
            res.data.extrasExpirationDate,
            res.data.storeTimeZone.timezone
          ),
        });
        this.toastrService.success('Cancelamento salvo com sucesso');
      });
  }

  clearStores() {
    this.storeStore.resetStores();
  }
}
