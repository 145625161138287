<div [class]="'flex w-full p-1 bg-gray-200 rounded' + extraClasses">
  <label
    *ngFor="let option of options"
    (click)="changeOption(option)"
    class="capitalize py-2 w-full rounded text-center cursor-pointer"
    [class.bg-white]="option.active"
  >
    {{ option.name }}
  </label>
</div>
