import { Injectable } from '@angular/core';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { HubsterMenuPublishData } from '@slabcode/hubster-models/hubster/payloads/menu/publish';
import {
  HubsterMenuCategoriesPublish,
  HubsterMenusPublish,
} from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/menu.types';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { ROLE } from '@model/enums';
import { Gallery, GalleryItem, GalleryMapper } from '@model/interfaces';
import { SidebarOption } from 'app/modules/sidebar/models/sidebar-option.interface';

@Injectable({
  providedIn: 'root',
})
export class MenuStore implements GalleryMapper {
  private _menu$ = new BehaviorSubject<HubsterMenuPublishData | null>(null);

  private menus$ = new BehaviorSubject<HubsterMenusPublish>(
    {} as HubsterMenusPublish
  );

  private categories$ = new BehaviorSubject<HubsterMenuCategoriesPublish>(
    {} as HubsterMenuCategoriesPublish
  );

  private sidebarOptions$ = new BehaviorSubject<SidebarOption[]>([]);
  private _navigate = new BehaviorSubject<string>('');
  private actualRoute$ = new BehaviorSubject<string>('');

  set menu(menuPublish: HubsterMenuPublishData) {
    this._menu$.next(menuPublish);
    this.menus$.next(menuPublish.menus);
    this.categories$.next(menuPublish.categories);
  }

  getMenu() {
    return this._menu$.asObservable();
  }

  getMenus2(): HubsterMenusPublish {
    return this.menus$.value;
  }

  getSidebarOptions(): Observable<SidebarOption[]> {
    return this.sidebarOptions$.asObservable();
  }

  set sidebarOptions(options: SidebarOption[]) {
    this.sidebarOptions$.next(options);
  }

  getMenuValue(): HubsterMenuPublishData {
    return this._menu$.value as HubsterMenuPublishData;
  }

  set navigate(path: string) {
    this._navigate.next(path);
  }

  getNavigate(): Observable<string> {
    return this._navigate.asObservable();
  }

  getGallery(categoryId: string): Observable<Gallery> {
    return this._menu$.pipe(
      map((menu) => {
        if (!menu || !menu.categories) return {} as Gallery;
        const { photos } = menu;
        const category: HubsterCategory = menu.categories[categoryId];
        const { itemIds } = category;
        const items = itemIds.map((id) => menu.items[id]);
        return { category, items, photos } as Gallery;
      })
    );
  }

  getGalleryItems(id: string): Observable<GalleryItem[]> {
    return this._menu$.pipe(
      map((menu) => {
        if (!menu || !menu.categories) return [] as GalleryItem[];
        const { photos } = menu;
        const category: HubsterCategory = menu.categories[id];
        const { itemIds } = category;
        return itemIds
          .map((item: string | number) => {
            const { id, name, photoIds, status } = menu.items[item];
            const images = photoIds.map((id) => photos[id].url);
            return {
              id: id as unknown as number,
              name,
              images,
              status: status.saleStatus,
            } as GalleryItem;
          })
          .filter((item) => item.status === 'FOR_SALE');
      })
    );
  }

  getCategory(categoryId: string): Observable<HubsterCategory | null> {
    return this._menu$.pipe(
      map((menu) =>
        menu ? (menu as HubsterMenuPublishData).categories[categoryId] : null
      )
    );
  }

  getCategoryByItemId = (id: string) => {
    const categories = this._menu$.getValue()?.categories;
    if (!categories) return;
    return Object.entries(categories).find(([_, value]) =>
      value.itemIds.includes(id)
    )?.[0];
  };

  getName(id: string): Observable<string> {
    return this._menu$.pipe(
      map((menu) => (menu as HubsterMenuPublishData).categories[id].name)
    );
  }

  // TODO Might not be needed
  getMenus(roles: ROLE[], path: string) {
    return this.getMenu().pipe(
      map((menu) => {
        if (!menu) {
          return [];
        }
        if (menu && !menu.categories) {
          return [];
        }

        const { menus, categories } = menu as HubsterMenuPublishData;
        this.categories$.next(categories);
        return this.formatedMenu(menus, roles, path);
      })
    );
  }

  getMenuName(categoryId: string) {
    return Object.values(this._menu$.getValue()!.menus).find((menu) =>
      menu.categoryIds.includes(categoryId)
    )!.name;
  }

  // TODO Might not be needed
  formatedMenu(menus: HubsterMenusPublish, roles: ROLE[], path: string) {
    return Object.values(menus).map((menu) => {
      const { categoryIds, name } = menu;
      return {
        expanded: false,
        hasOptions: true,
        name,
        options: this.filterCategories(categoryIds, path, roles, name),
        path: '',
        roles,
        sorted: true,
      } as SidebarOption;
    });
  }

  filterCategories(ids: string[], path: string, roles: ROLE[], parent: string) {
    const categories = this.categories$.value;
    return ids
      .filter(
        (key) =>
          categories[key].name != 'ITENS PACOTE' || !path.includes('shop')
      )
      .map((key) => {
        const { name, id } = categories[key];
        return {
          expanded: false,
          hasOptions: false,
          name,
          options: [],
          path: `${path}/${id}`,
          roles,
          parent,
          sorted: true,
        } as SidebarOption;
      });
  }

  // TODO Might not be needed
  setNestedOptions(options: SidebarOption[], path: string) {
    const sidebarOptions = this.sidebarOptions$.value;
    const newOptions = sidebarOptions.map((option) => {
      if (option.path === path) option.options = options;
      return option;
    });
    this.sidebarOptions$.next([...newOptions]);
  }

  getMenuItem(id: string) {
    const menu = this._menu$.value as HubsterMenuPublishData;
    return menu.items[id];
  }

  getPackageItemsGallery(itemIds: string[]) {
    const { items, photos } = this._menu$.value as HubsterMenuPublishData;
    return itemIds.map((item: string | number) => {
      if (!items[item]) return {} as GalleryItem;
      const { id, name, photoIds } = items[item];
      const images = photoIds.map((id) => photos[id].url);
      return {
        id: id as unknown as number,
        name,
        images,
      } as GalleryItem;
    });
  }
  getPackageItemsGalleryName(names: string[]) {
    const { items, photos, categories } = this._menu$
      .value as HubsterMenuPublishData;
    const categoryIds = Object.values(categories)
      .map((category) => category.itemIds)
      .flat();
    return Object.values(items)
      .filter(
        (item) => names.includes(item.name) && categoryIds.includes(item.id)
      )
      .map((item) => {
        const { id, name, photoIds, status } = item;
        const images = photoIds.map((id) => photos[id].url);
        return {
          id: id as unknown as number,
          name,
          images,
          status: status.saleStatus,
        } as GalleryItem;
      })
      .filter((item) => item.status === 'FOR_SALE');
  }

  set actualRoute(menuName: string) {
    this.actualRoute$.next(menuName);
  }

  getActualRoute(): Observable<string> {
    return this.actualRoute$.asObservable();
  }

  getActualRouteValue(): string {
    return this.actualRoute$.getValue();
  }
}
