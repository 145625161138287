import { DateTime } from 'luxon';

export function DateFormatFromTimezone(timezone: string, date: Date) {
  const timezonedDate = DateTime.fromJSDate(date).setZone(timezone).toObject();
  return DateTime.fromObject({
    year: timezonedDate.year,
    month: timezonedDate.month,
    day: timezonedDate.day,
    hour: timezonedDate.hour,
    minute: timezonedDate.minute,
  }).toJSDate();
}
