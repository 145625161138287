import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

import { Customer, Entity, Promotion, PromotionItem } from '@model/interfaces';
import { PromotionFrontend } from '@model/interfaces/promotion/promotion-frontend';
@Injectable({
  providedIn: 'root',
})
export class PromotionStore {
  private promotions$ = new BehaviorSubject<PromotionFrontend[]>([]);
  private promotion$ = new BehaviorSubject<PromotionFrontend | null>(null);
  private totalPromotions$: BehaviorSubject<number> = new BehaviorSubject(0);

  private readonly associateStores$: BehaviorSubject<Entity[]> =
    new BehaviorSubject<Entity[]>([] as Entity[]);

  private readonly avaliableStores$: BehaviorSubject<Entity[]> =
    new BehaviorSubject<Entity[]>([] as Entity[]);

  private readonly associatedCustomers$: BehaviorSubject<Customer[]> =
    new BehaviorSubject<Customer[]>([]);

  private readonly availableCustomers$: BehaviorSubject<Customer[]> =
    new BehaviorSubject<Customer[]>([]);

  private readonly promotionsByCustomer$: BehaviorSubject<Promotion[]> =
    new BehaviorSubject<Promotion[]>([] as Promotion[]);

  private readonly promotionSelected$: BehaviorSubject<Promotion> =
    new BehaviorSubject<Promotion>({} as Promotion);

  private readonly promotionApply$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  private readonly promotionItem$: BehaviorSubject<PromotionItem[]> =
    new BehaviorSubject<PromotionItem[]>([] as PromotionItem[]);

  set promotions(promotions: PromotionFrontend[]) {
    this.promotions$.next(promotions);
  }

  getPromotions() {
    return this.promotions$.asObservable();
  }

  set promotion(promotion: PromotionFrontend | null) {
    this.promotion$.next(promotion);
  }

  getPromotion() {
    return this.promotion$.asObservable();
  }

  set totalPromotions(total: number) {
    this.totalPromotions$.next(total);
  }

  getTotalPromotions() {
    return this.totalPromotions$.asObservable();
  }

  clearPromotion() {
    this.promotion$.next(null);
  }

  set associationStores(associationStores: Entity[]) {
    this.associateStores$.next([...associationStores]);
  }

  getAssociationStores() {
    return this.associateStores$.asObservable();
  }

  set avaliableStores(stores: Entity[]) {
    this.avaliableStores$.next([...stores]);
  }

  getAvaliableStores() {
    return this.avaliableStores$.asObservable();
  }

  set associatedCustomers(customers: Customer[]) {
    this.associatedCustomers$.next([...customers]);
  }

  getAssociatedCustomers() {
    return this.associatedCustomers$.asObservable();
  }

  set availableCustomers(customers: Customer[]) {
    this.availableCustomers$.next([...customers]);
  }

  getAvailableCustomers() {
    return this.availableCustomers$.asObservable();
  }

  set promotionByCustomer(promotions: Promotion[]) {
    this.promotionsByCustomer$.next([...promotions]);
  }

  getPromotionsByCustomer() {
    return this.promotionsByCustomer$.asObservable();
  }

  set promotionSelected(promotionSelected: Promotion) {
    this.promotionSelected$.next(promotionSelected);
  }

  getPromotionSelected() {
    return this.promotionSelected$.asObservable();
  }

  set promotionApply(promotionApply: boolean) {
    this.promotionApply$.next(promotionApply);
  }

  getPromotionApply() {
    return this.promotionApply$.asObservable();
  }

  set promotionItem(promotion: PromotionItem) {
    const promotionItems = this.promotionItem$.getValue();
    this.promotionItem$.next([...promotionItems, promotion]);
  }

  set promotionItems(promotions: PromotionItem[]) {
    this.promotionItem$.next([...promotions]);
  }

  getPromotionItems() {
    return this.promotionItem$.asObservable();
  }

  getPromotionItem = (id: string) =>
    this.promotionItem$
      .getValue()
      .find((promotionItems) => promotionItems.id == id);

  removePromotionItem = (item: PromotionItem) => {
    const promotionItems = this.promotionItem$.getValue();
    const promotionItemsFiltered = promotionItems.filter(
      (pItem) => pItem != item
    );
    this.promotionItem$.next([...promotionItemsFiltered]);
  };

  updatePromotionItemQuantity = (id: string, quantity: number) => {
    const promotions = this.promotionItem$.getValue();
    const index = promotions.findIndex(
      (promotionItem) => promotionItem.id == id
    );
    if (index >= 0) promotions[index].quantity = quantity;
    this.promotionItem$.next([...promotions]);
  };

  getPromotionItemSize = () => this.promotionItem$.getValue().length;

  hasPromotionItems = () =>
    this.promotionItem$.pipe(map((promotions) => promotions.length > 0));

  getPromotionItemValue = () => this.promotionItem$.getValue();

  reset = () => {
    this.associatedCustomers = [];
    this.associationStores = [];
    this.availableCustomers = [];
    this.avaliableStores = [];
    this.promotions = [];
    this.promotion = null;
    this.totalPromotions = 0;
    this.promotionSelected = {} as Promotion;
    this.promotionByCustomer = [];
  };
}
