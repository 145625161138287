import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { PickupResponse, PickupsResponse } from '@infrastructure/dtos';

@Injectable({
  providedIn: 'root',
})
export class PickupService {
  private URL = `${environment.apiOrderUrl}/pickups`;

  constructor(private readonly http: HttpClient) {}

  fetchPickups(
    status: string,
    page: number,
    size: number,
    date?: string
  ): Observable<PickupsResponse> {
    let params = new HttpParams()
      .append('status', status)
      .append('page', page)
      .append('size', size);
    if (date) params = params.append('date', date);
    return this.http.get<PickupsResponse>(`${this.URL}`, {
      params,
    });
  }

  fetchPickup(pickupId: number): Observable<PickupResponse> {
    return this.http.get<PickupResponse>(`${this.URL}/${pickupId}`);
  }

  createPickup(
    comments: string,
    customerId: number,
    pickupDate: string
  ): Observable<PickupResponse> {
    return this.http.post<PickupResponse>(`${this.URL}`, {
      comments,
      customerId,
      pickupDate,
    });
  }

  updatePickup(
    pickupId: number,
    comments: string,
    pickupDate: string
  ): Observable<void> {
    return this.http.put<void>(`${this.URL}/${pickupId}`, {
      comments,
      pickupDate,
    });
  }

  setAsComplete(pickupId: number): Observable<void> {
    return this.http.post<void>(`${this.URL}/${pickupId}/complete`, {});
  }

  deletePickup(pickupId: number): Observable<void> {
    return this.http.delete<void>(`${this.URL}/${pickupId}`);
  }
}
