import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import {
  AssociatedStore,
  AvailableStore,
  GalleryItem,
  GalleryMapper,
  OrderItem,
  Package,
  PackageItem,
} from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PackageStore implements GalleryMapper {
  private packages$: BehaviorSubject<Package[]> = new BehaviorSubject<
    Package[]
  >([]);

  private totalPackages$: BehaviorSubject<number> = new BehaviorSubject(0);

  private packageItems$: BehaviorSubject<PackageItem[]> = new BehaviorSubject<
    PackageItem[]
  >([]);

  private selectedPackage$: BehaviorSubject<Package | null> =
    new BehaviorSubject<Package | null>(null);

  private associatedStores$: BehaviorSubject<AssociatedStore[] | null> =
    new BehaviorSubject<AssociatedStore[] | null>(null);

  private availableStores$: BehaviorSubject<AvailableStore[] | null> =
    new BehaviorSubject<AvailableStore[] | null>(null);

  getPackages() {
    return this.packages$.asObservable();
  }

  getPackagesValue() {
    return this.packages$.value;
  }

  set packages(packages: Package[]) {
    this.packages$.next([...packages]);
  }

  getTotalPacakges() {
    return this.totalPackages$.asObservable();
  }

  set totalPackages(total: number) {
    this.totalPackages$.next(total);
  }

  getSelectedPackage() {
    return this.selectedPackage$.asObservable();
  }

  set selectedPackage(newPackage: Package | null) {
    if (newPackage) {
      this.selectedPackage$.next({ ...newPackage });
    } else {
      this.selectedPackage$.next(null);
    }
  }

  getPackage(id: number): Package | undefined {
    return this.packages$.value.find((p: Package) => {
      return p.id === id;
    });
  }

  getAssociatedStores() {
    return this.associatedStores$.asObservable();
  }

  set associatedStores(stores: AssociatedStore[] | null) {
    this.associatedStores$.next(stores);
  }

  getGalleryItems(): Observable<GalleryItem[]> {
    const images = [
      'https://photos.tryotter.com/menu-photos/e961ed3e-27f0-4544-a688-9f4341e4f9d1.png',
    ];
    return this.getPackages().pipe(
      map((packages) =>
        packages.map(({ id, name }) => {
          return {
            id,
            name,
            images,
          } as GalleryItem;
        })
      )
    );
  }

  getItems(): Observable<OrderItem[]> {
    return this.packageItems$.pipe(
      map((items: PackageItem[]) => {
        return items.map(({ id, name, quantity, total }) => {
          return { id, name, quantity, total } as OrderItem;
        });
      })
    );
  }

  set packageItems(packageItems: PackageItem[]) {
    this.packageItems$.next([...packageItems]);
  }

  getPackageItemsValue(): PackageItem[] {
    return this.packageItems$.getValue();
  }

  getAvailableStores() {
    return this.availableStores$.asObservable();
  }

  setAvailableStores(stores: AvailableStore[] | null) {
    this.availableStores$.next(stores);
  }

  switchAssociatedToAvailableStore(connectionId: number) {
    const associatedStores = this.associatedStores$.value;
    if (!associatedStores) return;
    const filtered = associatedStores.filter((as) => as.id !== connectionId);
    this.associatedStores$.next(filtered);

    const { id, name } = associatedStores.find((as) => as.id === connectionId)!;
    const availableStores = this.availableStores$.value!;
    availableStores.push({ id, name });
    this.availableStores$.next([...availableStores]);
  }

  resetPackageOrder = () => this.packageItems$.next([]);

  resetPackageSelected = () => this.selectedPackage$.next(null);

  resetAssociatedStores = () => this.associatedStores$.next(null);

  isPackageSelectedValue(): boolean {
    return this.selectedPackage$.getValue() != null;
  }

  getSelectedPackageValue() {
    return this.selectedPackage$.value;
  }
}
