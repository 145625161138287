import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { AuthFacade } from '@application/facades';

export const AlreadyAuthenticatedGuard = (): boolean | UrlTree => {
  const router = inject(Router);
  const authFacade = inject(AuthFacade);

  if (!authFacade.isLoggedIn()) {
    return true;
  }

  return router.parseUrl('/orders');
};
