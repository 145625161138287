import { Injectable } from '@angular/core';
import { take } from 'rxjs';

import { PositionStore } from '@application/stores';
import { PositionsService } from '@infrastructure/api';
import { PositionsResponse } from '@infrastructure/dtos';

@Injectable({
  providedIn: 'root',
})
export class PositionFacade {
  constructor(
    private readonly positionService: PositionsService,
    private readonly positionStore: PositionStore
  ) {}

  fetchPositions(): void {
    this.positionService
      .fetchPositions()
      .pipe(take(1))
      .subscribe((res: PositionsResponse) => {
        this.positionStore.positions = res.data;
        this.positionStore.totalPositions = res.total;
      });
  }
}
