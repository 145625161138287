import { StockItem, StockSetting } from '@model/interfaces';
import { Observable } from 'rxjs';

export abstract class IStockStore {
  abstract setStockItems(items: StockItem[]): void;
  abstract getStockItems(): Observable<StockItem[] | null>;

  abstract setStockSettings(items: StockSetting[]): void;
  abstract getStockSettings(): Observable<StockSetting[] | null>;
}
