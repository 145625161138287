import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ROLE } from '@model/enums';
import { AlreadyAuthenticatedGuard } from '@shared/guards/already-authenticated.guard';
import { AuthGuard } from '@shared/guards/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AlreadyAuthenticatedGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      roles: [ROLE.INTEGRATION_ADMIN],
    },
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
    data: {
      roles: [ROLE.STORE_USER],
    },
  },
  {
    path: 'manager',
    loadChildren: () =>
      import('./modules/manager/manager.module').then((m) => m.ManagerModule),
    canActivate: [AuthGuard],
    data: {
      roles: [ROLE.STORE_MANAGER],
    },
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./modules/home/modules/checkout/checkout.module').then(
        (m) => m.CheckoutModule
      ),
    canActivate: [AuthGuard],
    data: {
      roles: [ROLE.STORE_USER],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
