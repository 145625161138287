import { Observable } from 'rxjs';
import { MenuItem } from '../models/menu-item.model';
import { MenuModifierGroup } from '../models/menu-modifier-group.model';
import { Menu } from '../models/menu.model';

export abstract class IHubsterFacade {
  abstract initData(): void;
  abstract initAdminData(): void;

  abstract getMenus(): Observable<Menu[] | null>;
  abstract getMenuItems(): Observable<MenuItem[] | null>;
  abstract getMenuItemsByIds(ids: string[]): Observable<MenuItem[] | null>;

  abstract getItem(itemId: string): MenuItem | null;
  abstract getItemByName(name: string): MenuItem | null;
  abstract getModifierGroup(modifierId: string): MenuModifierGroup | null;

  abstract clearData(): void;
}
