import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  EmptyResponse,
  OrderResponse,
  OrdersResponse,
} from '@infrastructure/dtos';
import { Cart, OrderFilter } from '@model/interfaces';
import { InvoiceBody } from '@model/interfaces/invoice/invoice-body';
import { InvoiceResponse } from '@infrastructure/dtos/invoice/invoice-response';
import { JobsInvoiceResponse } from '@infrastructure/dtos/invoice/invoice-jobs-response';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private URL = `${environment.apiOrderUrl}`;

  constructor(private readonly http: HttpClient) {}

  createOrder(cart: Cart): Observable<OrderResponse> {
    return this.http.post<OrderResponse>(`${this.URL}/orders`, cart);
  }

  fetchOrder(id: number): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(`${this.URL}/orders/${id}`);
  }

  fetchOrders(filter: OrderFilter): Observable<OrdersResponse> {
    let params = new HttpParams();
    if (filter.paid === undefined) delete filter.paid;
    Object.entries(filter).map(([key, value]) => {
      params = params.set(key, value);
    });

    return this.http.get<OrdersResponse>(`${this.URL}/orders`, { params });
  }

  setOrderAsComplete(id: number, userId: number): Observable<EmptyResponse> {
    return this.http.post<EmptyResponse>(`${this.URL}/orders/${id}/complete`, {
      userId,
    });
  }

  orderInvoice(orderId: number) {
    return this.http.post(`${this.URL}/orders/${orderId}/invoice`, null);
  }

  cancelOrder(id: number, userId: number) {
    return this.http.post(`${this.URL}/orders/${id}/cancel`, { userId });
  }

  createInvoices(ids: InvoiceBody): Observable<InvoiceResponse> {
    return this.http.post<InvoiceResponse>(`${this.URL}/event`, ids);
  }

  invoicesJob(jobId: string) {
    return this.http.get<InvoiceResponse>(
      `${this.URL}/orders/invoices/${jobId}`
    );
  }

  payOrder(
    orderId: number,
    userId: number,
    value: number,
    paymentMethodId: number
  ) {
    return this.http.post<EmptyResponse>(`${this.URL}/orders/${orderId}/pay`, {
      userId,
      customerPayment: {
        value,
        paymentMethodId,
      },
    });
  }

  fetchAllInvoices(
    page: number,
    size: number
  ): Observable<JobsInvoiceResponse> {
    let params = new HttpParams();
    params = params.set('page', page);
    params = params.set('size', size);
    return this.http.get<JobsInvoiceResponse>(`${this.URL}/orders/invoices`, {
      params,
    });
  }
}
