import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

import { ItemInfo } from '@model/interfaces';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent<T extends ItemInfo> {
  @Input() packageItems: T[] = [];
  @Output() item = new EventEmitter<T>();
  @Output() itemClicked = new EventEmitter<number>();
  @ContentChild('controls', { static: false }) controlsRef:
    | TemplateRef<any>
    | undefined;
  @ContentChild('extra', { static: false }) extraRef:
    | TemplateRef<any>
    | undefined;
  @ContentChild('total', { static: false }) totalRef:
    | TemplateRef<any>
    | undefined;

  editItem = (item: T, index: number) => {
    this.item.emit(item);
    this.itemClicked.emit(index);
  };
}
