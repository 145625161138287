import { Injectable } from '@angular/core';
import { ROLE } from '@model/enums';

import { AuthToken } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthStore {
  private _roles: ROLE[] = [];
  private _connectionIds: number[] = [];

  getRoles(): ROLE[] {
    return this._roles;
  }

  set roles(roles: ROLE[]) {
    this._roles = [...roles];
  }

  setToken(token: AuthToken): void {
    localStorage.setItem('token', JSON.stringify(token));
  }

  getToken(): AuthToken | null {
    const token = localStorage.getItem('token');

    if (!token) return null;
    return JSON.parse(token) as AuthToken;
  }

  removeToken(): void {
    this.roles = [];
    localStorage.removeItem('token');
  }

  getConnectionIds(): number[] {
    return this._connectionIds;
  }

  set connectionIds(ids: number[]) {
    this._connectionIds = [...ids];
  }
}
