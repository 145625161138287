import { Injectable } from '@angular/core';
import { HubsterMenuModifierGroupsPublish } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData';
import { BehaviorSubject, Observable } from 'rxjs';
import { IHubsterStore } from '../interfaces/hubster-store.interface';
import { MenuItem, MenuItemMap } from '../models/menu-item.model';
import { MenuModifierGroupMap } from '../models/menu-modifier-group.model';
import { Menu } from '../models/menu.model';

@Injectable()
export class HubsterStore implements IHubsterStore {
  private readonly menus = new BehaviorSubject<Menu[] | null>(null);

  getMenus(): Observable<Menu[] | null> {
    return this.menus.asObservable();
  }

  setMenus(value: Menu[] | null): void {
    this.menus.next(value);
  }

  private readonly menuItems = new BehaviorSubject<MenuItem[] | null>(null);

  getMenuItems(): Observable<MenuItem[] | null> {
    return this.menuItems.asObservable();
  }

  getMenuItemsSnapshot(): MenuItem[] | null {
    return this.menuItems.getValue();
  }

  setMenuItems(value: MenuItem[] | null): void {
    this.menuItems.next(value);
  }

  private readonly modifierGroups =
    new BehaviorSubject<HubsterMenuModifierGroupsPublish | null>(null);

  setModifierGroups(modifiers: MenuModifierGroupMap | null): void {
    this.modifierGroups.next(modifiers);
  }

  getModifierGroupsSnapshot(): MenuModifierGroupMap | null {
    return this.modifierGroups.getValue();
  }

  private readonly menuItemsMap = new BehaviorSubject<MenuItemMap | null>(null);

  setItems(items: MenuItemMap | null): void {
    this.menuItemsMap.next(items);
  }

  getItemsSnapshot(): MenuItemMap | null {
    return this.menuItemsMap.getValue();
  }
}
