export enum ROLE {
  INTEGRATION_ADMIN = 'IntegrationAdmin',
  STORE_MANAGER = 'StoreManager',
  STORE_USER = 'StoreUser',
}

// TODO Remove bad practice
export enum ROLE_ID {
  INTEGRATION_ADMIN = 1,
  STORE_MANAGER = 2,
  STORE_USER = 3,
}
