import { DateTime } from 'luxon';
import { CashierSummary } from '../domain/infrastructure/dtos/cashier/cashier-summary-responde.dto';
import { StoreSettings } from '../domain/model/interfaces/store/store-settings';
import {
  formatBRLCurrency,
  formatIntlCompleteDate,
} from '../utils/intl-formatters.function';

/*
/////////////////////////////////////////////////////////////////////////

CIERRE CAJA

OMO LAVANDERIA

Linea dir 1

Linea dir 2

Linea dir 3

postal code

*********

FECHAMENTO CAIXA style="center"

ULTIMO X DIA style="center"

*********

fecha y hora

===============================

OPERAÇOES TICKET EMITIDO DIA

FATU. BRUTO:         R$10.00

FATU. BRUTO:         R$10.00                grossRevenue

(-) OPERAÇOES APOS EMISSÃO

DESC. ENTREGA:       R$0.00

V. ANULADO:          R$0.00

DESC. ENTREGA:       R$0.00                 deliveryDiscount

V. ANULADO:          R$0.00                 cancelations

-------------------------------

FATU. LIQUIDO:       R$10.00

FATU. LIQUIDO:       R$10.00                netRevenue

===============================

TOTAL GERAL style="center"

TOTAL GERAL style="center"                  paymentsDay (array)

AMEX:                   R$5.00

VISA:                   R$5.00

V. FATURADO DIA:        R$10.00

V. RECEBIDO DIA:        R$10.00

VALOR NO CAIXA          R$10.00

CONSUMO SALDO:          R$0.00

QTDE. DE PEÇAS:            125

QTDE. DE SERV:             125

QTDE. DE PROD:              10

A PAGAR (DIA):          R$3.00

A PAGAR (AC):           R$7.00

DESCONTO EMIS:          R$1.00

CONVENIO:               R$3.00

PROMOÇAO:               R$3.00

$ VIA SITE:             R$0.00

DEPOSITO CAIXA:         R$0.00

SANGRIA CAIXA:          R$0.00

TICKETS ANUL.:          R$0.00

VALORES ANUL.:          R$0.00

QTDE ORCA.:             R$0.00

VALORES ORCA.:          R$0.00

V. FATURADO DIA:        R$10.00             netRevenue

V. RECEBIDO DIA:        R$10.00             receivedDay

VALOR NO CAIXA          R$10.00             cashOnHand

CONSUMO SALDO:          R$0.00              creditConsumption

QTDE. DE PEÇAS:            125              totalClothes

QTDE. DE SERV:             125              totalServices

QTDE. DE PROD:              10              totalProducts

A PAGAR (DIA):          R$3.00              receivableDay

A PAGAR (AC):           R$7.00              receivableTotal

DESCONTO EMIS:          R$1.00              // Siempre 0 por ahora

CONVENIO:               R$3.00              // Siempre 0 por ahora

PROMOÇAO:               R$3.00              // Siempre 0 por ahora

$ VIA SITE:             R$0.00              // Remover

DEPOSITO CAIXA:         R$0.00              cashierDeposits

SANGRIA CAIXA:          R$0.00              cashierWithdrawals

TICKETS ANUL.:          R$0.00              canceledOrders

VALORES ANUL.:          R$0.00              cancelations

TODO: Check quotes
QTDE ORCA.:             R$0.00              quotes

VALORES ORCA.:          R$0.00              quotesTotal

INSUMOS style: "center"

BAIXA VENDA:                 0

BAIXA MANUAL:                0

INCLUSÃO MANUAL

Operador: Nombre usuario

Operador: Nombre usuario                    // Se obtiene de la petición de cerrar la caja

------------------------------

TICKETS EMITIDOS

TICKETS EMITIDOS                            issuedOrders (array)

ID             VALOR      PAGAMENTO

25072023-0001  R$1.00          VISA

25072023-0002  R$1.00

TOTAL RECEBIDO:         R$15.00

TOTAL EMITIDOS:         30

------------------------------

TICKETS ENTREGUES

TICKETS ENTREGUES                           deliveredOrders (array)

ID                    QTDE. PEÇAS

24072023-0001                   2

24072023-0002                   2

TOTAL ENTREGUES:               42

---------------------------------

TICKETS PAGOS

TICKETS PAGOS                               paidOrders (array)

ID             VALOR      PAGAMENTO

24072023-0001  R$1.00          VISA

24072023-0002  R$1.00.         AMEX

TOTAL RECEBIDO:             R$20.00

TOTAL PAGOS:                     30

---------------------------------

DESCONTOS DE CONVENIOS

FUNCIONARIOS UNI:           R$23.01

DESCONTOS DE FUNCIONÁRIOS                   issuanceDiscounts (array)

JEFFERSON CASTRO:           R$23.01

----------------------------------

DESCONTOS DE PROMOÇOES

PROMOÇÃO 1:                 R$13.01

PROMOÇÃO 2:                 R$13.01 // vacio

---------------------------------

DESCONTOS DE CONVENIOS                      agreementDiscounts

----------------------------------

DESCONTOS DE PROMOÇOES                      promotionDiscounts

///////////////////////////////////////////////////////////////////////////////////
*/
export const printCloseCashierTicket = (
  res: CashierSummary,
  userName: string,
  storeSettings: StoreSettings
) => {
  const newTab = window.open('', '_blank') as Window;
  newTab.document.open();
  newTab.document.write(
    getPrintCloseCashierTicket(res, userName, storeSettings)
  );
  newTab.print();
  newTab.close();
};

export const getPrintCloseCashierTicket = (
  res: CashierSummary,
  userName: string,
  storeSettings: StoreSettings
) => {
  const htmlParsed = /*html*/ `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Recibo</title>
    <style>
      @media print {
        @page {
          margin: 0;
        }
        body {
          margin: 1.6cm;
        }
      }
      body {
        width: 50mm;
        font-family: "Arial";
        font-weight: bold;
        font-size: 9pt;
      }
      .container {
        width: 45mm; /* plus .6 inches from padding */
        padding: 5mm;
        padding-top: 0;
        margin-right: 0.125in; /* the gutter */
        margin-top: 0;
        float: left;
        text-align: left;
        overflow: hidden;
        outline: 0px dotted; /* outline doesn't occupy space like border does */
      }
      .page-break {
        clear: left;
        display: block;
        page-break-after: always;
      }
      .center {
        text-align: center;
      }
      .bold {
        font-weight: bold;
      }
      table {
        font-size: 13px;
        width: 50mm;
      }
      table th {
        text-align: left;
      }
      table td {
        font-size: small;
      }
      .text-sm {
        font-size: x-small;
      }
      .align-right {
        text-align: right;
      }

      .w-full {
        width: 50mm;
      }

      .flex-between {
        display: flex;
        font-size: x-small;
        justify-content: space-between;
      }

      table tr .id-column {
        min-width: 11mm;
        word-break: break-all;
        text-align: start;
      }

      table tr .value-column {
        text-align: end;
      }

      table tr td {
        vertical-align: baseline;
        font-size: x-small;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="center w-full">CIERRE CAJA</div>
      <div class="center w-full">OMO LAVANDERIA</div>
      <br />
      <div class="center w-full">${storeSettings.address.fullAddress}</div>
      <div class="center w-full">${storeSettings.address.postalCode}</div>
      <br />
      ****************************************
      <div class="center w-full">FECHAMENTO CAIXA</div>
      <div class="center w-full">ULTIMO X DIA</div>
      ****************************************
      <div class="center w-full">${formatIntlCompleteDate(
        storeSettings.timezone.timezone,
        DateTime.now().setZone(storeSettings.timezone.timezone).toJSDate()
      )}</div>
      =============================== OPERAÇOES TICKET EMITIDO DIA <br />
      <div class="flex-between w-full">FATU. BRUTO: <span>${formatBRLCurrency(
        res.grossRevenue
      )}</span></div>

      <div>(-) OPERAÇOES APOS EMISSÃO DESC.</div>
      <div class="flex-between w-full">DESC. ENTREGA: <span>${formatBRLCurrency(
        res.deliveryDiscount
      )}</span></div>
      <div class="flex-between w-full">V. ANULADO: <span>${formatBRLCurrency(
        res.cancelations
      )}</span></div>
      ------------------------------------------------
      <div class="flex-between w-full">FATU. LIQUIDO: <span>${formatBRLCurrency(
        res.netRevenue
      )}</span></div>
      ===============================
      <div class="center">TOTAL GERAL</div>
      ${res.paymentsDay
        .map(
          (paymentDay) =>
            `<div class="flex-between w-full">${
              paymentDay.paymentMethod
            }: <span>${formatBRLCurrency(paymentDay.total)}</span></div>`
        )
        .join(' ')}

      <div class="flex-between w-full">
        V. FATURADO DIA: <span>${formatBRLCurrency(res.netRevenue)}</span>
      </div>

      <div class="flex-between w-full">
        V. RECEBIDO DIA: <span>${formatBRLCurrency(res.receivableDay)}</span>
      </div>

      <br />
      <div class="flex-between w-full">VALOR NO CAIXA <span>${formatBRLCurrency(
        res.cashOnHand
      )}</span></div>
      <br />
      <div class="flex-between w-full">CONSUMO SALDO: <span>${formatBRLCurrency(
        res.creditConsumption
      )}</span></div>

      <div class="flex-between w-full">QTDE. DE PEÇAS: <span>${
        res.totalClothes
      }</span></div>

      <div class="flex-between w-full">QTDE. DE SERV: <span>${
        res.totalServices
      }</span></div>

      <div class="flex-between w-full">QTDE. DE PROD: <span>${
        res.totalProducts
      }</span></div>

      <br />
      <div class="flex-between w-full">A PAGAR (DIA): <span>${formatBRLCurrency(
        res.receivableDay
      )}</span></div>

      <div class="flex-between w-full">A PAGAR (AC): <span>${formatBRLCurrency(
        res.receivableTotal
      )}</span></div>

      <div class="flex-between w-full">DESCONTO EMIS: <span>R$0.00</span></div>

      <div class="flex-between w-full">CONVENIO: <span>R$0.00</span></div>

      <div class="flex-between w-full">PROMOÇAO: <span>R$0.00</span></div>

      <div class="flex-between w-full">DEPOSITO CAIXA: <span>${formatBRLCurrency(
        res.cashierDeposits
      )}</span></div>

      <div class="flex-between w-full">SANGRIA CAIXA: <span>${formatBRLCurrency(
        res.cashierWithdrawals
      )}</span></div>

      <div class="flex-between w-full">TICKETS ANUL.: <span>${formatBRLCurrency(
        res.canceledOrders
      )}</span></div>

      <div class="flex-between w-full">VALORES ANUL.: <span>${formatBRLCurrency(
        res.cancelations
      )}</span></div>

      <div class="flex-between w-full">QTDE ORCA.: <span>${formatBRLCurrency(
        res.quotes
      )}</span></div>

      <div class="flex-between w-full">VALORES ORCA.: <span>${formatBRLCurrency(
        res.quotesTotal
      )}</span></div>

      <br />
      ===============================
      <div class="center">INSUMOS</div>
      <div class="flex-between w-full">BAIXA VENDA:<span>0</span></div>
      <div class="flex-between w-full">BAIXA MANUAL: <span>0</span></div>
      <div>INCLUSÃO MANUAL</div>

      ===============================
      <div class="center w-full">Operador: ${userName}</div>
      ===============================
      <div class="center">TICKETS EMITIDOS</div>

      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>PAGAMENTO</th>
            <th>VALOR</th>
          </tr>
        </thead>
        <tbody>
          ${res.issuedOrders
            .map(
              (order) => `
          <tr>
            <td class="id-column">${order.orderId}</td>
            <td>${order.paymentMethod ?? ''}</td>
            <td class="value-column">${formatBRLCurrency(order.total)}</td>
          </tr>
          `
            )
            .join(' ')}


        </tbody>
      </table>
      <div class="flex-between w-full">TOTAL RECEBIDO: <span>${formatBRLCurrency(
        res.issuedOrders.reduce(
          (prev, item) =>
            item.paymentMethod !== null ? prev + item.total : prev,
          0
        )
      )}</span></div>

      <div class="flex-between w-full">TOTAL EMITIDOS: <span>${
        res.issuedOrders.length
      }</span></div>
      ===============================
      <div class="center">TICKETS ENTREGUES</div>

      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th style="text-align: end">QTDE. PEÇAS</th>
          </tr>
        </thead>
        <tbody>
          ${res.deliveredOrders
            .map(
              (order) => `
          <tr>
            <td class="id-column">${order.orderId}</td>
            <td class="value-column">${order.deliveredItems}</td>
          </tr>
          `
            )
            .join(' ')}
        </tbody>
      </table>

      <div class="flex-between w-full">TOTAL EMITIDOS: <span>${res.deliveredOrders.reduce(
        (prev, item) => prev + item.deliveredItems,
        0
      )}</span></div>

      ===============================
      <div class="center">TICKETS PAGOS</div>

      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>PAGAMENTO</th>
            <th>VALOR</th>
          </tr>
        </thead>
        <tbody>
          ${res.paidOrders
            .map(
              (order) => `
          <tr>
            <td class="id-column">${order.orderId}</td>
            <td>${order.paymentMethod ?? ''}</td>
            <td class="value-column">${formatBRLCurrency(order.total)}</td>
          </tr>
          `
            )
            .join(' ')}
        </tbody>
      </table>

      <div class="flex-between w-full">TOTAL RECEBIDO: <span>${formatBRLCurrency(
        res.paidOrders.reduce(
          (prev, item) =>
            item.paymentMethod !== null ? prev + item.total : prev,
          0
        )
      )}</span></div>
      <div class="flex-between w-full">TOTAL PAGOS: <span>${
        res.paidOrders.length
      }</span></div>

      ===============================
      <div class="center">DESCONTOS DE FUNCIONÁRIOS</div>
      ${res.issuanceDiscounts.map(
        (discount) =>
          `<div class="flex-between w-full">${
            discount.userName
          } <span>${formatBRLCurrency(discount.total)}</span></div>`
      )}

      ===============================
      <div class="center">DESCONTOS DE CONVENIOS</div>
      Total: ${formatBRLCurrency(res.convenioPromoDiscounts)}
      ===============================
      <div class="center">DESCONTOS DE PROMOÇOES</div>
      Total: ${formatBRLCurrency(res.convenioPromoDiscounts)}
    </div>
    <div class="page-break"></div>
  </body>
</html>

  `;
  console.log(htmlParsed);
  return htmlParsed;
};
