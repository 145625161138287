<section class="bg-white px-2">
  <ul class="text-sm flex flex-col">
    <li *ngFor="let item of packageItems; index as i"
      class="flex flex-col items-center py-2 border-b w-full">
      <div class="flex w-full justify-between items-center">
        <app-item-count [value]="item" (click)="editItem(item, i)">
        </app-item-count>
        <ng-container
          *ngIf="controlsRef"
          [ngTemplateOutlet]="controlsRef"
          [ngTemplateOutletContext]="{$implicit:item}"
        ></ng-container>
      </div>
      <ng-container
        *ngIf="extraRef"
        [ngTemplateOutlet]="extraRef"
        [ngTemplateOutletContext]="{$implicit:item}"
      ></ng-container>
      <ng-container
        *ngIf="totalRef"
        [ngTemplateOutlet]="totalRef"
        [ngTemplateOutletContext]="{$implicit:item}"
      ></ng-container>
    </li>
    <ng-content></ng-content>
  </ul>
</section>
