import { Injectable } from '@angular/core';
import { combineLatest, filter, shareReplay } from 'rxjs';

import { AttendanceStore, StoreStore } from '@application/stores';
import { AttendanceService } from '@infrastructure/api';
import { ATTENDANCE } from '@model/enums';
import { UTCToTimezoned } from 'app/utils/utc-to-timezoned.function';
import { ToastrService } from 'ngx-toastr';
import { DateFormatFromTimezone } from '../../../../utils/date-format-from-timezone';
import { formatIntlShortDate } from '../../../../utils/intl-formatters.function';

@Injectable({
  providedIn: 'root',
})
export class AttendanceFacade {
  constructor(
    private attendanceService: AttendanceService,
    private attendanceStore: AttendanceStore,
    private toastrService: ToastrService,
    private storeStore: StoreStore
  ) {}

  getAttendances(date: string, connectionId: number) {
    combineLatest([
      this.storeStore
        .getStoreSettings()
        .pipe(filter((storeSettings) => Boolean(storeSettings.timezone))),
      this.attendanceService
        .getAttendances(date, connectionId)
        .pipe(shareReplay(1)),
    ]).subscribe({
      next: ([storeSettings, res]) => {
        const { timezone } = storeSettings.timezone;
        this.attendanceStore.attendances = res.data.map((attendance) => ({
          ...attendance,
          entryDate: UTCToTimezoned(attendance.entryDate, timezone),
          exitDate: attendance.exitDate
            ? UTCToTimezoned(attendance.exitDate, timezone)
            : '',
        }));
      },
      error: (err) => this.toastrService.error(err?.error.error.message),
    });
  }

  registerEntry(type: ATTENDANCE, userId: number, validInsert: boolean) {
    const { timezone } = this.storeStore.getCurrentSettings().timezone;
    this.attendanceService
      .registerEntry(type, userId)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          let action;
          const resWithTimezone = {
            ...res.data,
            entryDate: formatIntlShortDate(
              timezone,
              new Date(res.data.entryDate)
            ),
            exitDate: res.data.exitDate
              ? formatIntlShortDate(timezone, new Date(res.data.exitDate))
              : '',
          };

          if (type === 'ENTRY') {
            action = 'Entrada';
            if (validInsert)
              this.attendanceStore.addAttendance(resWithTimezone);
          } else {
            action = 'Saída';
            if (validInsert)
              this.attendanceStore.updateAttendance(resWithTimezone);
          }
          this.toastrService.success(action + ' criada com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  registerEntryForManager(
    entryDate: string,
    exitDate: string,
    userId: number,
    connectionId: number,
    validInsert: boolean
  ) {
    const { timezone } = this.storeStore.getCurrentSettings().timezone;
    const timezoneEntryDate = DateFormatFromTimezone(
      timezone,
      new Date(entryDate)
    ).toISOString();

    const timezoneExitDate = DateFormatFromTimezone(
      timezone,
      new Date(exitDate)
    ).toISOString();
    this.attendanceService
      .registerEntryForManager(
        timezoneEntryDate,
        timezoneExitDate,
        userId,
        connectionId
      )
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          if (validInsert)
            this.attendanceStore.addAttendance({
              ...res.data,
              entryDate: formatIntlShortDate(
                timezone,
                new Date(res.data.entryDate)
              ),
              exitDate: formatIntlShortDate(
                timezone,
                new Date(res.data.exitDate)
              ),
            });
          this.toastrService.success('Registro criado com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  updateAttendance(attendanceId: number, entryDate: string, exitDate: string) {
    const { timezone } = this.storeStore.getCurrentSettings().timezone;
    const timezoneEntryDate = DateFormatFromTimezone(
      timezone,
      new Date(entryDate)
    ).toISOString();

    const timezoneExitDate = DateFormatFromTimezone(
      timezone,
      new Date(exitDate)
    ).toISOString();

    this.attendanceService
      .updateAttendance(attendanceId, timezoneEntryDate, timezoneExitDate)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.attendanceStore.updateAttendance({
            ...res.data,
            entryDate: formatIntlShortDate(
              timezone,
              new Date(res.data.entryDate)
            ),
            exitDate: formatIntlShortDate(
              timezone,
              new Date(res.data.exitDate)
            ),
          });
          this.toastrService.success('Registro editado com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  clearAttendances() {
    this.attendanceStore.attendances = [];
  }
}
