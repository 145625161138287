<div class="flex w-full px-2 font-semibold">
  <span class=" text-xs cursor-pointer quantity">
    {{ value.quantity !== undefined && value.quantity >= 0
      ? value.quantity
      : value.totalQuantity }}
  </span>
  <span class="ml-2 cursor-pointer">
    {{ value.name | titlecase }}
  </span>
</div>
