import { Injectable } from '@angular/core';
import { CustomerStore, PickupStore, StoreStore } from '@application/stores';
import { PickupService } from '@infrastructure/api';

import { PickupDetail } from '@model/interfaces';
import { UTCToTimezoned } from 'app/utils/utc-to-timezoned.function';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, map, shareReplay } from 'rxjs';
import { DateFormatFromTimezone } from '../../../../utils/date-format-from-timezone';
import { formatIntlCompleteDate } from '../../../../utils/intl-formatters.function';
import { printPickup } from '../../../../utils/print-pickup.function';

@Injectable({
  providedIn: 'root',
})
export class PickupFacade {
  constructor(
    private pickupService: PickupService,
    private customerStore: CustomerStore,
    private pickupStore: PickupStore,
    private storeStore: StoreStore,
    private toastrService: ToastrService
  ) {}

  fetchPickups(status: string, page: number, size: number, date?: string) {
    combineLatest([
      this.storeStore.getStoreSettings(),
      this.pickupService.fetchPickups(status, page, size, date),
    ])
      .pipe(
        shareReplay(1),
        map(([storeSettings, pickups]) => {
          return {
            ...pickups,
            data: pickups.data.map((pickup) => ({
              ...pickup,
              pickupDate: UTCToTimezoned(
                pickup.pickupDate,
                storeSettings.timezone.timezone
              ),
            })),
          };
        })
      )
      .subscribe({
        next: (res) => {
          this.pickupStore.pickups = res.data;
          this.pickupStore.totalPickups = res.total;
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  fetchPickup(pickupId: number) {
    combineLatest([
      this.storeStore.getStoreSettings(),
      this.pickupService.fetchPickup(pickupId),
    ])
      .pipe(shareReplay(1))
      .subscribe({
        next: ([storeSettings, pickupResponse]) => {
          const pickup = pickupResponse.data;
          pickup.pickupDate = UTCToTimezoned(
            pickup.pickupDate,
            storeSettings.timezone.timezone
          );
          this.pickupStore.pickup = pickup;
          this.customerStore.customer = pickup.customer;
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  createPickup(
    comments: string,
    customerId: number,
    pickupDate: string,
    wantPrint: boolean
  ) {
    const { timezone: completeTimezone } = this.storeStore.getCurrentSettings();

    const timezoneDate = DateFormatFromTimezone(
      completeTimezone.timezone,
      new Date(pickupDate)
    ).toISOString();

    this.pickupService
      .createPickup(comments, customerId, timezoneDate)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          const pickup = res.data;
          pickup.pickupDate = UTCToTimezoned(
            res.data.pickupDate,
            completeTimezone.timezone
          );
          this.pickupStore.addPickup(pickup);
          this.toastrService.success('Pedido criado com sucesso');
          if (wantPrint) {
            printPickup(
              pickup,
              this.storeStore.getCurrentSettings().address.fullAddress
            );
          }
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  updatePickup(
    pickup: PickupDetail,
    comments: string,
    pickupDate: string,
    wantPrint: boolean
  ) {
    const { timezone: completeTimezone } = this.storeStore.getCurrentSettings();

    const timezoneDate = DateFormatFromTimezone(
      completeTimezone.timezone,
      new Date(pickupDate)
    ).toISOString();

    this.pickupService
      .updatePickup(pickup.id, comments, timezoneDate)
      .pipe(shareReplay(1))
      .subscribe({
        next: () => {
          this.pickupStore.updatePickup(
            pickup.id,
            comments,
            formatIntlCompleteDate(
              completeTimezone.timezone,
              new Date(timezoneDate)
            )
          );
          if (wantPrint) {
            printPickup(
              {
                ...pickup,
                comments,
                pickupDate: formatIntlCompleteDate(
                  completeTimezone.timezone,
                  new Date(timezoneDate)
                ),
              },
              this.storeStore.getCurrentSettings().address.fullAddress
            );
          }
          this.toastrService.success('Pedido editado com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  setAsComplete(pickupId: number) {
    this.pickupService
      .setAsComplete(pickupId)
      .pipe(shareReplay(1))
      .subscribe({
        next: () => {
          this.pickupStore.setAsComplete(pickupId);
          this.toastrService.success('Pedido completado com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  deletePickup(pickupId: number) {
    this.pickupService
      .deletePickup(pickupId)
      .pipe(shareReplay(1))
      .subscribe({
        next: () => {
          this.pickupStore.deletePickup(pickupId);
          this.toastrService.success('Pedido excluído com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  resetPickup() {
    this.pickupStore.resetPickup();
  }

  resetPickups() {
    this.pickupStore.resetPickups();
  }
}
