export const formatBRLCurrency = (num: unknown) => {
  const formatter = Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
  });

  return typeof num !== 'number'
    ? formatter.format(0).replace(/\s/g, '')
    : formatter.format(num).replace(/\s/g, '');
};

export const longDateFormatter = (timezone: string) =>
  Intl.DateTimeFormat('pt-BR', {
    timeZone: timezone,
    hour: '2-digit',
    minute: 'numeric',
    day: '2-digit',
    year: 'numeric',
    month: 'short',
    hourCycle: 'h12',
  });

export const formatIntlCompleteDate = (timezone: string, date: Date) =>
  longDateFormatter(timezone).format(date);

export const formatIntlMediumDate = (timezone: string, date: Date) =>
  Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'medium',
    timeZone: timezone,
  }).format(date);

export const formatIntlShortDate = (timezone: string, date: Date) =>
  Intl.DateTimeFormat('sv-SE', {
    day: '2-digit',
    year: 'numeric',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timezone,
  }).format(date);
