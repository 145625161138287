<article class="flex flex-col items-center gap-3 w-full justify-between h-full">
  <div class="position">
    <div
      *ngFor="
      let position of positions$
      | async
      | pagination : pageIndex : pageSize
      "
    >
      <input
        name="position"
        type="radio"
        class="hidden"
        *ngIf="!position.order"
        [formControl]="positionControl"
        [id]="position.id"
        [value]="position.id"
      />
      <label
        class="item"
        [for]="position.id"
        [ngClass]="{ disabled: !isReadable(position.order) }"
        (click)="statusEmit(position)"
      >
        <mat-icon *ngIf="position.order" class="scale-150">
          shopping_cart
        </mat-icon>
        <mat-icon *ngIf="!position.order" class="scale-150">
          add_shopping_cart
        </mat-icon>
        <span *ngIf="!position.order">{{ position.number }} Livre</span>
        <span *ngIf="position.order"
          >{{ position.number }} {{ position.order.customerName }}</span
        >
        <sub
          class="text-xs opacity-50"
          *ngIf="position.order"
        >
          {{ position.order.sequence }}
        </sub>
      </label>
    </div>
  </div>
  <mat-paginator
    *ngIf="(positions$ | async)!.length"
    (page)="handlePageEvent($event)"
    [length]="(positions$ | async)!.length"
    [pageSize]="20"
    [hidePageSize]="true"
    [showFirstLastButtons]="true"
    [pageIndex]="pageIndex"
  >
  </mat-paginator>
</article>
