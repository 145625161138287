import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

import { PackageStore } from '@application/stores';
import { OrderItem, PackageAvailable } from '@model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ItemsStore {
  packageSelected$ = inject(PackageStore).getSelectedPackage();

  private items$: BehaviorSubject<OrderItem[]> = new BehaviorSubject(
    [] as OrderItem[]
  );

  set item(item: OrderItem) {
    this.items$.next([...this.items$.getValue(), item]);
  }

  set items(items: OrderItem[]) {
    this.items$.next([...items]);
  }

  getItems(): Observable<OrderItem[]> {
    return this.items$.asObservable();
  }

  hasItems(): Observable<boolean> {
    return this.items$.pipe(map((items) => items.length > 0));
  }

  hasItemsValue(): boolean {
    return this.items$.getValue().length > 0;
  }

  deleteItem(item: OrderItem): void {
    const items = this.items$.getValue().filter((i) => i !== item);
    this.items$.next([...items]);
  }

  updateItem(oldItem: OrderItem, newItem: OrderItem): void {
    const items = this.items$
      .getValue()
      .map((i) => (i !== oldItem ? i : newItem));
    this.items$.next([...items]);
  }

  // getItemValue(index: number): OrderItem | undefined {
  //   return this.items$.getValue().at(index);
  // }

  clearItems = () => this.items$.next([]);

  private getAvailablePackage$(): Observable<PackageAvailable> {
    return this.packageSelected$.pipe(
      map(
        (packageItem) =>
          ({
            total: packageItem?.totalQuantity || null,
            usage: 0,
            available: packageItem?.totalQuantity || null,
            items: packageItem?.items.map((item) => ({
              id: item.id,
              total: item.quantity || 0,
              usage: 0,
              available: item.quantity || 0,
              isUnlimited: item.isUnlimited,
            })),
          } as PackageAvailable)
      )
    );
  }

  itemsAvailable(): Observable<PackageAvailable> {
    return combineLatest([this.items$, this.getAvailablePackage$()]).pipe(
      map(([items, packageItem]) => {
        if (!items || !packageItem.items) return packageItem;
        let totalUsage = 0;
        const pItems = packageItem.items.map((item) => {
          const usage = this.getUsageByItemId(items, item.id);
          totalUsage += usage;
          return { ...item, usage, available: item.total - usage };
        });
        packageItem.items = [...pItems];
        packageItem.usage = totalUsage;
        packageItem.available = packageItem.total - totalUsage;
        return packageItem;
      })
    );
  }

  getUsageByItemId(items: OrderItem[], id: string): number {
    return items
      .filter((i) => i.id == id)
      .reduce(
        ({ quantity: qTotal }, { quantity: qCur }) =>
          ({
            quantity: (qTotal += qCur),
          } as OrderItem),
        { quantity: 0 } as OrderItem
      ).quantity;
  }

  getItemAvailable(id: string): Observable<number | null> {
    return this.itemsAvailable().pipe(
      map((item) => {
        if (!item.items) return null;
        const selected = item.items.find((item) => item.id == id);
        if (!selected) return item.available;
        if (selected?.isUnlimited) return item.available;
        if (item.available < selected.available) return item.available;
        return selected?.available;
      })
    );
  }
}
