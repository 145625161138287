import { DateTime, Settings } from 'luxon';

/**
 * Returns a string datetime in the requested timezone
 * @param date string utc date, e.g. 2024-01-02T00:00:00.000Z
 * @param timezone date timezone
 * @returns Date in format yyyy-MM-dd HH:mm
 */
export const UTCToTimezoned = (date: string, timezone: string): string => {
  Settings.defaultLocale = 'pt';
  return DateTime.fromISO(date, { zone: timezone }).toFormat(
    'yyyy-MM-dd HH:mm'
  );
};
