import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MapService } from '@infrastructure/api';
import { CustomerAddress } from '@model/interfaces';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
})
export class AddressInputComponent implements AfterViewInit, OnInit {
  @ViewChild('fullAddress', { static: false })
  addressRef!: ElementRef<HTMLInputElement>;

  innerControl!: FormControl;

  @Input() addressAbstractControl!: AbstractControl;
  addressFormGroup!: FormGroup;

  isAddressFinder = true;

  addressFields: CustomerAddress = {
    streetName: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    location: {
      latitude: 0,
      longitude: 0,
    },
    postalCode: '',
    additionalComments: '',
    countryCode: '',
    fullAddress: '',
  };

  constructor(
    private mapService: MapService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.addressFormGroup = this.addressAbstractControl as FormGroup;
    this.innerControl = new FormControl('');
  }

  ngAfterViewInit() {
    let autocomplete: google.maps.places.Autocomplete | undefined;
    this.addressRef.nativeElement.onkeyup = (ev) => {
      if (this.addressRef.nativeElement.value.length < 6) {
        ev.stopImmediatePropagation();
        return;
      } else {
        if (!autocomplete) {
          autocomplete = new google.maps.places.Autocomplete(
            this.addressRef.nativeElement,
            {
              componentRestrictions: { country: 'BR' },
              types: ['address'], // 'establishment' / 'address' / 'geocode'
            }
          );
          google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.invokeEvent(autocomplete!.getPlace());
          });
        }
      }
    };
  }

  invokeEvent(place: google.maps.places.PlaceResult) {
    if (!place.address_components) {
      return;
    }
    this.addressFields.streetName =
      place.address_components.find((c) => c.types.includes('route'))
        ?.long_name ?? '';
    this.addressFields.number =
      place.address_components.find((c) => c.types.includes('street_number'))
        ?.short_name ?? '';
    this.addressFields.neighborhood =
      place.address_components.find((c) => c.types.includes('sublocality'))
        ?.long_name ?? '';
    this.addressFields.city =
      place.address_components.find((c) =>
        c.types.includes('administrative_area_level_2')
      )?.long_name ?? '';
    this.addressFields.state =
      place.address_components.find((c) =>
        c.types.includes('administrative_area_level_1')
      )?.short_name ?? '';
    if (place.geometry) {
      this.addressFields.location = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      };
    }
    this.addressFields.postalCode =
      place.address_components.find((c) => c.types.includes('postal_code'))
        ?.long_name ?? '';
    this.addressFields.countryCode =
      place.address_components.find((c) => c.types.includes('country'))
        ?.short_name ?? '';

    this.innerControl.setValue(place.formatted_address);
    this.addressFormGroup.patchValue(this.addressFields);
  }

  initZipcodeFinder() {
    this.innerControl = new FormControl('', [Validators.minLength(9)]);
    this.innerControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value: string) => {
        if (!this.innerControl.errors) {
          this.mapService.getDataFromZipcode(value).subscribe({
            next: (res) => {
              const components = res.data.address_components;
              this.addressFields.streetName =
                components.find((c) => c.type === 'route')?.long_name ?? '';
              this.addressFields.number =
                components.find((c) => c.type === 'street_number')
                  ?.short_name ?? '';
              this.addressFields.neighborhood =
                components.find((c) => c.type === 'sublocality')?.long_name ??
                '';
              this.addressFields.city =
                components.find((c) => c.type === 'administrative_area_level_2')
                  ?.long_name ?? '';
              this.addressFields.state =
                components.find((c) => c.type === 'administrative_area_level_1')
                  ?.short_name ?? '';
              this.addressFields.location = {
                latitude: res.data.geometry.location.lat,
                longitude: res.data.geometry.location.lng,
              };
              this.addressFields.postalCode =
                components.find((c) => c.type === 'postal_code')?.long_name ??
                '';
              this.addressFields.countryCode =
                components.find((c) => c.type === 'country')?.short_name ?? '';
              this.addressFormGroup.patchValue(this.addressFields);
            },
            error: () => {
              this.toastrService.error('Zero results');
            },
          });
        }
      });
  }

  onToggle(checked: boolean) {
    this.isAddressFinder = checked;
    if (this.isAddressFinder) {
      this.innerControl = new FormControl('');
      return;
    }
    this.initZipcodeFinder();
  }
}
