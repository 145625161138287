import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Observable, combineLatest, startWith } from 'rxjs';

import { CartStore, PositionStore } from '@application/stores';
import { POSITION_FILTER, POSITION_MODE } from '@model/enums';
import { OrderInPosition, Position, PositionEmit } from '@model/interfaces';

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss'],
})
export class PositionComponent implements OnInit {
  positions$: Observable<Position[]> = this.positionStore.getPositions(
    POSITION_FILTER.ALL,
    ''
  );

  @Input() statusAbstractControl!: AbstractControl;
  statusFilter!: FormControl;
  @Input() textAbstractControl?: AbstractControl;
  textFilter!: FormControl;

  @Input() mode = POSITION_MODE.READ;
  @Output() status = new EventEmitter<PositionEmit>();

  pageIndex = 0;
  pageSize = 20;
  actualPosition = this.cartStore.getPositionIdValue();
  positionControl = new FormControl(this.actualPosition);
  isReadable = (order: OrderInPosition | null) =>
    this.mode == POSITION_MODE.READ ? order != null : order == null;

  constructor(
    private readonly cartStore: CartStore,
    private readonly positionStore: PositionStore
  ) {}

  ngOnInit() {
    this.statusFilter = this.statusAbstractControl as FormControl;
    this.textFilter = this.textAbstractControl
      ? (this.textAbstractControl as FormControl)
      : new FormControl('');

    combineLatest([
      this.statusFilter.valueChanges.pipe(startWith(this.statusFilter.value)),
      this.textFilter.valueChanges.pipe(startWith(this.textFilter.value)),
    ]).subscribe(([status, text]) => {
      this.pageIndex = 0;
      this.positions$ = this.positionStore.getPositions(status, text);
    });
  }

  handlePageEvent(e: PageEvent) {
    this.pageIndex = e.pageIndex;
  }

  statusEmit(e: Position) {
    if (!this.isReadable(e.order)) return;
    const emit = {
      value: +e.id,
      invalid: false,
      order: e.order,
    } as PositionEmit;
    this.status.emit(emit);
  }
}
