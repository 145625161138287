import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface AppTabOption {
  name: string;
  value: string;
  active: boolean;
}

@Component({
  standalone: true,
  selector: 'app-tabs',
  imports: [NgFor],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Output() optionChanged = new EventEmitter<string>();
  @Input() options!: AppTabOption[];
  @Input() public set class(value: string) {
    this.extraClasses = ' ' + value;
  }

  public extraClasses = '';

  changeOption(option: AppTabOption) {
    this.options = this.options.map((opt) => ({
      ...opt,
      active: opt.value === option.value,
    }));

    this.optionChanged.emit(option.value);
  }
}
