import { Injectable } from '@angular/core';
import { StockItem, StockSetting } from '@model/interfaces';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StockStore {
  private stockSettings$: BehaviorSubject<StockSetting[] | null> =
    new BehaviorSubject<StockSetting[] | null>(null);

  private stockItems$: BehaviorSubject<StockItem[] | null> =
    new BehaviorSubject<StockItem[] | null>(null);

  getStockSettings() {
    return this.stockSettings$.asObservable();
  }

  getStockItems() {
    return this.stockItems$.asObservable();
  }

  set stockSettings(stockSettings: StockSetting[]) {
    this.stockSettings$.next(stockSettings);
  }

  set stockItems(stockItems: StockItem[]) {
    this.stockItems$.next([...stockItems]);
  }

  updateItemQuantity(itemId: number, quantity: number) {
    const stockItemsArray = this.stockItems$.value as StockItem[];
    const itemSelected = stockItemsArray.find(
      (i) => i.id === itemId
    ) as StockItem;
    itemSelected.stock = quantity;
    this.stockItems$.next(stockItemsArray);
  }

  updateSetting(stockSetting: StockSetting) {
    const settingsArray = this.stockSettings$.value as StockSetting[];
    const updatedSettings = settingsArray.filter(
      (s) => s.id !== stockSetting.id
    );
    updatedSettings.push(stockSetting);
    this.stockSettings$.next(updatedSettings);
  }

  getStockItemId(id: number) {
    return this.stockItems$
      .asObservable()
      .pipe(
        map(
          (items) => items?.find((stockItem) => stockItem.id == id) || undefined
        )
      );
  }

  getStockItemName(name: string) {
    return this.stockItems$
      .asObservable()
      .pipe(
        map(
          (items) =>
            items?.find((stock) => stock.itemName === name)?.stock ?? undefined
        )
      );
  }
}
