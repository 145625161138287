import { ROLE } from '@model/enums';
import { SidebarOption } from '../models/sidebar-option.interface';

export const SIDEBAR_OPTIONS: SidebarOption[] = [
  {
    name: 'Pacotes',
    path: '/admin/packages',
    expanded: false,
    roles: [ROLE.INTEGRATION_ADMIN],
    hasOptions: false,
    options: [],
    icon: 'icon-package',
  },
  {
    name: 'Meios de pagamento',
    path: '/admin/payment-methods',
    expanded: false,
    roles: [ROLE.INTEGRATION_ADMIN],
    hasOptions: false,
    options: [],
    icon: 'icon-payment_method',
  },
  {
    name: 'Royalties',
    path: '/admin/royalties',
    expanded: false,
    roles: [ROLE.INTEGRATION_ADMIN],
    hasOptions: false,
    options: [],
    icon: 'icon-royalties',
  },
  {
    name: 'Cancelamentos',
    path: '/admin/cancellations',
    expanded: false,
    roles: [ROLE.INTEGRATION_ADMIN],
    hasOptions: false,
    options: [],
    icon: 'icon-cancellations',
  },
  {
    name: 'Lojas',
    path: '/manager/stores',
    expanded: false,
    roles: [ROLE.STORE_MANAGER],
    hasOptions: false,
    options: [],
    icon: 'icon-store',
  },
  {
    name: 'Usuários',
    path: '/manager/users',
    expanded: false,
    roles: [ROLE.STORE_MANAGER],
    hasOptions: false,
    options: [],
    icon: 'icon-user',
  },
  {
    name: 'Caixa',
    path: '/manager/cashier',
    expanded: false,
    roles: [ROLE.STORE_MANAGER],
    hasOptions: false,
    options: [],
    icon: 'icon-cashier',
  },
  {
    name: 'Entrada/Saída',
    path: '/manager/attendance',
    expanded: false,
    roles: [ROLE.STORE_MANAGER],
    hasOptions: false,
    options: [],
    icon: 'icon-attendance',
  },
  {
    name: 'Stock - NFe',
    path: '/manager/stock',
    expanded: false,
    roles: [ROLE.STORE_MANAGER],
    hasOptions: false,
    options: [],
    icon: 'icon-invoice',
  },
  {
    name: 'Configurações',
    path: '/manager/stores/settings',
    expanded: false,
    roles: [ROLE.STORE_MANAGER],
    hasOptions: false,
    options: [],
    icon: 'icon-settings',
  },
  {
    name: 'Clientes',
    path: '/customers',
    expanded: false,
    roles: [ROLE.STORE_USER],
    hasOptions: false,
    options: [],
    icon: 'icon-customer',
  },
  {
    name: 'Pedidos',
    path: '/orders',
    expanded: false,
    roles: [ROLE.STORE_USER],
    hasOptions: false,
    options: [],
    icon: 'icon-order',
  },
  {
    name: 'Locais',
    path: '/positions',
    expanded: false,
    roles: [ROLE.STORE_USER],
    hasOptions: false,
    options: [],
    icon: 'icon-position',
  },
  {
    name: 'Pacotes',
    path: '/package-order',
    expanded: false,
    roles: [ROLE.STORE_USER],
    hasOptions: false,
    options: [],
    icon: 'icon-package',
  },
  {
    name: 'Coletas',
    path: '/pickup',
    expanded: false,
    roles: [ROLE.STORE_USER],
    hasOptions: false,
    options: [],
    icon: 'icon-pickup',
  },
  {
    name: 'Caixa',
    path: '/cashier',
    expanded: false,
    roles: [ROLE.STORE_USER],
    hasOptions: false,
    options: [],
    icon: 'icon-cashier',
  },
  {
    name: 'Nota Fiscal',
    path: '/invoice',
    expanded: false,
    roles: [ROLE.STORE_USER],
    hasOptions: false,
    options: [],
    icon: 'icon-invoice',
  },
  {
    name: 'Nota Fiscal',
    path: '/manager/invoice',
    expanded: false,
    roles: [ROLE.STORE_MANAGER],
    hasOptions: false,
    options: [],
    icon: 'icon-invoice',
  },
  {
    name: 'Promoções',
    path: '/admin/promotions',
    expanded: false,
    roles: [ROLE.INTEGRATION_ADMIN],
    hasOptions: false,
    options: [],
    icon: 'icon-promotion',
  },
  {
    name: 'Convênios',
    path: '/manager/promotions',
    expanded: false,
    roles: [ROLE.STORE_MANAGER],
    hasOptions: false,
    options: [],
    icon: 'icon-promotion',
  },
  {
    name: 'Convênios',
    path: '/promotions',
    expanded: false,
    roles: [ROLE.STORE_USER],
    hasOptions: false,
    options: [],
    icon: 'icon-promotion',
  },
  {
    name: 'Entrada/Saída',
    path: '/attendance',
    expanded: false,
    roles: [ROLE.STORE_USER],
    hasOptions: false,
    options: [],
    icon: 'icon-attendance',
  },
];
