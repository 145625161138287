import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

registerLocaleData(localePt);

import { AuthInterceptorService } from '@shared/interceptors/auth.interceptor';
import getPortuguesePaginatorIntl from '@shared/intl/paginator.intl';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StockModule } from './modules/_stock/stock.module';
import { HubsterModule } from './modules/hubster/hubster.module';
import { IInterceptorFilter } from '@shared/interfaces/interceptor-filter';
import { LoadingHttpContextSkip } from '@shared/interceptors/filters/loading-skip.filter';
import { LoadingInterceptor } from '@shared/interceptors/loading.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    SharedModule,
    ToastrModule.forRoot(),
    HubsterModule.forRoot(),
    StockModule.forFeature(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: IInterceptorFilter,
      useClass: LoadingHttpContextSkip,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MatPaginatorIntl,
      useValue: getPortuguesePaginatorIntl(),
    },
    { provide: LOCALE_ID, useValue: 'pt' },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
