import { Injectable } from '@angular/core';
import { Attendance } from '@model/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttendanceStore {
  private attendances$ = new BehaviorSubject<Attendance[] | null>(null);

  getAttendances() {
    return this.attendances$.asObservable();
  }

  set attendances(attendances: Attendance[]) {
    this.attendances$.next(attendances);
  }

  addAttendance(attendance: Attendance) {
    const currentAttendances = this.attendances$.value as Attendance[];
    this.attendances$.next([...currentAttendances, attendance]);
  }

  updateAttendance(attendance: Attendance) {
    const newArray = [...(this.attendances$.value as Attendance[])];
    const index = newArray.findIndex((el) => el.id === attendance.id);
    newArray[index] = attendance;
    this.attendances$.next(newArray);
  }
}
