import { PickupDetail } from '@model/interfaces';

export const printPickup = (pickup: PickupDetail, fullAddress: string) => {
  setTimeout(function () {
    const newTab = window.open('', '_blank') as Window;
    newTab.document.open();
    newTab.document.write(genTicketTemplate(pickup, fullAddress));
    newTab.print();
    newTab.close();
  }, 1000);
};

const genTicketTemplate = (pickup: PickupDetail, fullAddress: string) => `
<html lang="en">

<head>
  <meta charset="utf-8">
  <style>
    @media print {   @page { margin: 0; }   body { margin: 1.6cm; } }
    body {
      width: 50 mm;
      font-family: "Arial";
      font-weight: bold;
      font-size: 9pt;
    }
    .label {
      width: 45mm; /* plus .6 inches from padding */
      padding: 5mm;
      padding-top: 0;
      margin-right: .125in; /* the gutter */
      margin-top: 0;
      float: left;
      text-align: left;
      overflow: hidden;
      outline: 0px dotted; /* outline doesn't occupy space like border does */
    }
    .page-break  {
      clear: left;
      display:block;
      page-break-after:always;
    }
    .comments {
      font-size: 8pt;
      font-weight: lighter;
      margin-left: 3mm;
      display: block;
    }
    .center{
      text-align: center;
    }
    .footer {
      text-align: center;
    }
  </style>
</head>

<body>
  <div class="label">
    <div class="center">OMO LAVANDERIA</div>
    <div class="center">${fullAddress}</div>
    ************************************
    <br> Coleta: ${pickup.id}
    <br> ------------------------------------------
    CLIENTE: ${pickup.customer.customerName}
    <br> TELEFONE: ${pickup.customer.phoneNumber}
    <br> ${pickup.customer.address.fullAddress}
    <br>
    <br> COLETA: ${pickup.pickupDate}
    <br> ------------------------------------------
    <br> ${pickup.comments}
  </div>
</body>
</html>
`;
