<span class="ml-1">{{ label }}</span>
<input
  class="w-full rounded"
  [ngClass]="{'border-red-400 bg-red-100': control.errors && control.touched }"
  [type]="type"
  [autocomplete]="autocomplete"
  [placeholder]="placeholder || label"
  [formControl]="control"
  [mask]="mask"
  (blur)="onBlur()"
  [maxlength]="maxlength" />
<div class="flex items-center h-6">
  <span
    *ngIf="control.touched && control.errors"
    class="text-xs text-red-500 ml-2 w-full"
    >{{ getErrorMsg(control.errors) }}</span
  >
</div>
