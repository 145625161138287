<ul class="grid gallery-grid gap-3 pb-3">
  <li
    *ngFor="let item of items"
    class="item bg-white border p-4"
    [ngClass]="{'cursor-pointer': isClickable}"
    (click)="itemClicked(item)"
  >
    <ng-container
      *ngIf="itemRef;else default"
      [ngTemplateOutlet]="itemRef"
      [ngTemplateOutletContext]="{$implicit:item}"
    ></ng-container>
    <ng-template  #default>
      {{ item.name | uppercase }}
      <p class="text-gray-400">QUANTIDADE:
        {{ item.quantity != null && item.quantity >= 0
        ? item.quantity
        : item.totalQuantity }}</p>
    </ng-template>
  </li>
</ul>
