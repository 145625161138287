import { ModuleWithProviders, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { SERVICES_URL } from '../../common/types/services-url.token';
import { IStockFacade } from './interfaces/stock-facade.interface';
import { IStockRepository } from './interfaces/stock-repository.interface';
import { IStockStore } from './interfaces/stock-store.interface';
import { StockFacade } from './services/stock-facade.service';
import { StockRepository } from './services/stock-repository.service';
import { StockStore } from './services/stock-store.service';
import { StockModuleConfig } from './types/stock-config.type';

@NgModule({
  providers: [
    {
      provide: SERVICES_URL,
      useValue: environment.apiMenuUrl,
    },
  ],
})
export class StockModule {
  static forFeature(
    config?: StockModuleConfig
  ): ModuleWithProviders<StockModule> {
    return {
      ngModule: StockModule,
      providers: [
        {
          provide: IStockRepository,
          useClass: config?.repositoryHandler ?? StockRepository,
        },
        {
          provide: IStockFacade,
          useClass: config?.facadeHandler ?? StockFacade,
        },
        {
          provide: IStockStore,
          useClass: config?.storeHandler ?? StockStore,
        },
      ],
    };
  }
}
