import { Injectable } from '@angular/core';
import { BehaviorSubject, delay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  count = 0;
  private _isLoading = new BehaviorSubject<boolean>(false);

  getIsLoading() {
    return this._isLoading.asObservable().pipe(delay(1));
  }

  show() {
    this._isLoading.next(true);
    this.count++;
  }

  hide() {
    this.count--;
    if (this.count === 0) {
      this._isLoading.next(false);
    }
  }
}
