import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

import { ItemInfo } from '@model/interfaces';

@Component({
  selector: 'app-package-info',
  templateUrl: './package-info.component.html',
  styleUrls: ['./package-info.component.scss'],
})
export class PackageInfoComponent<T extends ItemInfo> {
  // TODO Should depend on Package
  @Input() items: T[] = [];
  @Input() isClickable?: boolean = false;
  @Output() itemSelected: EventEmitter<T> = new EventEmitter<T>();
  @ContentChild('item', { static: false }) itemRef:
    | TemplateRef<any>
    | undefined;

  itemClicked = (item: T) => this.itemSelected.emit(item);
}
