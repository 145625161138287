import { inject, Injectable } from '@angular/core';
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import { BehaviorSubject, combineLatest, iif, map, switchMap } from 'rxjs';

import { CartStore, ItemsStore } from '@application/stores';
import { PackageStore } from '../package/package.store';

@Injectable({
  providedIn: 'root',
})
export class TotalStore {
  private total$: BehaviorSubject<{
    total: HubsterOrderTotal;
    currency: string;
  }> = new BehaviorSubject(
    {} as { total: HubsterOrderTotal; currency: string }
  );

  private readonly itemStore = inject(ItemsStore);
  private readonly items$ = this.itemStore.getItems();
  private readonly cart$ = inject(CartStore).getCart();
  private readonly discount$ = inject(CartStore).getDiscount();
  private readonly packages$ = inject(PackageStore).getItems();

  getTotal() {
    const elements$ = this.itemStore
      .hasItems()
      .pipe(
        switchMap((hasItems) =>
          iif(
            () => hasItems,
            this.items$.pipe(
              map((i) => i.map(({ currency, total }) => ({ currency, total })))
            ),
            this.packages$.pipe(
              map((p) => p.map(({ currency, total }) => ({ currency, total })))
            )
          )
        )
      );
    return combineLatest([elements$, this.cart$, this.discount$]).pipe(
      map(([items, cart, discount]) => {
        const orderTotal: HubsterOrderTotal = { subtotal: 0, total: 0 };
        const total = items.reduce((acc, current) => {
          return (acc += current.total);
        }, 0);
        const tip = cart.tip;
        orderTotal.subtotal = Math.round(total*1e2)/1e2;
        orderTotal.total = Math.round((total + tip)*1e2)/1e2;
        orderTotal.tip = tip;

        if (discount.type === 'amount') {
          orderTotal.discount = discount.value;
          orderTotal.total -= discount.value;
        }
        if (discount.type === 'percentage') {
          const discountPrice = (total * discount.value) / 100;
          orderTotal.discount = discountPrice;
          orderTotal.total -= discountPrice;
        }
        const currency =
          items && items.length > 0 ? items[0].currency : 'BRL' ?? 'BRL';
        this.total$.next({ total: orderTotal, currency });
        return { total: orderTotal, currency };
      })
    );
  }

  set total(newTotal: number) {
    const clone = this.total$.getValue();
    this.total$.next({ ...clone, total: { ...clone.total, total: newTotal } });
  }

  set currency(newCurrency: string) {
    const total = this.total$.getValue();
    this.total$.next({ ...total, currency: newCurrency });
  }

  getTotalUncalc() {
    return this.total$.asObservable();
  }

  getTotalUncalcValue() {
    return this.total$.getValue();
  }

  reset() {
    this.total$.next({} as { total: HubsterOrderTotal; currency: string });
  }
}
