import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';

import { HttpErrorResponse } from '@angular/common/http';
import { AuthStore, StoreStore } from '@application/stores';
import { InvoiceStore } from '@application/stores/invoice/invoice.store';
import { AuthService } from '@infrastructure/api';
import { LoginRequest, LoginResponse } from '@infrastructure/dtos';
import { ROLE } from '@model/enums';
import { DecodedToken } from '@model/interfaces';
import { IStockFacade } from 'app/modules/_stock/public-api';
import { IHubsterFacade } from 'app/modules/hubster/public-api';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  constructor(
    private authService: AuthService,
    private authStore: AuthStore,
    private storeStore: StoreStore,
    private invoiceStore: InvoiceStore,
    private router: Router,
    private toastrService: ToastrService,
    private hubsterFacade: IHubsterFacade,
    private stockFacade: IStockFacade
  ) {}

  getRoles() {
    return this.authStore.getRoles();
  }

  login(payload: LoginRequest): void {
    this.authService.login(payload).subscribe({
      next: (res: LoginResponse) => {
        this.authStore.setToken(res.data);
        const decodedToken: DecodedToken = jwt_decode(res.data.accessToken);
        this.initStores(decodedToken);
        this.redirectByRole(decodedToken.roles[0]);
      },
      error: (err: HttpErrorResponse) => {
        this.toastrService.error(err.error.error.message, 'Error');
      },
    });
  }

  loadAuth(): void {
    const token = this.authStore.getToken();
    if (token) {
      const decodedToken: DecodedToken = jwt_decode(token.accessToken);
      this.initStores(decodedToken);
    }
  }

  private initStores(decodedToken: DecodedToken) {
    this.authStore.roles = decodedToken.roles;
    this.authStore.connectionIds = decodedToken.connectionIds;
    this.storeStore.setStoreNames(decodedToken.storeNames);
    if (decodedToken.roles[0] === ROLE.STORE_USER) {
      this.stockFacade.fetchStockSettings(decodedToken.connectionIds[0]);
      this.stockFacade.initDataForItems();
      this.hubsterFacade.initData();
    }
  }

  private redirectByRole(role: ROLE) {
    switch (role) {
      case ROLE.INTEGRATION_ADMIN:
        this.router.navigateByUrl('/admin/packages');
        break;
      case ROLE.STORE_MANAGER:
        this.router.navigateByUrl('/manager/stores');
        break;
      case ROLE.STORE_USER:
        this.storeStore.setStoreSelected(this.authStore.getConnectionIds()[0]);
        this.router.navigateByUrl('/orders');
        break;
    }
  }

  logout(): void {
    this.authStore.removeToken();
    this.invoiceStore.removeInvoices();
    this.storeStore.reset();
    this.hubsterFacade.clearData();
    this.router.navigateByUrl('auth/login');
  }

  isLoggedIn(): boolean {
    return this.authStore.getToken() ? true : false;
  }
}
