import { Injectable } from '@angular/core';
import { Invoice, StatusInvoice } from '@model/interfaces';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoiceStore {
  private invoice$: BehaviorSubject<Invoice> = new BehaviorSubject({
    status: 'EMPTY',
    ids: [],
  } as Invoice);

  constructor() {}

  getInvoice() {
    return this.invoice$.asObservable();
  }

  set invoice(invoice: Invoice) {
    this.invoice$.next(invoice);
  }

  set ids(ids: number[]) {
    const invoice = this.invoice$.value;
    this.invoice$.next({ ...invoice, ids });
  }

  set jobId(jobId: string) {
    const invoice = this.invoice$.value;
    this.invoice$.next({ ...invoice, jobId });
  }

  getIds() {
    return this.invoice$.asObservable().pipe(map((invoice) => invoice.ids));
  }

  getStatus() {
    return this.invoice$.asObservable().pipe(map((invoice) => invoice.status));
  }

  set status(status: StatusInvoice) {
    if (['FinishedWithErrors', 'Finished', 'Error'].includes(status)) {
      this.invoice$.next({ status: 'EMPTY', ids: [], jobId: '' });
      localStorage.removeItem('invoice');
      return;
    }
    const invoice = this.invoice$.value;
    this.invoice$.next({ ...invoice, status });
  }

  saveStoreInvoice() {
    const invoice = this.invoice$.value;
    localStorage.setItem('invoice', JSON.stringify(invoice));
  }

  removeInvoices(): void {
    localStorage.removeItem('invoice');
  }

  getStoreInvoice() {
    const flatInvoice = localStorage.getItem('invoice') || '';
    if (!flatInvoice || flatInvoice == '')
      return this.invoice$.next({ status: 'EMPTY', ids: [] });
    const invoice = JSON.parse(flatInvoice) as Invoice;
    this.invoice$.next(invoice);
  }
}
