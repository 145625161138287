import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BasicStockItem } from '../models/basic-stock-item.model';

import { SERVICES_URL } from '../../../common/types/services-url.token';
import { IStockRepository } from '../interfaces/stock-repository.interface';

import { StockItemsResponse } from '../types/stock-items-response.type';
import { StockSettingResponse } from '../types/stock-setting-response.type';
import { StockSettingsResponse } from '../types/stock-settings-response.type';

@Injectable()
export class StockRepository implements IStockRepository {
  constructor(
    private readonly http: HttpClient,
    @Inject(SERVICES_URL) private readonly servicesUrl: string
  ) {}

  fetchStockSettings(connectionId: number): Observable<StockSettingsResponse> {
    const params = new HttpParams().append('connectionId', connectionId);
    return this.http.get<StockSettingsResponse>(
      `${this.servicesUrl}/stock-settings`,
      { params }
    );
  }

  createStockSettings(
    menuName: string,
    stockEnabled: boolean,
    connectionId: number
  ): Observable<StockSettingResponse> {
    return this.http.post<StockSettingResponse>(
      `${this.servicesUrl}/stock-settings`,
      {
        menuName,
        stockEnabled,
        connectionId,
      }
    );
  }

  createStockItems(
    stockSettingsId: number,
    items: BasicStockItem[]
  ): Observable<StockItemsResponse> {
    return this.http.post<StockItemsResponse>(
      `${this.servicesUrl}/stock-items`,
      {
        stockSettingsId,
        items,
      }
    );
  }

  stockItemsByStockSettings(
    stockSettingsId: number
  ): Observable<StockItemsResponse> {
    return this.http.get<StockItemsResponse>(
      `${this.servicesUrl}/stock-settings/${stockSettingsId}/stock-items`
    );
  }

  stockItems(): Observable<StockItemsResponse> {
    return this.http.get<StockItemsResponse>(
      `${this.servicesUrl}/store/stock-items`
    );
  }
}
