import { Injectable } from '@angular/core';
import { combineLatest, of, shareReplay } from 'rxjs';

import { RoyaltiesStore } from '@application/stores';
import { RoyaltiesService, StoreService } from '@infrastructure/api';
import { RoyaltyDetail } from '@model/interfaces/royalties/royalty-detail';
import { UTCToTimezoned } from 'app/utils/utc-to-timezoned.function';
import { ToastrService } from 'ngx-toastr';
import { Royalty } from '../../../model/interfaces';

@Injectable({
  providedIn: 'root',
})
export class RoyaltiesFacade {
  constructor(
    private royaltiesService: RoyaltiesService,
    private royaltiesStore: RoyaltiesStore,
    private storeService: StoreService,
    private toastrService: ToastrService
  ) {}

  fetchRoyaltyDetails(year: string, connectionId?: number) {
    combineLatest([
      this.royaltiesService
        .getRoyalties(connectionId, year)
        .pipe(shareReplay(1)),
      connectionId
        ? this.storeService.fetchStoreSettings(connectionId)
        : of(null),
    ]).subscribe({
      next: ([royalties, storeSettings]) => {
        const royaltyDetails = royalties.data
          .filter((r) => this.filterByYear(r, year))
          .map((royalty) => {
            const copy = { ...royalty };
            copy.startDate = UTCToTimezoned(
              royalty.startDate,
              storeSettings?.data.timezone.timezone ?? 'America/Sao_Paulo'
            );
            copy.endDate = UTCToTimezoned(
              royalty.endDate,
              storeSettings?.data.timezone.timezone ?? 'America/Sao_Paulo'
            );

            return copy;
          });
        this.royaltiesStore.setRoyaltyDetails(royaltyDetails);
        this.royaltiesStore.setTotalSales(
          royaltyDetails.reduce((acc, current) => acc + current.revenue, 0)
        );
        this.royaltiesStore.setTotalEarnings(
          royaltyDetails.reduce((acc, current) => acc + current.amount, 0)
        );
      },
      error: (err) => this.toastrService.error(err?.error.error.message),
    });
  }

  private filterByYear(royalty: RoyaltyDetail, year: string) {
    return (
      royalty.startDate.substring(0, 4) === year ||
      royalty.endDate.substring(0, 4) === year
    );
  }

  clearRoyalties() {
    this.royaltiesStore.reset();
  }

  getStoreRoyalties(): void {
    this.royaltiesService
      .fetchStoreSettingsRoyalties()
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.royaltiesStore.setRoyalties(res.data);
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  saveCurrentRoyalties(royalties: Royalty[]): void {
    this.storeService
      .createStoreSettingsRoyalties(royalties)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.royaltiesStore.setRoyalties(res.data);
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }
}
