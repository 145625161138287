import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pagination',
})
export class PaginationPipe implements PipeTransform {
  transform<T>(
    positions: T[] | null = [],
    index: number | null = 0,
    size: number
  ): T[] | null {
    if (positions == null) return null;
    if (index == null) index = 0;
    const init = index * size;
    const end = init + size;
    return positions.slice(init, end);
  }
}
