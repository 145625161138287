import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, shareReplay } from 'rxjs';

import { UserStore } from '@application/stores';
import { UserService } from '@infrastructure/api';
import {
  CreateStoreUser,
  UpdateStoreUser,
  ValidatePinResponse,
} from '@infrastructure/dtos';
import { User } from '@model/interfaces';
import { UTCToTimezoned } from 'app/utils/utc-to-timezoned.function';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  constructor(
    private userService: UserService,
    private userStore: UserStore,
    private toastrService: ToastrService
  ) {}

  // TODO Should return void
  validatePin(pin: string): Observable<ValidatePinResponse> {
    return this.userService.validatePin(pin).pipe(shareReplay(1));
  }

  fetchUsersByStore(connectionId: number): void {
    this.userService
      .fetchUsersByStore(connectionId)
      .pipe(shareReplay(1))
      .subscribe((res) => {
        this.userStore.users = res.data.map((u) => ({
          ...u,
          createdAt: UTCToTimezoned(u.createdAt, 'utc'),
          updatedAt: UTCToTimezoned(u.updatedAt, 'utc'),
        }));
      });
  }

  createStoreUser(user: CreateStoreUser): void {
    this.userService
      .createStoreUser(user)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.userStore.addUser({
            ...res.data,
          });
          this.toastrService.success('Usuário criado com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  updateStoreUser(user: UpdateStoreUser): void {
    this.userService
      .updateStoreUser(user)
      .pipe(shareReplay(1))
      .subscribe({
        next: (res) => {
          this.userStore.updateUser({
            ...res.data,
          });
          this.toastrService.success('Usuário atualizado com sucesso');
        },
        error: (err) => this.toastrService.error(err?.error.error.message),
      });
  }

  setUserStatus(user: User, enable: boolean): void {
    this.userStore.updateUser({ ...user, isEnabled: enable });
    this.userService
      .setUserStatus(user.id, enable)
      .pipe(shareReplay(1))
      .subscribe({
        next: () => {
          const msg = enable ? 'habilitado' : 'desabilitado';
          this.toastrService.success(`Usuário ${msg} com sucesso`);
        },
        error: (err) => {
          this.userStore.updateUser({ ...user, isEnabled: !enable });
          this.toastrService.error(err?.error.error.message);
        },
      });
  }
}
